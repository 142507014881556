import { FC, Fragment, useEffect, useState } from "react";
import {
  ModalBody,
  Input,
  useColorModeValue,
  FormControl,
  ModalBodyProps,
  FormErrorMessage
} from "@chakra-ui/react";
import { CredsInput, InputLabel, ProvidersInput, RegionSelect } from "./components";
import Env from "./provider";
import { ProviderNames, ProviderSettings, RegionTypes } from "./types";
import { CustomModalFooter } from "../components";
import { useEnvs } from "./useenvs";
import { validateObj } from "./utils";
import { FieldData } from "./types";
import { AutoSave } from "components/navbar/savechanges";

const { Events } = AutoSave;

interface AddEnvProps extends ModalBodyProps {
  onClose: () => void;
  spaceId: string;
  vendor?: ProviderNames;
  onSuccess?: () => void;
  eventId?: string;
}

/**
 * Add environment popup
 * @param props Add environment popup props
 * @returns JSX.Element
 */
export const AddEnv: FC<AddEnvProps> = props => {
  const {
    onClose: __close,
    spaceId,
    vendor: lockVendor,
    onSuccess,
    eventId
  } = props;

  const { addEnv } = useEnvs();

  const [fields, setFields] = useState<ProviderSettings[]>([]);
  const [vendor, setVendor] = useState<ProviderNames | "">("");
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState<any>({
    name: false, vendor: false, region: false
  });

  const [envData, setEnvData] = useState<any>({
    name: "", vendor: "", region: "", credentials: {}
  })


  /**
   * Get vendor specific field data from the Credentials Input component
   * @param e Field data
   */
  const setCredentials = (e: FieldData) => {
    const { field, value } = e;
    const { credentials } = envData;
    const newCreds = { ...credentials, [field]: value };
    setEnvData((prev: any) => ({ ...prev, credentials: newCreds }));
  }

  /**
   * Validate the input fields as the data changes
   * @param e Input event
   */
  const validate = (e: any) => {
    const { name, value } = e.target;
    if (value.trim() === "") {
      setErrors((prev: any) => ({ ...prev, [name]: true }));
    } else {
      setErrors((prev: any) => ({ ...prev, [name]: false }));
    }
  }

  /**
   * Onchange handler for the input fields
   * @param e Input event
   */
  const onChange = (e: any) => {
    const { name, value } = e.target;
    setEnvData((prev: any) => ({ ...prev, [name]: value }));
    validate(e);
  }


  /**
   * Create the environment by saving the data to firestore
   */
  const handleCreate = async () => {
    setLoading(true);
    const { region, ...data } = envData;

    const __vendor = lockVendor || vendor

    if (Env.hasRegions(__vendor as ProviderNames)) {
      await addEnv({ ...data, region, vendor: __vendor }, spaceId);
    } else {
      await addEnv({ ...data, vendor: __vendor }, spaceId);

    }
    setLoading(false);
    onSuccess && onSuccess();
    eventId && Events.emit(eventId, []);
    onClose();
  }

  /**
   * Local onclose function
   */
  const onClose = () => {
    Env.cleanup();
    __close();
  }
  const textColor = useColorModeValue("#000", "#fff");

  /**
   * Auto update the envs' input fields for the selected vendor
   */
  useEffect(() => {
    if (vendor) {
      const __fields = Env.getProvider(vendor, "create");
      setFields(__fields);
      setEnvData((prev: any) => ({ ...prev, credentials: {} }));
    }
  }, [vendor]);

  // Validate input fields
  useEffect(() => {
    try {
      const { credentials, vendor: _, ...rest } = envData;
      const fields = { ...rest, ...credentials }
      const valid = validateObj(fields);
      setDisabled(!valid || loading || vendor === "")
    } catch (e) {
      console.log('AddEnv() -- Error validating fields', e);
    }
  }, [loading, envData, vendor]);


  // Lock vendor if provided
  useEffect(() => {
    if (lockVendor) {
      setVendor(lockVendor);
    }
  }, [lockVendor]);

  return (
    <Fragment>
      <ModalBody>
        <FormControl isRequired isInvalid={errors.name}>
          <InputLabel>Name</InputLabel>
          <Input color={textColor} name="name" value={envData.name}
            mb="1rem" borderRadius="1rem"
            onChange={onChange}
            placeholder="Enter environment name"
            onBlur={validate}
            _invalid={{ mb: ".25rem" }}
          />
          <FormErrorMessage mb=".5rem">
            Please enter a name for the environment
          </FormErrorMessage>
        </FormControl>
        <ProvidersInput label="Provider" name="vendor"
          onChange={(e: any) => {
            setVendor(e.target.value);
            validate(e);
          }}
          value={vendor} color={textColor}
          isInvalid={errors.vendor}
          onFocus={validate}
          disabled={!!lockVendor}
        />
        {Env.hasRegions(vendor as ProviderNames) &&
          <RegionSelect vendor={vendor as RegionTypes}
            value={envData.region} onChange={onChange}
            isInvalid={errors.region}
            onFocus={validate}
          />
        }
        {vendor && fields.map(field =>
          <CredsInput {...field} provider={vendor} key={field.field}
            onSetField={setCredentials}
          />
        )}
      </ModalBody>
      <CustomModalFooter
        onAccept={handleCreate}
        onCancel={onClose}
        acceptLabel={loading ? "Please wait..." : "Add Environment"}
        cancelLabel="Cancel"
        disabled={disabled}
      />
    </Fragment>
  )
}