import { useAuth } from "context/AuthContext"
import { AutoSave } from "components/navbar/savechanges"
import { UserInvite } from "functions/inviteuser"


interface MemberInvite {
  name: string;
  email: string;
  permission: string;
}

/**
 * A custom hook to handle team member invites
 * @returns utility functions
 */
export const useTeams = () => {

  const { docId, getOrgsData, spaceId } = useAuth();

  const inviteMember = async (data: MemberInvite) => {
    try {
      const colData = {
        docId, spaceId, teamId: docId
      }
      const invite = new UserInvite(data, colData);
      await invite.handlInvite();
      getOrgsData();
      AutoSave.display('Member invite sent successfully')
    } catch (error) {
      console.log('inviteMember(): ', error);
      AutoSave.error('Error inviting member')
    }
  }

  return {
    inviteMember
  }
}