import React, { FC, useEffect, useRef } from 'react';
import { Box, BoxProps, Icon, Text } from '@chakra-ui/react';
import { useResize } from './useResize';
import { BiExpand } from 'react-icons/bi';
import { TbArrowsMinimize } from 'react-icons/tb';
import { handlePositions as pos, boxConfig, initialPosition, BoxStyle } from './constants';
import { HandlePosition, HandlePositionProps, ResizableBoxProps } from './types';

import "./cssstyles/main.css";

// Resizable box component for the terminal
export const ResizableBox: FC<ResizableBoxProps> = props => {
  const { children, onClose, ...rest } = props;
  const [mode, setMode] = React.useState<'resize' | 'full'>('resize');

  const { width, height, ref, setSize } = useResize(mode);

  /**
   * Toggles between resize and full modes
   */
  const toggleMode = () => {
    setMode(mode === 'resize' ? 'full' : 'resize');
  };

  return (
    <Box
      width={width}
      height={height}
      overflow='hidden'
      border='1px solid #e2e8f0'
      borderRadius='md'
      position={'relative'}
      display='flex'
      alignItems='center'
      justifyContent='center'
      flexDir="column"
      padding="3rem 1rem 1rem 1rem"
      ref={ref}
      {...rest}
    >
      <ToolBar>
        <Icon as={mode === "resize" ? BiExpand : TbArrowsMinimize} 
          className="terminal-toolbar" 
          onClick={toggleMode}
          boxSize={6}
        />
        <Text className="terminal-toolbar" onClick={onClose}>Close</Text>
      </ToolBar>
      {['left', 'right', 'top', 'bottom'].map(placement => (
        <ResizeHandle key={placement} placement={placement as HandlePosition}
          setSize={setSize} boxRef={ref} size={{ width, height }}
        />
      ))}
      {children}
    </Box>
  );
};

// Toolbar component
const ToolBar: FC<BoxProps> = props => {
  const { children, ...rest } = props;
  return (
    <Box
      {...BoxStyle}
      {...rest}
    >
      {children}
    </Box>
  );
};

// Horizontal resize handle
export const ResizeHandle: FC<BoxProps & HandlePositionProps> = props => {
  const { placement, setSize, size, boxRef, ...rest } = props;

  const position = useRef(initialPosition);

  const recordPosition = useRef(false);

  // Update data on mouse move
  useEffect(() => {

    // Query the handle
    const handle = document.querySelector(`[data-handle=${placement}]`) as HTMLElement;

    // Do nothing if ref is not set
    if (!boxRef?.current) return;

    // Mouse Down Handler
    const mouseDownHandler = (event: MouseEvent) => {
      event.preventDefault();
      const windowRect = boxRef.current?.getBoundingClientRect();
      position.current = {
        ...position.current,
        startX: event.clientX,
        startY: event.clientY,
        startWidth: windowRect?.width || 0,
        startHeight: windowRect?.height || 0,
      };

      document.body.style.cursor = pos[placement].cursor;
      recordPosition.current = true;
    }

    // Mouse Up Handler
    const mouseUpHandler = (event: MouseEvent) => {
      event.preventDefault();
      recordPosition.current = false;
      document.body.style.cursor = 'default';
    }

    // Mouse Move Handler
    const mouseMoveHandler = (event: MouseEvent) => {
      event.preventDefault();
      if (recordPosition.current) {
        position.current = {
          ...position.current,
          clientX: event.clientX,
          clientY: event.clientY,
          dX: 2 * (event.clientX - position.current.startX),
          dY: 2 * (event.clientY - position.current.startY),
        };
        if (placement === 'left') {
          let newWidth = position.current.startWidth - position.current.dX;
          if (newWidth < boxConfig.minWidth) {
            newWidth = boxConfig.minWidth;
          }
          setSize({ ...size, width: newWidth });
        } else if (placement === 'right') {
          let newWidth = position.current.startWidth + position.current.dX;
          if (newWidth < boxConfig.minWidth) {
            newWidth = boxConfig.minWidth;
          }
          setSize({ ...size, width: newWidth });
        }
        if (placement === 'top') {
          let newHeight = position.current.startHeight - position.current.dY;
          if (newHeight < boxConfig.minHeight) {
            newHeight = boxConfig.minHeight;
          }
          setSize({ ...size, height: newHeight });
        }
        if (placement === 'bottom') {
          let newHeight = position.current.startHeight + position.current.dY;
          if (newHeight < boxConfig.minHeight) {
            newHeight = boxConfig.minHeight;
          }
          setSize({ ...size, height: newHeight });
        }
      }
    }

    // Add event listeners
    if (handle) {
      handle.addEventListener('mousedown', mouseDownHandler);
      window.addEventListener('mousemove', mouseMoveHandler);
      window.addEventListener('mouseup', mouseUpHandler);
    }

    // Remove event listeners
    return () => {
      handle.removeEventListener('mousedown', mouseDownHandler);
      window.removeEventListener('mousemove', mouseMoveHandler);
      window.removeEventListener('mouseup', mouseUpHandler);
    };

  }, [placement, boxRef, setSize, size]);

  return (
    <Box
      data-handle={placement}
      position='absolute'
      {...pos[placement]}
      {...rest}
    />
  );
};
