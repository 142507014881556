import { Box, useColorModeValue } from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";

interface inputFieldProps {
  strength: number;
}

type STRENGTH = "weak" | "medium" | "strong";

export const StrengthMeter: FC<inputFieldProps> = props => {
  const [strength, setStrength] = useState<STRENGTH>("weak");

  const weakColor = useColorModeValue("red.500", "red");
  const mediumColor = useColorModeValue("yellow.500", "yellow.600");
  const strongColor = useColorModeValue("green.500", "green");

  useEffect(() => {
    if (props.strength < 10) {
      setStrength("weak");
    } else if (props.strength >= 10 && props.strength < 18) {
      setStrength("medium");
    } else {
      setStrength("strong");
    }
  }, [props.strength])

  return (
    <Box mr="1rem" 
      bg={strength === "weak" ? weakColor : strength === "medium" ? mediumColor : strongColor}
      color="white"
      p="0 1rem"
      borderRadius="0.5rem"
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={{ base: "1rem", md: "1.2rem" }}
      cursor="pointer"
      >
      {`${strength} password`}
    </Box>
  )
}