import { FC } from "react"
import Card from "components/card/Card";
import { Flex, Image, Text, Icon } from "@chakra-ui/react";
import { VscKebabVertical } from "react-icons/vsc"
import { CheckIcon } from "@chakra-ui/icons";


interface PostData {
  name: string;
  photoUrl: any;
  time: string;
  post: string;
}
export const Post: FC<PostData> = props => {
  const { name, time, post, photoUrl } = props;

  return (
    <Card mb="1rem">
      <Flex flexDir="column" gap="1rem"
        fontSize="md" fontWeight="semibold"
      >
        <Flex flexDir="row" justifyContent="space-between">
          <Flex flexDir="row" gap="1rem">
            <Image src={photoUrl}
              boxSize="50px"
              borderRadius="25px"
              objectFit="cover"
            />
            <Flex flexDir="column" >
              <Text>{name}</Text>
              <Text color="gray.500">{time}</Text>
            </Flex>
          </Flex>
          <Flex flexDir="row" justifyContent="flex-end" gap="1rem" alignItems="center">
            <CheckIcon color="green" />
            <Icon as={VscKebabVertical} color="gray.500" />
          </Flex>
        </Flex>
        <Text p="0 1rem">{post}</Text>
      </Flex>
    </Card>
  )
}
