import React, { FC, Fragment, useState, useEffect } from "react"
import { ModalBody, ModalBodyProps } from "@chakra-ui/react"
import { CustomInput, CustomModalFooter, CustomSelect } from "../components"
import { validate as emailValidator } from "email-validator";
import { useTeams } from "./useteams";

interface InviteMemberProps extends ModalBodyProps {
  onClose: () => void
}

const permissisionData = [
  { id: "1", name: "viewer" },
  { id: "3", name: "developer" },
  { id: "2", name: "admin" },
];

export const InviteMember: FC<InviteMemberProps> = props => {
  const { onClose } = props;

  const [disabled, setDisabled] = useState(true);
  const [errorState, setErrorState] = useState({
    name: false,
    email: false,
    permission: false,
  });
  const [data, setData] = useState({
    name: "",
    email: "",
    permission: "",
  });
  const [emailValid, setEmailValid] = useState(true);
  const [loading, setLoading] = useState(false);

  const { inviteMember } = useTeams()

  const validate = (e: any) => {
    const { name, value } = e.target;
    if (value.trim() === "") {
      setErrorState({ ...errorState, [name]: true });
    } else {
      setErrorState({ ...errorState, [name]: false });
    }
    if (name === "email") {
      setEmailValid(emailValidator(value));
    }
  }

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    validate(e)
  }

  useEffect(() => {
    const fieldError = Object.values(data).some((field) => field.trim() === "");
    const ErrorState = Object.values(errorState).some((field) => field);

    const disable = fieldError || ErrorState || !emailValid;
    setDisabled(disable);
    setEmailValid(emailValid);

  }, [data, errorState, emailValid]);

  const onInvite = async () => {
    setLoading(true);
    await inviteMember(data)
      .then(() => {
        setLoading(false);
        onClose();
      });
  }

  return (
    <Fragment>
      <ModalBody>
        <CustomInput
          label="Name"
          placeholder="Enter name"
          isRequired
          name="name"
          isInvalid={errorState.name}
          onChange={onChange}
          onBlur={validate}
          error={errorState.name ? "Name is required" : ""}
        />
        <CustomInput
          label="Email"
          placeholder="member@mail.com"
          isRequired
          name="email"
          isInvalid={errorState.email || !emailValid }
          onChange={onChange}
          onBlur={validate}
          error={errorState.email ? "Email is required" : ""}
        />
        <CustomSelect
          isRequired
          label="Permission"
          placeholder="Select permission"
          name="permission"
          isInvalid={errorState.permission}
          data={permissisionData.map(data =>
            ({ name: data.name, id: data.name }))
          }
          onFocus={validate}
          onChange={onChange}
          error={errorState.permission ? "Permission is required" : ""}
        />
      </ModalBody>
      <CustomModalFooter
        onAccept={onInvite}
        onCancel={onClose}
        cancelLabel="Close"
        acceptLabel={loading ? "Please wait..." : "Invite"}
        disabled={disabled || loading}
      />
    </Fragment>
  )
}                   
