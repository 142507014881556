import { useEffect, useState } from 'react';
interface validatorProps {
  valid: boolean;
  message: string;
  strength: number;
}


/**
 * Password Strength parameters and requirements
 */
const minLen = 8;
const maxLen = 40;
const matchNum = /\d/g;
const matchLower = /[a-z]/g;
const matchUpper = /[A-Z]/g;
const matchSpecial = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g;

/**
 * Password Strength messages based on each category tested
 */
enum messages {
  lenShort = "Password must be at least 8 characters long",
  lenLong = "Password must be at most 40 characters long",
  lower = "Password must contain at least one lower-case letter (e.g. a, b, c)",
  upper = "Password must contain at least one upper-case letter (e.g. A, B, C)",
  number = "Password must contain at least one number (e.g. 1, 2, 3)",
  special = "Password must contain at least one special character (e.g. !, @, #)",
  success = "Password is strong",
}

/**
 * Gets the strength of the password based on length to a scale of 0-8
 * @param {string} password - password to be checked
 * @returns {number} - strength of password
 */
const getStregnthLen = (password: string): number => {
  if (password.length >= 8 && password.length <= 10) {
    return 2;
  } else if (password.length >= 11 && password.length <= 15) {
    return 3;
  } else if (password.length >= 16 && password.length <= 20) {
    return 4;
  } else if (password.length >= 21 && password.length <= 25) {
    return 5;
  } else if (password.length >= 26 && password.length <= 30) {
    return 6;
  } else if (password.length >= 31 && password.length <= 35) {
    return 7;
  } else if (password.length >= 36 && password.length <= 40) {
    return 8;
  } else {
    return 0;
  }
};

/**
 * Gets the strength of the password based on special characters to a scale of 0-8
 * @param {string} password - password to be checked
 * @returns {number} - strength of password
 */
const getStregnthLoweer = (password: string): number => {
  const hasLower = matchLower.test(password);
  if (hasLower) {
    let lowerCount = password.match(matchLower)?.length;
    if (lowerCount > 10) lowerCount = 10;
    return 0.8 * lowerCount;
  } else {
    return 0;
  }
};

/**
 * Gets the strength of the password based on special characters to a scale of 0-8
 * @param {string} password - password to be checked
 * @returns {number} - strength of password
 */
const getStregnthUpper = (password: string): number => {
  const hasUpper = matchUpper.test(password);
  if (hasUpper) {
    let upperCount = password.match(matchUpper)?.length;
    if (upperCount > 10) upperCount = 10;
    return 0.8 * upperCount;
  } else {
    return 0;
  }
};

/**
 * Gets the strength of the password based on special characters to a scale of 0-8
 * @param {string} password - password to be checked
 * @returns {number} - strength of password
 */
const getStregnthNumber = (password: string) => {
  const hasNumber = matchNum.test(password);
  if (hasNumber) {
    let numberCount = password.match(matchNum)?.length;
    if (numberCount > 10) numberCount = 10;
    return 0.8 * numberCount;
  } else {
    return 0;
  }
};

/**
 * Gets the strength of the password based on special characters to a scale of 0-8
 * @param {string} password - password to be checked
 * @returns {number} - strength of password
 */
const getStregnthSpecial = (password: string) => {
  const hasSpecial = matchSpecial.test(password);
  if (hasSpecial) {
    let specialCount = password.match(matchSpecial)?.length;
    if (specialCount > 10) specialCount = 10;
    return 0.8 * specialCount;
  } else {
    return 0;
  }
};

/**
 * Gets the strength of the password based on all parameters to a scale of 0-40
 * @param {string} password - password to be checked
 * @returns {number} - strength of password
 */
export const getStregnth = (password: string): number => {
  const stregnthLen = getStregnthLen(password);
  const stregnthLower = getStregnthLoweer(password);
  const stregnthUpper = getStregnthUpper(password);
  const stregnthNumber = getStregnthNumber(password);
  const stregnthSpecial = getStregnthSpecial(password);
  const stregnth = stregnthLen + stregnthLower + stregnthUpper + stregnthNumber + stregnthSpecial;
  return stregnth;
};


/**
 * Custom hook to validate password strength
 * @param password - password to be checked
 * @returns {validatorProps} - object containing validation status, message, and strength
 */
export const useValidator = (password: string): validatorProps => {
  const [valid, setValid] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [strength, setStregnth] = useState<number>(0);


  /**
   * Updates the validation status of the password
   */
  useEffect(() => {
    if (password.length < minLen) {
      setMessage(messages.lenShort);
      setValid(false);
    } else if (password.length > maxLen) {
      setMessage(messages.lenLong);
      setValid(false);
    } else if (!matchNum.test(password)) {
      setMessage(messages.number);
      setValid(false);
    } else if (!matchLower.test(password)) {
      setMessage(messages.lower);
      setValid(false);
    } else if (!matchUpper.test(password)) {
      setMessage(messages.upper);
      setValid(false);
    } else if (!matchSpecial.test(password)) {
      setMessage(messages.special);
      setValid(false);
    } else {
      setMessage(messages.success);
      setValid(true);
    }
  }, [password]);

  /**
   * Updates the strength of the password
   */
  useEffect(() => {
    const stregnth = getStregnth(password);
    if (valid) setStregnth(stregnth);
    else setStregnth(0);
  }, [valid, password]);

  return { message, valid, strength };
};
