import React, { useState } from "react";
import Design from "../cloudDesign/Design";

// Chakra imports
import { Box, Button, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import VideoPlayer from "common/VideoPlayer";

import Card from "components/card/Card";
import Dropdown from "common/Dropdown";
import BackdropOverlay from "common/BackdropOverlay";
import { useAuth } from "context/AuthContext";
import AccessAlert from "common/AccessAlert";
import InvalidRole from "common/InvalidRole";
import { AutoSave } from "components/navbar/savechanges";

// const btnData = [{ id: 1, name: "Cloud Observe" }];

export default function CloudSettings() {
  const [btnId, setBtnId] = useState<number>(1);
  const { permission } = useAuth();
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  // const textColorBrand = useColorModeValue("brand.500", "white");

  const handleBtnClick = (id: number) => {
    setBtnId(id);
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <InvalidRole />
      <BackdropOverlay />
      {permission === "viewer" && <AccessAlert readonlyAccess={true} />}
      <Flex flexDirection="column">
        <Card p="0px">
          <Flex
            align={{ sm: "flex-start", lg: "center" }}
            justify="space-between"
            w="100%"
            px="22px"
            py="18px"
          >
            <Text color={textColor} fontSize="xl" fontWeight="600">
              Cloud Observe
            </Text>
            {/* <Box>
              {btnData.map(({ id, name }) => (
                <Button
                  key={id}
                  variant={btnId === id ? "action" : "ghost"}
                  onClick={() => handleBtnClick(id)}
                >
                  {name}
                </Button>
              ))}
            </Box> */}
            <Button colorScheme="blue">Save</Button>
          </Flex>
          <Dropdown disableBtn={true}/>
          <Flex direction="row" justifyContent="center" alignItems="center">
            <Design />
          </Flex>
          <Box>
            <VideoPlayer />
          </Box>
        </Card>
      </Flex>
    </Box>
  );
}
