import { Box, BoxProps, Text } from "@chakra-ui/react";
import { FC, useEffect, useRef } from "react";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { useTerminal } from "./utils";


// CSS
import './cssstyles/main.css';
import 'xterm/css/xterm.css'

interface TerminalProps extends BoxProps {
  data: string;
  ps1: string;
  name: string;
  resizeCallback?: () => void;
}
export const Terminal: FC<TerminalProps> = props => {
  const { ps1, data, name, ...rest } = props;
  const { containerRef, fitRef } = useTerminal(data);
  const terminalRef = useRef(null);

  const bgColor = useColorModeValue('#fff', '#000');

  useEffect(() => {
    if (!terminalRef.current) return;
    if (!fitRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (fitRef.current) {
        fitRef.current.fit();
        // console.log('fitting', fitRef.current.proposeDimensions());
      }
    });
    resizeObserver.observe(terminalRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [fitRef]);


  return (
    <Box ref={terminalRef} className='terminal-ext' bg={bgColor} width="95%" height="50%"
      padding="1rem" borderRadius="8px" overflow="hidden"
      display={'flex'} flexDirection={'column'} alignItems={'center'}
      justifyContent={'center'}
      {...rest}
    >
      <Text className='terminal-ps1'>{ps1}</Text>
      <Box
        // className="code-container" 
        id={`code-container-${name}`} ref={containerRef}
        w="100%" h="100%"
      >
      </Box>
    </Box>
  )
}