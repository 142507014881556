import React, { createContext, useContext, useEffect, useState } from "react";
import { auth, db } from "firebase-local/config";
import { onAuthStateChanged } from "firebase/auth";
// import { userTypes } from "../types/userTypes";
import { userArrayTpesValues } from "../types/userArray";
import { UserContextTypes, PlanConfig } from "../types/userContext";
import { useAuth } from "./AuthContext";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { col, pkgs } from "functions";
import { updateDoc } from "firebase/firestore";
import { getPackageDetails } from "./utils";
import { AutoSave } from "components/navbar/savechanges";
import { Session } from "functions/sessionhandler";
import Packages from "functions/packagehandler";

const session = new Session();

const UserContext = createContext<UserContextTypes>({
  currentUser: null,
  changeCount: 0,
  autoSave: false,
  initialDiagramData: "{}",
  userArray: userArrayTpesValues,
  userId: "",
  userEmail: "",
  customCodes: [],
  getUserData: (email: string) => { },
  getDiagramData: (data: any) => { },
  emailVerified: false,
  diagramData: "{}",
  updateDiagram: false,
  updateDiagramData: (update: boolean) => { },
  updateInitialDiagramData: (data: any) => { },
  autoSaveChanges: (changes: boolean) => { },
  modules: [],
  newModule: () => new Promise(() => { }),
  deleteModule: (index: number) => new Promise(() => { }),
  updateModule: (index: number, content: string) => new Promise(() => { }),
  moduleIndex: 0,
  setModuleIndex: (index: number) => { },
  activity: false,
  setActivity: (activity: boolean) => { },
  packageInfo: {
    name: "",
    ext: ".ts",
    language: "typescript",
    id: "",
  },
});

export const useUser = () => useContext(UserContext);

type Props = {
  children: JSX.Element;
};

const UserContextProvider: React.FC<Props> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<null>(null);
  const [userId, setUserId] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  // const [userArray, setUserArray] = useState<userTypes[]>([]);
  const [emailVerified, setEmailVerified] = useState<boolean>(false);
  const [updateDiagram, setUpdateDiagram] = useState<boolean>(false);

  const [diagramData, setDiagramData] = useState<string>("{}");
  const [autoSave, setAutoSave] = useState<boolean>(false);
  const [initialDiagramData, setInitialDiagramData] = useState<string>("{}");
  const [customCodes, setCustomCodes] = useState<string[]>([]);
  const [changeCount, setChangeCount] = useState<number>(0);

  // AuthContext
  const { spaceId, planId, docId } = useAuth();

  // Editor Data
  const [modules, setModules] = useState<string[]>([]);
  const [moduleIndex, setModuleIndex] = useState<number>(0);
  const [activity, setActivity] = useState<boolean>(false);
  const [template, setTemplate] = useState<string>("");
  const [packageInfo, setPackageInfo] = useState({
    name: "",
    ext: ".ts",
    language: "typescript",
    id: "",
  });

  /**
   * Get the plans contents and the custom modules for the editor
   */
  useEffect(() => {
    const planDoc = doc(
      db,
      col.orgs,
      docId,
      col.teams,
      docId,
      col.spaces,
      spaceId,
      col.plans,
      planId
    );

    const unsubscribe = onSnapshot(planDoc, async (doc: any) => {
      if (doc.data()) {
        const plan = doc.data() as PlanConfig;
        const meta = await getPackageDetails(plan.package);
        const custom = plan.custom;
        const content = plan.content;
        if (!custom) {
          setModules([content || ""])
        } else {
          setModules([content || "", ...custom])
        }
        setPackageInfo(meta);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [spaceId, planId, docId]);

  /**
   * Get the custom template file for creating the custom template codes
   */
  useEffect(() => {
    const id = packageInfo.id;
    if (!id || id === "") return;

    (async () => {
      try {
        const file = await pkgs.getFile("custom.template.ts", id);
        const template = await file.text();
        setTemplate(template);
      } catch (error) {
        console.error('Error getting file', error);
      }
    })()

  }, [packageInfo]);


  // Get User Data
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user: any) => {
      setEmailVerified(user && user.emailVerified);
      setCurrentUser(user ? user : null);
      setUserId(user ? user.uid : "");
      setUserEmail(user ? user.auth.currentUser.email : "");
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (spaceId || planId) {
      loadBlueprint();
    }
    // eslint-disable-next-line
  }, [spaceId, planId, docId]);

  // Auto Load Blurprint

  const autoSaveChanges = (changes: boolean) => {
    setAutoSave(changes);
  };

  const updateInitialDiagramData = (data: any) => {
    setInitialDiagramData(data);
  };

  // Load blueprint

  const loadBlueprint = async () => {
    const plansRef = doc(
      db,
      "orgs",
      docId,
      "teams",
      docId,
      "spaces",
      spaceId,
      "plans",
      planId
    );
    const docSnap: any = await getDoc(plansRef);

    if (docSnap.data() && docSnap.data().content) {
      const planConfigContent = docSnap.data().content as string;

      // Get custom code blocks from the plan
      const custom = docSnap.data().custom;
      if (custom && custom.length > 0) setCustomCodes(custom);

      setDiagramData(planConfigContent);
      setInitialDiagramData(planConfigContent);
      setUpdateDiagram(true);
      setChangeCount(0);
      setTimeout(() => {
        setChangeCount(1);
      }, 1000);
    } else {
      setDiagramData("{}");
      setInitialDiagramData("{}");
      setUpdateDiagram(true);
      setChangeCount(0);
      setTimeout(() => {
        setChangeCount(1);
      }, 1000);
    }
  };

  const getDiagramData = (data: string) => {
    setDiagramData(data);
  };

  const updateDiagramData = (update: boolean) => {
    setUpdateDiagram(update);
  };

  /**
   * Hndles saving custom modules to the database
   * @param modules string array of custom modules and the config file
   */
  const saveModules = async (modules: string[]) => {
    const planDoc = doc(
      db, col.orgs, docId, col.teams, docId, col.spaces, spaceId, col.plans, planId
    );

    const [content, ...custom] = modules;
    await updateDoc(planDoc, {
      content,
      custom,
    });
  };

  /**
   * Creates a new custom module using the package template
   * @returns index of the newly created module
   */
  const newModule = async () => {
    const moduleNo = modules.length - 1;
    const newModules = [
      ...modules,
      Packages.setupTemplate(template, moduleNo)
    ];
    setModules(newModules);
    await saveModules(newModules);
    return newModules.length - 1;
  };

  /**
   * Deletes a custom module from the modules array and saves to 
   * the database
   * @param index index of the module to delete
   * @returns index to select after deleting the module
   */
  const deleteModule = async (index: number) => {
    // Do not dlete config and package files
    if (index <= 0) return;
    try {
      const newModules = modules.filter((_, i) => i !== index);
      setModules(newModules);
      await saveModules(newModules);
    } catch (error) {
      console.error("Error deleting module", error)
    }
  };

  /**
   * Updates the modules array and saves to the database
   * @param index index of the module to update
   * @param content new content of the module at index
   */
  const updateModule = async (index: number, content: string) => {
    const newModules = modules.map((m, i) => {
      if (i === index) return content;
      return m;
    });
    setModules(newModules);
    await saveModules(newModules);
    AutoSave.display("Saved Changes");
  };

  /**
   * Selects which module index to display in the editor
   * @param index index to select
   */
  const updateModuleIndex = (index: number) => {
    setModuleIndex(index);
    const key = `editor-${spaceId}/${planId}`;
    session.setItem(key, `${index}`);
    setActivity(false);
  };

  const value: any = {
    currentUser,
    userId,
    userEmail,
    emailVerified,
    // userArray,
    getDiagramData,
    diagramData,
    updateDiagramData,
    updateDiagram,
    autoSave,
    updateInitialDiagramData,
    initialDiagramData,
    changeCount,
    autoSaveChanges,
    customCodes,
    modules,
    newModule,
    deleteModule,
    updateModule,
    packageInfo,
    moduleIndex,
    activity,
    setModuleIndex: updateModuleIndex,
    setActivity
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
