import { FC, Fragment, useEffect, useState } from "react";
import { Flex, ModalBody, ModalBodyProps, Text } from "@chakra-ui/react";
import {
  CustomInput,
  CustomModalFooter,
  CheckList
} from "../components";
import { useSpace } from "./usespace";

interface CreateSpaceProps extends ModalBodyProps {
  onClose: () => void
}
export const CreateSpace: FC<CreateSpaceProps> = props => {
  const { onClose, ...rest } = props;
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [errorState, setErrorState] = useState({
    name: false,
  });
  const [data, setData] = useState({
    name: "",
  });
  const [members, setMembers] = useState([]);

  const { addSpace, teamMembers } = useSpace();

  const handleAddSpace = async () => {
    setLoading(true);
    await addSpace(data, members)
      .then(() => setLoading(false));
    onClose();
  }

  useEffect(() => {
    const { name } = data;
    if (name.trim() === "" || errorState.name || loading) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [data, errorState, loading]);

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    if (name === "name") validate(e);
  }

  const validate = (e: any) => {
    const { name, value } = e.target;
    if (value.trim() === "") {
      setErrorState({ ...errorState, [name]: true });
    } else {
      setErrorState({ ...errorState, [name]: false });
    }
  }

  return (
    <Fragment>
      <ModalBody {...rest}>
        <CustomInput
          label="Name"
          placeholder="Enter space name"
          isRequired
          name="name"
          onChange={onChange}
          onBlur={validate}
          isInvalid={errorState.name}
          error="Name is required"
        />
        {teamMembers.length > 0 ?
          <CheckList
            getData={setMembers}
            data={teamMembers}
            label="Members"
          /> :
          <Flex flexDir="column" alignItems="center" justifyContent="center">
            <Text fontSize="sm" color="gray.500">
              No member to add, grow your team or upgrade plan
            </Text>
          </Flex>
        }
      </ModalBody>
      <CustomModalFooter
        onAccept={handleAddSpace}
        onCancel={onClose}
        cancelLabel="Close"
        acceptLabel="Create"
        disabled={disabled}
      />
    </Fragment>
  )
}

export default CreateSpace;
