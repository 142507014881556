import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

const {
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_PROJECT_NUMBER,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_RECAPTCHA_SITE_KEY,
} = process.env;

export const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_PROJECT_ID + ".firebaseapp.com",
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_PROJECT_ID + ".appspot.com",
  messagingSenderId: REACT_APP_FIREBASE_PROJECT_NUMBER,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
  databaseURL: "https://" + REACT_APP_FIREBASE_PROJECT_ID + ".firebaseio.com",
  recaptchaSiteKey: REACT_APP_FIREBASE_RECAPTCHA_SITE_KEY,
};

export const app = initializeApp(firebaseConfig);
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(firebaseConfig.recaptchaSiteKey),
  isTokenAutoRefreshEnabled: true,
});
export const ga = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const storage = getStorage(app);
