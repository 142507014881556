import {
  FC, useState, useEffect
} from "react";
import {
  Menu, MenuButton,
  Flex, MenuList, Text
} from "@chakra-ui/react";
import {
  ChevronDown,
  VerticalLine,
  HorizontalLine,
  MenuButtonFlexStyle,
  MenuButtonStyle,
  MenuListStyle,
  TextStyle,
  OrgsList,
  TeamsList
} from "./stylesandtemplates";
import { useAuth } from "context/AuthContext";
import { Org, OrgsType } from "./types";
import { uniqBy } from "lodash";

/**
 * Orgs drop-down menu
 * @returns JSX Element
 */
const OrgsMenu: FC = () => {
  const [org, setOrg] = useState<Org>({ name: "Org", id: 'id' })
  const [orgs, setOrgs] = useState<OrgsType>([]);
  const [team, setTeam] = useState<Org>({ name: "Team", id: 'id' })
  const [teams, setTeams] = useState<OrgsType>([]);

  const {
    userAssociatedOrgs,
    updateDocID, docId,
    updateTeamID, teamId,
    userAssociatedTeams,
    orgIds
  } = useAuth();

  /**
   * Orgs Update method 
   */
  useEffect(() => {
    if (docId !== "" && userAssociatedOrgs[docId]) {
      const { name, id } = userAssociatedOrgs[docId]
      setOrg({ name, id });
      const _orgs = Object.values(userAssociatedOrgs);
      setOrgs(_orgs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAssociatedOrgs[docId], orgIds])

  /**
   * Teams update method
   */
  useEffect(() => {
    if (docId !== "") {
      
      const _teams = uniqBy(userAssociatedTeams, "id");
      if (_teams.length) {
        setTeams(_teams);
        const { name, id } = _teams[0];
        setTeam({ name, id });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId, userAssociatedTeams, teamId])

  /**
   * Handler to update the global docId value
   * @param _orgId docId
   * @returns void
   */
  const orgSelectHandler = (_orgId: string) => {
    updateDocID(_orgId);
  }

  /**
   * Handler to update the team
   * @param _teamId team id
   * @returns void
   */
  const teamSelectHandler = (_teamId: string) => updateTeamID(_teamId);


  return (
    <Menu autoSelect={false} closeOnSelect={false}>
      <MenuButton {...MenuButtonStyle}>
        <Flex {...MenuButtonFlexStyle}>
          <Text>{org.name}</Text>
          <VerticalLine />
          <Text>{team.name}</Text>
          <ChevronDown />
        </Flex>
      </MenuButton>
      <MenuList {...MenuListStyle}>
        <Text {...TextStyle}>Select Org</Text>
        <HorizontalLine />
        <OrgsList onClick={orgSelectHandler} data={orgs} id={org.id} />
        <HorizontalLine width="100%" />
        <Text {...TextStyle}>Teams available</Text>
        <HorizontalLine />
        <TeamsList onClick={teamSelectHandler} data={teams} id={team.id} />
      </MenuList>
    </Menu>
  )
}

export default OrgsMenu;
