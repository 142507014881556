import { BoxProps } from "@chakra-ui/react";
import { ITheme } from "xterm";

export const CUSTOM_ESC = '__FAKE_UI_ESC__';
export const SELECT = '__FAKE_UI_SELECT__';
export const RESET = '[0m';

/**
 * Terminal symbols (ANSI escape codes) source: https://en.wikipedia.org/wiki/ANSI_escape_code
 */
export enum Symbols {
  CURSOR = '\u001B[?25h',
  CURSOR_HIDE = '\u001B[?25l',
  CURSOR_UP = '\u001B[1A',
  CURSOR_DOWN = '\u001B[1B',
  CURSOR_RIGHT = '\u001B[1C',
  CURSOR_LEFT = '\u001B[1D',
  CURSOR_NEXT_LINE = '\u001B[1E',
  CURSOR_PREVIOUS_LINE = '\u001B[1F',
  CURSOR_HORIZONTAL_ABSOLUTE = '\u001B[1G',
  CURSOR_POSITION = '\u001B[1;1H',
  ERASE_DISPLAY = '\u001B[2J',
  ERASE_LINE = '\u001B[2K',
  SCROLL_UP = '\u001B[1S',
  SCROLL_DOWN = '\u001B[1T',
  SAVE_CURSOR = '\u001B[s',
  RESTORE_CURSOR = '\u001B[u',
  INSERT_BLANK = '\u001B[1@',
  INSERT_BLANK_LINE = '\u001B[1L',
  DELETE_LINE = '\u001B[1M',
  ERASE_CHARACTER = '\u001B[1P',
  ERASE_CHARACTER_LINE = '\u001B[1X',
  INSERT_BLANK_CHARACTER = '\u001B[1@',
  INSERT_BLANK_CHARACTER_LINE = '\u001B[1@',
  DELETE_CHARACTER = '\u001B[1P',
  DELETE_CHARACTER_LINE = '\u001B[1X',
  BOLD = '\u001B[1m',
  FAINT = '\u001B[2m',
  ITALIC = '\u001B[3m',
  UNDERLINE = '\u001B[4m',
  BLINK = '\u001B[5m',
  BLINK_FAST = '\u001B[6m',
  REVERSE = '\u001B[7m',
  HIDDEN = '\u001B[8m',
  STRIKETHROUGH = '\u001B[9m',
  DEFAULT = '\u001B[10m',
  BOLD_OFF = '\u001B[21m',
  BOLD_FAINT_OFF = '\u001B[22m',
  ITALIC_OFF = '\u001B[23m',
  UNDERLINE_OFF = '\u001B[24m',
  BLINK_OFF = '\u001B[25m',
  BLINK_FAST_OFF = '\u001B[26m',
  REVERSE_OFF = '\u001B[27m',
  HIDDEN_OFF = '\u001B[28m',
  STRIKETHROUGH_OFF = '\u001B[29m',
  BLACK = '\u001B[30m',
  RED = '\u001B[31m',
  GREEN = '\u001B[32m',
  YELLOW = '\u001B[33m',
  BLUE = '\u001B[34m',
  MAGENTA = '\u001B[35m',
  CYAN = '\u001B[36m',
  WHITE = '\u001B[37m',
  DEFAULT_COLOR = '\u001B[39m',
  BACKGROUND_BLACK = '\u001B[40m',
  BACKGROUND_RED = '\u001B[41m',
  BACKGROUND_GREEN = '\u001B[42m',
  BACKGROUND_YELLOW = '\u001B[43m',
  BACKGROUND_BLUE = '\u001B[44m',
  BACKGROUND_MAGENTA = '\u001B[45m',
  BACKGROUND_CYAN = '\u001B[46m',
  BACKGROUND_WHITE = '\u001B[47m',
  BACKGROUND_DEFAULT = '\u001B[49m',
  BRIGHT_BLACK = '\u001B[90m',
  BRIGHT_RED = '\u001B[91m',
  BRIGHT_GREEN = '\u001B[92m',
  BRIGHT_YELLOW = '\u001B[93m',
  BRIGHT_BLUE = '\u001B[94m',
  BRIGHT_MAGENTA = '\u001B[95m',
  BRIGHT_CYAN = '\u001B[96m',
  BRIGHT_WHITE = '\u001B[97m',
  BACKGROUND_BRIGHT_BLACK = '\u001B[100m',
  BACKGROUND_BRIGHT_RED = '\u001B[101m',
  BACKGROUND_BRIGHT_GREEN = '\u001B[102m',
  BACKGROUND_BRIGHT_YELLOW = '\u001B[103m',
  BACKGROUND_BRIGHT_BLUE = '\u001B[104m',
  BACKGROUND_BRIGHT_MAGENTA = '\u001B[105m',
  BACKGROUND_BRIGHT_CYAN = '\u001B[106m',
  BACKGROUND_BRIGHT_WHITE = '\u001B[107m',
  START_BOLD = '\u001B[1m',
  STOP = '\u001B[0m',
}

/**
 * Custom Format styles
 */
export const styleMatches = {
  singleQuotes: /'([^']*)'/g,
  doubleQuotes: /"([^"]*)"/g,
  url: /(https?:\/\/[^\s]+)/g,
  curley: /{([^}]*)}/g,
  number: / (\d+) /g,
  openCurley: "{",
  closeCurley: "}",
};

export const EOLBreaks = {
  clsLine: '\x1b[G',
  startEntry: /\[\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}]/g,
  atLine: /\s+at\s/g,
  matchSpaces: /\s{24} +/g,
}

export const formatSymbols = {
  curley: /[{}]/g,
  round: /[()]/g,
  angle: /[<>]/g,
  boolean: /true|false/g,
  number: / \d+ +/g,
}

export const formatSymColors = {
  curley: `${Symbols.MAGENTA}$&${Symbols.STOP}`,
  round: `${Symbols.YELLOW}$&${Symbols.STOP}`,
  angle: `${Symbols.GREEN}$&${Symbols.STOP}`,
  boolean: `${Symbols.BRIGHT_BLUE}$&${Symbols.STOP}`,
  number: `${Symbols.BRIGHT_BLUE}$&${Symbols.STOP}`,
}

/**
 * Resizable box configurations for the console
 * @export boxConfig enum
 * @enum {number} Number constants
 * @property {number} minWidth Minimum width of the box
 * @property {number} minHeight Minimum height of the box
 * @property {number} width Width of the box
 * @property {number} height Height of the box
 */
export enum boxConfig {
  minWidth = 600,
  minHeight = 500,
  width = 1200,
  height = 716,
}

/**
 * Resizable box handle position styles
 */
export const handlePositions = {
  'left': {
    left: 0,
    cursor: 'ew-resize',
    top: 0,
    bottom: 0,
    height: '100%',
    width: '4px'
  },
  'right': {
    right: 0,
    cursor: 'ew-resize',
    top: 0,
    bottom: 0,
    height: '100%',
    width: '4px'
  },
  'top': {
    top: 0,
    cursor: 'ns-resize',
    left: 0,
    right: 0,
    width: '100%',
    height: '4px'
  },
  'bottom': {
    bottom: 0,
    cursor: 'ns-resize',
    left: 0,
    right: 0,
    width: '100%',
    height: '4px'
  },
}

/**
 * Initial cursor positions for the resizable box
 */
export const initialPosition = {
  clientX: 0,
  clientY: 0,
  startX: 0,
  startY: 0,
  dX: 0,
  dY: 0,
  startWidth: 0,
  startHeight: 0,
}

/**
 * Flexbox styles for the resizable box
 */
export const BoxStyle: BoxProps = {
  position: 'absolute',
  top: ".5rem",
  right: "1rem",
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 10px',
  flexDirection: "row",
  gap: "1rem",
}

export const TerminalTheme: ITheme = {
  background: '#fff',
  foreground: '#555',
  cursor: '#fff',
  cursorAccent: '#00f',
  black: '#555',
  red: '#f00',
  green: '#00D768',
  yellow: '#8f8f3a',
  blue: '#00f',
  magenta: '#f0f',
  cyan: '#0ff',
  white: '#fff',
  brightBlack: '#000',
  brightRed: '#f00',
  brightGreen: '#00D768',
  brightYellow: '#8f8f3a',
  brightBlue: '#0096FF',
  brightMagenta: '#f0f',
  brightCyan: '#0ff',
  brightWhite: '#fff',
  selectionBackground: '#000',
  selectionForeground: '#fff',
  selectionInactiveBackground: '#555'
}

export const TerminalThemDark: ITheme = {
  ...TerminalTheme,
  background: '#000',
  foreground: '#d4d4d4',
  cursor: '#fff',
  cursorAccent: '#00f',
  selectionBackground: '#fff',
  selectionForeground: '#000',
  selectionInactiveBackground: '#D4D4D4',
  blue: "#5050f8",
  brightBlack: "#8372ff"
}

export const TerminalThemeLight: ITheme = {
  ...TerminalTheme,
  background: '#fff',
  foreground: '#555',
  cursor: '#fff',
  cursorAccent: '#00f',
  selectionBackground: '#000',
  selectionForeground: '#fff',
  selectionInactiveBackground: '#555',
}
