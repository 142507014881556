// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";

import ColumnsTable from "views/admin/dataTables/components/ColumnsTable";

import { columnsDataColumns } from "views/admin/dataTables/variables/columnsData";
import { useState, useEffect } from "react";
import { useAuth } from "context/AuthContext";
import InvalidRole from "common/InvalidRole";

export default function MainDashboard() {
  const [tableData, setTableData] = useState([]);
  const { spaces, getTotalPages }: { spaces: any; getTotalPages: any } =
    useAuth();

  useEffect(() => {
    if (spaces.length > 0) {
      getTotalPages(spaces);
    }
    // eslint-disable-next-line
  }, [spaces]);

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

      
      <InvalidRole />
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <ColumnsTable columnsData={columnsDataColumns} tableData={tableData} />
      </SimpleGrid>
    </Box>
  );
}
