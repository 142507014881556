import { FlexProps, InputProps, ButtonProps, IconProps, FormLabelProps } from "@chakra-ui/react";

export const InputFlexStyle: FlexProps = {
  flexDir: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  // borderColor: borderColor,
  border: '1px solid',
  borderRadius: '1rem',
  // _focusWithin: {borderColor: borderFocus}
}

export const InputStyle: InputProps = {
  border: 'none',
  _focus: {border: 'none'},
  _active: {border: 'none'},
  pr: '0',
  type: 'text',
  autoComplete: 'off'
}

export const ButtonStyle: ButtonProps = {
  w: '1rem',
  h: '1rem',
  bg: 'transparent',
  p: '0',
  m: '0',
  minW: '0',
  minH: '0',
  _focus: {bg: 'transparent'},
  _active: {bg: 'transparent'},
  _hover: {bg: 'transparent'}
}

export const IconStyle: IconProps = {
  w: '1rem',
  h: '1rem',
  m: '0',
  p: '0',
  color: 'gray',
  cursor: 'pointer',
}

export const LabelStyle: FormLabelProps = {
  cursor: 'pointer',
  fontSize: 'sm',
  mb: '.25rem',
  textTransform: 'capitalize'
}