import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastVaues = {};

export const ErrorCotainer = (
  <ToastContainer
    position="top-left"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
);

export const MsgContainerTopCenter = (
  <ToastContainer
    position="top-center"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
);

export const errorMessage = (message: string) => {
  if (message.includes("email-already-in-use")) {
    return toast.error("Email already in use");
  } else if (message.includes("weak-password")) {
    return toast.error("Password too weak");
  } else if (message.includes("user-not-found")) {
    toast.error("User not found with this email");
  } else if (message.includes("wrong-password")) {
    toast.error("Incorrect password");
  } else if (
    message.includes("invalid-email") ||
    message.includes("missing-email")
  ) {
    return toast.error("Please provide a valid email address");
  } else {
    return toast.error(message);
  }
};
