import React, { FC } from 'react';
import { Flex, Icon, Box, IconProps, BoxProps } from '@chakra-ui/react';
import { MdDelete } from 'react-icons/md';
import { Prompt } from 'views/admin/cloudSettings/environment/prompt';
import { pkgs } from 'functions';


interface EditMetaProps extends BoxProps {
  deleteHandler: () => void;
  index: number;
}
export const EditMeta: FC<EditMetaProps> = (props) => {
  const { deleteHandler, index, ...rest } = props;
  return (
    <Flex flexDir="row" gap="1rem" position="absolute" top=".2rem" right="2%"
      zIndex={1} {...rest}
    >
      <Prompt
        prompt={`module${index}${pkgs.ext}`}
        action={deleteHandler}
      >
        <Box cursor="pointer">
          <IconDisp icon={MdDelete} tooltiptext="Delete" />
        </Box>
      </Prompt>
    </Flex >
  );
};

interface IconDispProps extends IconProps {
  icon: any;
  tooltiptext: string;
}

const IconDisp: FC<IconDispProps> = ({ icon, tooltiptext, ...props }) => {
  return (
    <Box className='edit-meta-icon-container'>
      <Box className='edit-meta-icon-tooltip'>
        {tooltiptext}
      </Box>
      <Icon as={icon} boxSize={6} {...props} />

    </Box>
  );
};
