import {
  Box,
  Select,
  FormLabel,
  Flex,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { useAuth } from "context/AuthContext";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { uniqBy } from "lodash";
import { dispatchPopup } from "components/popups";

type Props = {
  showbtn?: boolean;
  disableBtn?: boolean;
};

const Dropdown: React.FC<Props> = ({ showbtn, disableBtn }) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const {
    spaces: allSpaces,
    getSpaceId,
    getPlanId,
    spaceId,
    planId,
    cloudReleaseTab,
    cloudDesignTab,
  } = useAuth();
  const [plans, setPlans] = useState<any>([]);
  const [change, setChange] = useState<boolean>(false);
  const [spaces, setSpaces] = useState<any>([]);

  const { pathname } = useLocation();
  const splitSpaceId = pathname.split("/");
  const urlSpaceId = splitSpaceId[3];
  const urlPlanId = splitSpaceId[4];
  const urlTabId = splitSpaceId[splitSpaceId.length - 1];

  const history = useHistory();

  useEffect(() => {
    if (change) {
      if (pathname.includes("cloud-observe")) {
        history.push(`/dashboard/cloud-observe/${spaceId}/${planId}`);
      } else if (pathname.includes("cloud-release")) {
        history.push(
          `/dashboard/cloud-release/${spaceId}/${planId}/${urlTabId}`
        );
      } else if (pathname.includes("cloud-design")) {
        history.push(
          `/dashboard/cloud-design/${spaceId}/${planId}/${urlTabId}`
        );
      }
    }
  }, [pathname, spaceId, planId, change, history, cloudReleaseTab, urlTabId]);

  useEffect(() => {
    if (urlSpaceId && !change) {
      spaces.forEach((space: any) => {
        if (space.id === urlSpaceId) {
          getSpaceId(urlSpaceId);
          if (space.plans.length > 0) {
            getPlanId(urlPlanId);
            setPlans(space.plans);
          } else {
            const plan = [{ id: "no-plan", name: "No Plan" }];
            setPlans(plan);
            getPlanId(urlPlanId);
          }
        }
      });
    } else {
      if (!change) {
        if (spaces.length > 0) {
          getSpaceId(spaces[0].id);
          if (spaces[0].plans.length > 0) {
            getPlanId(spaces[0].plans[0].id);
          }
        }
        if (spaceId !== "") {
          spaces.forEach((space: any) => {
            if (space.id === spaceId) {
              if (space.plans.length > 0) {
                setPlans(space.plans);
              } else {
                const plan = [{ id: "no-plan", name: "No Plan" }];
                setPlans(plan);
              }
            }
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [spaces, spaceId, planId, urlSpaceId, urlPlanId, change]);

  // Filter spaces to display only the active ones in the DDL
  useEffect(() => {
    if (allSpaces.length > 0) {
      const uniqSpaces = uniqBy(allSpaces, "id");
      const spaces = uniqSpaces.filter((space: any) => space.status === "active");
      setSpaces(spaces);
    }
  }, [allSpaces]);

  const spaceChangeHandler = (spaceId: string) => {
    setChange(true);
    getSpaceId(spaceId);
    spaces.forEach((space: any) => {
      if (space.id === spaceId) {
        if (space.plans.length > 0) {
          setPlans(space.plans);
          getPlanId(space.plans[0].id);
        } else {
          const plan = [{ id: "no-plan", name: "No Plan" }];
          setPlans(plan);
          getPlanId(plan[0].id);
        }
      }
    });
  };
  const planChangeHandler = (spaceId: string) => {
    setChange(true);
    getPlanId(spaceId);
  };

  const AddPlan = dispatchPopup("AddPlan", { spaceId })

  // const uniqPlans = uniqBy(plans, "id");

  return (
    <Flex direction={{ base: "column", md: "row" }} justifyContent="center">
      <Flex
        direction="row"
        justifyContent="center"
        alignItems="center"
        background="transparent"
        borderRadius="15px"
      >
        <Box w={{ base: "100%", md: "250px" }} maxW="100%" ml="16px" mr="16px">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Spaces
          </FormLabel>
          <Select
            size={"lg"}
            mb="8"
            fontSize="sm"
            sx={{ borderRadius: "16px" }}
            name="permission"
            textTransform="capitalize"
            onChange={(e) => spaceChangeHandler(e.target.value)}
            value={spaceId}
          >
            {spaces.length > 0 &&
              spaces.map((space: any) => (
                <option key={space.id} value={space.id}>
                  {space.name}
                </option>
              ))}
          </Select>
        </Box>
      </Flex>
      <Flex
        direction="row"
        justifyContent="center"
        alignItems="center"
        background="transparent"
        borderRadius="15px"
      >
        <Box w={{ base: "100%", md: "250px" }} maxW="100%" ml="16px" mr="16px">
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            Plans
          </FormLabel>
          <Select
            size={"lg"}
            mb="8"
            fontSize="sm"
            sx={{ borderRadius: "16px" }}
            name="permission"
            textTransform="capitalize"
            onChange={(e) => planChangeHandler(e.target.value)}
            value={planId}
          >
            {plans.length > 0 &&
              uniqBy(plans, "id").map((plan: any) => (
                <option key={plan.id} value={plan.id}>
                  {plan.name}
                </option>
              ))}
          </Select>
        </Box>
      </Flex>
      <Flex
        direction="row"
        justifyContent="center"
        alignItems="center"
        background="transparent"
        borderRadius="15px"
      >
        <Box
          w={{ base: "100%", md: "100px" }}
          maxW="100%"
          ml="16px"
          mr="16px"
          mb="8px"
        >
          <Flex flexDir="row" gap="1.5rem">
            {showbtn && (
              <Link
                to={`/dashboard/cloud-design/${spaceId}/${planId}/${cloudDesignTab}`}
              >
                <Button colorScheme="blue" disabled={disableBtn}>Go</Button>
              </Link>
            )}
            {!disableBtn &&
              <Box >
                <Button colorScheme="blue"
                  onClick={AddPlan}
                >
                  Add Plan
                </Button>
              </Box>
            }
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default React.memo(Dropdown);
