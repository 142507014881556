import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";
import { useEffect, useState } from "react"
import { db } from "firebase-local/config";
import { updateDoc, doc, collection, query, where, onSnapshot, limit } from "firebase/firestore";
import { AutoSave } from "components/navbar/savechanges";
import { col } from "functions"


/**
 * Custom hook to handle Autosaving plans in both the diagram and code editor 
 * @returns Object containing utility funtions and variables
 */
export const useAutoSave = () => {
  const {
    spaces,
    permission,
    docId,
    spaceId,
    planId
  } = useAuth();

  const {
    changeCount,
    autoSave,
    autoSaveChanges,
    diagramData,
    initialDiagramData,
    updateDiagramData
  }: any = useUser();

  const [updateData, setUpdateData] = useState(false);
  const [saveCount, setSaveCount] = useState(1);
  const [btnId, setBtnId] = useState<number>(1);

  /**
   * Handle saving plans to firestore plans sub-collection after loading 
   */
  const saveBlueprint = () => {

    // Plan doc reference
    const plansRef = doc(
      db, "orgs", docId, "teams", docId, "spaces", spaceId,
      "plans", planId
    );

    // Update plan doc
    updateDoc(plansRef, {
      content: diagramData,
    })
      .then(() => {
        if (saveCount !== 1) {
          AutoSave.display("Changes Saved!");
        }
        setSaveCount(saveCount + 1);
        setUpdateData(false);
      })
      .catch(({ message }) => {
        AutoSave.error(message);
      });
  };

  /**
   * Handle saving plans to firestore plans sub-collection after loading 
   * the user data
   * @returns void
   */
  const handleSaving = () => {
    /* Return if spaces aren't loaded yet */
    if (!spaces.length) return () => { };

    if (changeCount === 1 && autoSave) {
      setUpdateData(true);
      autoSaveChanges(false);
    }

    /**
     * A worker process that checks for changes on plan every 5 secs and 
     * saves the plan to firestore plans sub-collection 
     */
    const worker = setInterval(() => {
      if (updateData && permission !== "viewer") {
        saveBlueprint();
        setUpdateData(false);
        // if (!isEmpty(diagramData.nodes)) {
        //   setUpdateData(false);
        // }
      }
    }, 5000);

    /**
     * Cleanup function
     */
    return () => {
      clearInterval(worker);
    }
  }

  // eslint-disable-next-line
  useEffect(handleSaving, [
    autoSave,
    diagramData,
    changeCount,
    permission,
    updateData,
    spaces,
    initialDiagramData
  ]);


  /**
   * Handle button click to facilitate changes within the editor winds
   * code | diagram | code + diagram
   * @param {number} id indicate which editor option is selected
   */
  const handleBtnClick = (id: number) => {
    setBtnId(id);
    updateDiagramData(true);
    setSaveCount(1);
  };

  const [deployStatus, setDeployStatus] = useState(false);

  // Check plan deployed status
  useEffect(() => {

    if (docId === "" || spaceId === "" || planId === "" || planId === "no-plan")
      return setDeployStatus(false);

    const runRef = collection(db, col.orgs, docId, col.teams, docId, col.spaces, spaceId, col.runs);
    const runsQuery = query(runRef,
      where("plan", "==", planId),
      where("status", "in", ["running", "succeeded"]),
      limit(1)
    );

    // Listen for changes in plans deployed status
    const unsubscribe = onSnapshot(runsQuery, (snapshot) => {
      if (snapshot.docs.length) {
        setDeployStatus(true);
      } else {
        setDeployStatus(false);
      }
    });

    return unsubscribe;
  }, [docId, planId, spaceId]);

  /**
   * Utility functions and variables
   */
  return { btnId, setBtnId, handleBtnClick, deployStatus }
}
