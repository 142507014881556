import React, { useEffect, useState } from "react";
import { Box, useDisclosure } from "@chakra-ui/react";
import {AutoSave} from "components/navbar/savechanges";
import { getPopup } from "../record";

const { Events } = AutoSave;

/**
 * A custom hook that controls the popups 
 * @returns Object containing useful functions and values
 */
export const usePopups = () => {
  const [Popup, setPopup] = useState<React.FC<any>>(Box as React.FC<{}>);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [props, setProps] = useState<any>({});
  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    const listener = Events.on('select-popup-element', data => {
      const [key, args] = data;
      const popup = getPopup(key);
      if(popup) {
        setPopup(popup.element);
        setTitle(popup.title);
      }
      setProps(args);
      onOpen();
    });

    return () => {
      if(listener) listener.remove('select-popup-element');
    }
  }, [onOpen]);

  return { getPopup, Popup, props, isOpen, onClose, title };
}