import { POPUPS, PopupDispatchProps, GetFieldType} from "../types"
import { AutoSave } from "components/navbar/savechanges"

const { Events } = AutoSave;

/**
 * Dispatch popups
 * @param name Name of the popup to dispatch
 * @param props Props required by the popup
 * @returns A function that dispatches an event to the popup
 */
export const dispatchPopup = (name: POPUPS, props: GetFieldType<PopupDispatchProps, POPUPS>) => {
  return () => {
    Events.emit("select-popup-element", [name, props]);
  }
}
