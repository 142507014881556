import {
  Flex,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Box,
  Td,
  Checkbox,
} from "@chakra-ui/react";
import { FaSort } from "react-icons/fa";

import Card from "components/card/Card";

const headData = [
  { id: 1, title: "Service" },
  { id: 2, title: "Running Version" },
  { id: 3, title: "Latest Version" },
  { id: 4, title: "Select" },
];

const tableData = [
  {
    id: 1,
    title: "First service list",
    runningV: "1234l-kh1234-1234",
    latestV: "29374qs-asdf-asdf",
  },
  {
    id: 2,
    title: "Second service list",
    runningV: "1234l-kh1234-1234",
    latestV: "29374qs-asdf-asdf",
  },
  {
    id: 3,
    title: "Third service list",
    runningV: "1234l-kh1234-1234",
    latestV: "29374qs-asdf-asdf",
  },
  {
    id: 4,
    title: "Fourth service list",
    runningV: "1234l-kh1234-1234",
    latestV: "29374qs-asdf-asdf",
  },
  {
    id: 5,
    title: "Fifth service list",
    runningV: "1234l-kh1234-1234",
    latestV: "29374qs-asdf-asdf",
  },
];

function ServiceList() {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Table variant="simple" color="gray.500" mb="24px">
        <Thead>
          <Tr>
            {headData.map(({ id, title }) => (
              <Th key={id} borderColor={borderColor}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap="8px"
                >
                  {id === 1 && <FaSort cursor="pointer" />}

                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {title}
                  </Flex>
                </Box>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody color="black">
          {tableData.map((service) => (
            <Tr
              fontSize="14px"
              color={textColor}
              textTransform="capitalize"
              key={service.id}
            >
              <Td>{service.title}</Td>
              <Td>{service.runningV}</Td>
              <Td>{service.latestV}</Td>

              <Td>
                <Checkbox colorScheme="blue" borderColor="gray.300" />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Card>
  );
}

export default ServiceList;
