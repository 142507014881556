import { useAuth } from "context/AuthContext";
import { useCallback, useEffect, useState } from "react";
import { EmptyMembersData as empty } from "./stylesandtemplates";
import { Return, Environments } from "./types";
import ClientAccess from "functions/clientaccess";
import { toast } from 'react-toastify';
import { AutoSave } from "components/navbar/savechanges";
import { UseShare } from "./types";
import { collection, DocumentData, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { db } from "firebase-local/config";
import { pkgs, RunJob, col } from "functions";
import { dispatchPopup } from "components/popups";

/**
 * Retrieves the available environments within the selected space
 * @returns An array containing environments and a function to manually
 * retrieve the environments
 */
function useEnvironments(isOpen: boolean): Return {
  const { spaces, spaceId, docId, teamId, planId } = useAuth();
  const [environments, setEnvironment] = useState<Environments>([]);
  const [runs, setRuns] = useState<RunJobSchema[]>([]);
  const [vendor, setVendor] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /**
   * Run deploy command by saving a runJob to the runs collection
   */
  const runDeploy = async (environmentId: string) => {
    AutoSave.display('Initiating launch please wait...', { action: 'loading' })
    const runRef = collection(db, col.orgs, docId,
      col.teams, docId, col.spaces, spaceId, col.runs
    )
    const entry = new RunJob(docId, planId, environmentId, runRef, "deploy")
    try {
      const props = await entry.saveData()
      AutoSave.followDeploy(props)
    } catch (e) {
      AutoSave.error('Failed to launch');
      console.log('Deploy error', e)
    }
  }

  /**
   * Run a destroy command by saving a runJob to the runs collection
   */
  const runDestroy = async (environmentId: string) => {
    AutoSave.display('Initiating launch please wait...', { action: 'loading' })
    const runRef = collection(db, col.orgs, docId,
      col.teams, docId, col.spaces, spaceId, col.runs
    )
    const entry = new RunJob(docId, planId, environmentId, runRef, "destroy")
    try {
      const props = await entry.saveData()
      AutoSave.followDeploy(props)
    } catch (e) {
      AutoSave.error('Failed to launch');
    }
  }


  /**
   * A method to retrieve a list of environments from the selected space
   * @returns an array of available environments
   */
  const getSpace = useCallback(() => new Promise((resolve, _) => {
    if (spaceId && spaces.length) {
      spaces.every(space => {
        if (space.id === spaceId) resolve(space)
        return true;
      })
    }
  }), [spaceId, spaces]);

  /**
   * Retrieves a list of environments from the selected space
   */
  const getEnvironments = useCallback(async () => {
    const space: any = await getSpace()
      .catch((error: Error) => {
        console.error("getEnvironments() ", error.message)
      });
    if (space.environments && space.environments.length) {
      try {
        /**
         * Filter the environments based on the vendor of the selected plan
         */
        const envs = filterEnvs(space.environments, vendor);
        setEnvironment(envs);
      } catch (error: any) {
        console.error("getEnvironments() ", error.message);
      }
    }
  }, [getSpace, vendor]);

  /**
   * Retrieves a list of runs from the selected plan
   */
  const getRuns = useCallback(async (environmentId: string) => { 
    const runsRef = collection(db, "orgs", docId, "teams", teamId, "spaces", spaceId, "runs");

    const q = query(runsRef, where("plan", "==", planId), where("environment", "==", environmentId), orderBy("finishTime", "desc"), limit(5));

    try {
      const runDocs: DocumentData = await getDocs(q);
      if (runDocs.exist && runDocs.docs.length > 0) {
        setRuns(runDocs.docs);
      }
    } catch (error) {
      console.error("Error getting 'run' documents: ", error as Error)
    }

  }, [docId, spaceId, teamId, planId])

  const [packageId, setPackageId] = useState<string>('');

  // Update the packageId and vendor when the planId changes
  useEffect(() => {
    if (!isOpen || planId === "" || spaceId === "" || docId === "") return;
    (async () => {
      setIsLoading(true);
      const packageId = await pkgs.getPackageId(docId, spaceId, planId);
      const vendor = await pkgs.getPackageVendor(packageId);
      setPackageId(packageId);
      setVendor(vendor);
      setIsLoading(false);
    })();
  }, [planId, spaceId, docId, isOpen]);


  /**
   * UseEffect body
   */
  function Run() {
    if(!isOpen) return;
    getEnvironments();
  }

  /**
   * Use effect hook to retrieve the environments
   */
  useEffect(Run, [getEnvironments, getRuns, isOpen]);

  /**
   * Start a Create New Environment flow in the cloud settings page
   * takes in spaceId and vendor as query params
   * if vendor is not provided, the user will be prompted to select a vendor
   * otherwise the vendor input will be locked to the provided vendor
   */
  const createNewEnv = (cb?: () => void) => dispatchPopup("AddEnv", { spaceId, vendor, onSuccess: cb })();

  /**
   * Update the plan package with the selected package id value
   * @param pkg package id
   */
  const updatePlanPackage = async (pkg: string) => {
    await pkgs.updatePlan(docId, spaceId, planId, pkg)
      .then(async () => {
        setPackageId(pkg);
        const vendor = pkgs.packages.find(p => p.id === pkg)?.package.vendor;
        setVendor(vendor);
        await getEnvironments();
      });
  }

  return {
    environments,
    runs,
    runDeploy,
    getRuns,
    runDestroy,
    vendor,
    createNewEnv,
    packageId,
    updatePlanHandler: updatePlanPackage,
    isLoading
  }
}

export default useEnvironments;

/**
 * Custom hook for the share plan activity
 * @param onClose Funtion to invoke when closing the share modal
 * @returns Assorted methods and attributes
 */
export const useShare: UseShare = (onClose) => {
  const funcs = new ClientAccess();
  const { docId, members } = useAuth()
  const [invite, setInvite] = useState(true);
  const [member, setMember] = useState({ ...empty })

  /**
   * Check if the user is already listed as a member
   */
  const onCheckMember = () => {
    const { email } = member;
    members.every(_member => {
      if (_member.email === email) {
        setInvite(false)
        return false;
      }
      return true
    })
  }

  /**
   * Update data fields as the the user types them in
   * @param e input event
   */
  const onChangeHandler = (e: any) => {
    const { name, value } = e.target;
    setMember({ ...member, [name]: value });
    if (name === 'email') {
      setInvite(true)
    }
  }

  /**
   * Handle invite member operation
   */
  const onInviteMember = async () => {
    onCheckMember();
    if (invite) {
      const res = await funcs.inviteTeamMembers(docId, member);
      if (res === 'SUCCESS') {
        setMember({ ...member, ...empty })
        onClose();
        AutoSave.display('Member added succesfully');
      } else {
        onClose();
        toast.error("Failed to add member")
      }
    }
  }
  return { onChangeHandler, onInviteMember, onCheckMember, invite }
}

export const filterEnvs = (envs: any, filter: string) => {
  return envs.filter((env: any) => env.vendor === filter)
}
