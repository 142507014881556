import { Flex } from "@chakra-ui/react";
import ReactPlayer from "react-player";
import video from "../assets/videos/video.mp4";

const VideoPlayer = () => {
  return (
    <Flex direction="row" justify="center" align="center" mb="32px">
      <ReactPlayer
        url={video}
        controls={true}
        playing={true}
        muted={true}
        width="60%"
        height="30%"
      />
    </Flex>
  );
};

export default VideoPlayer;
