import { useColorModeValue } from "@chakra-ui/system";
import { useEffect, useRef, useState } from "react";
import { useUser } from "context/UserContext";
import { useAuth } from "context/AuthContext";

import { AutoSave } from "components/navbar/savechanges";
import { Session } from "functions/sessionhandler";

const { Events } = AutoSave;

const session = new Session();

export const useCodeEditor = () => {
  const editorRef = useRef(null);
  const monacoRef = useRef(null);
  const [changes, setChanges] = useState(0);
  const pos = useRef(null);
  const [autosave, setAutosave] = useState(false);
  const [buffer, setBuffer] = useState<string>(null);
  const { spaceId, planId } = useAuth();

  const {
    updateModule,
    deleteModule,
    setModuleIndex,
    modules,
    packageInfo,
    moduleIndex,
    getDiagramData,
    activity,
    setActivity,
  } = useUser();


  const theme = useColorModeValue("vs", "vs-dark")

  const setRef = (editor: any, _monaco: any) => {
    editorRef.current = editor;
    monacoRef.current = _monaco;
  }

  /**
   * Update the buffer when the module changes
   */
  useEffect(() => {
    if (!activity) {
      setBuffer(modules[moduleIndex]);
    }
  }, [activity, modules, moduleIndex, packageInfo]);

  /**
   * Remember which module was last edited in the current session
   * and load it on page refresh
   */
  useEffect(() => {
    const index = session.getItem(`editor-${spaceId}/${planId}`);
    if (!index) {
      setModuleIndex(0);
      return;
    }
    const idx = parseInt(index, 10);
    setModuleIndex(idx);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Auto save on ctrl + s
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "s") {
        e.preventDefault();
        setAutosave(true);
        setChanges(2)
      }
    };
    window.addEventListener("keydown", listener);
    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

  /**
   * Recieves info from toolbar on which content to open for editiong
   * or viewing from a list of config file and custom codes array
   */
  useEffect(() => {
    if (!modules.length) return;
    const listener = Events.on("editor-open-file", (data) => {
      const [{ index }] = data;

      setChanges(0);
      setModuleIndex(index);
      setBuffer(modules[index]);
    });

    return () => {
      listener.remove("editor-open-file");
    }

  }, [modules, packageInfo.language, setModuleIndex]);

  /**
   * Onchange handler for code editor - updates buffer and cursor position
   * @param value editor value
   * @param _index code block index
   */
  const onChangeHandler = async (value: string, _index: number) => {
    if (changes < 2) {
      setChanges(changes + 1);
    }
    !activity && setActivity(true);
    if (moduleIndex !== _index) return;
    setBuffer(value);
    pos.current = editorRef.current.getPosition();
    setAutosave(true);
  }

  /**
   * Auto save changes to the plan data
   */
  useEffect(() => {
    if (!autosave || changes < 2) return;
    (async () => {
      if (moduleIndex === 0) {
        try {
          JSON.parse(buffer);
        } catch (error) {
          return;
        }
        getDiagramData(buffer);
      }
      await updateModule(moduleIndex, buffer);
      setAutosave(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autosave, buffer, moduleIndex, changes]);

  /**
   * Delete a custom code block
   */
  const deleteHandler = async (index: number) => {
    if (index <= 1) return;

    await deleteModule(index);
    setModuleIndex(index - 1);
  }


  return {
    configs: {
      theme,
      language: moduleIndex === 0 ? "json" : packageInfo.language || "typescript",
      onMount: setRef,
      value: buffer,
    },
    index: moduleIndex,
    onChangeHandler,
    deleteHandler,
    blockNavigation: buffer !== modules[moduleIndex],
  }
};
