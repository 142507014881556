/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/marketplace/components/Banner";
import TableTopLaunches from "views/admin/marketplace/components/TableTopLaunches";
import Card from "components/card/Card";
import { SaasTemplate } from "./components/SaasTemplate";
import { Post } from "./components/post";

// Assets
import tableDataTopLaunches from "views/admin/marketplace/variables/tableDataTopLaunches.json";
import { tableColumnsTopLaunches } from "views/admin/marketplace/variables/tableColumnsTopLaunches";
import pocketbase from "assets/img/saas/pocketbase.png";
import supabase from "assets/img/saas/supabase.png";
import fintechcloud from "assets/img/saas/fintechcloud.png"
import photoUrl from "assets/img/avatars/avatarSimmmple.png"

const MockData = [
  {
    title: "Supabase",
    platform: "AWS/GCP/Azure",
    info: [
      "In collaboration with official Supabase project team"
    ],
    logo: supabase
  },
  {
    title: "PocketBase",
    platform: "AWS/GCP/Azure",
    info: [
      "In collaboration with official PocketBase project team"
    ],
    logo: pocketbase
  },
  {
    title: "fintech Cloud",
    platform: "AWS/Azure",
    info: [
      "In collaboration with X, a fintech venture builder",
      "Fintech focused modular infra"
    ],
    logo: fintechcloud
  }
]

const mockPost = {
  photoUrl,
  post: `Introducing FABR Marketplace where our trusted partners offer their expertise and support, ready to go live in minutes.`,
  name: "Chen Wang",
  time: "21:34 Today",
}

export default function Marketplace() {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        mb="20px"
        gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}
      >
        <Flex
          flexDirection="column"
          gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}
        >
          <Banner />
          <Flex
            flexDir="row" gap="3rem" flexWrap="wrap" mt="2rem"
            p="0 1rem 2rem 1rem"
          >
            {MockData.map(product => <SaasTemplate {...product} key={product.title} />)}
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}
        >
          <Post {...mockPost}/>
          <Card px="0px" mb="20px">
            <TableTopLaunches
              tableData={tableDataTopLaunches}
              columnsData={tableColumnsTopLaunches}
            />
          </Card>
        </Flex>
      </Grid>
      {/* Delete Product */}
    </Box>
  );
}
