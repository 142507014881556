import {
  CollectionReference,
  DocumentReference,
  DocumentSnapshot,
  DocumentData,
  Unsubscribe,
  FieldValue
} from "firebase/firestore";

/**
 * List of function names available
 */
export enum functions {
  CreateUser = "CreateUser"
}

/**
 * List of collections available
 */
export enum col {
  orgs = "orgs",
  teams = "teams",
  members = "members",
  spaces = "spaces",
  plans = "plans",
  runs = "runs",
  environments = "environments",
  users = "users",
  pkgs = "pkgs"
}

export interface MemberInvite {
  name: string;
  email: string;
  reference: string;
  permission: string;
}

export interface CreateUser {
  data: {
    name: string;
    email: string;
  }
}

export type UID = string;
export type CheckUser = { id: UID, isUser: boolean }
export interface CreateUserResults {
  email: string;
  verification: string;
  id: string;
  OTP: string;
}

export type MemberInviteReturns = 'USEREXISTS' | 'FAILEDTOCREATE' | 'SUCCESS';
export type DataRef = CollectionReference<DocumentData>;
export type DocRef = DocumentReference<DocumentData>;
export type Snapshot = DocumentSnapshot<DocumentData>;
export type Observer = (snapshot: Snapshot) => Unsubscribe

export type TimeStamp = Date | null;
export type Command = "deploy" | "destroy";
export type Status = "succeeded" | "failed" | "queued" | "running";

export interface runJob {
  environment: string,
  finishTime: TimeStamp,
  member: string,
  command: Command,
  status: Status,
  plan: string,
  startTime: TimeStamp,
  stderr: string,
  stdout: string,
  version: string
  createdAt: FieldValue;
}

export type Plan = {
  name: string,
  /**
   * Package Infa config
   */
  content: string,
  /**
   * ID of the package associated with this plans config
   */
  package: string,
  /**
   * Custom code 
   */
  custom: string[]
}

export type ToolingLanguage = "typescript" | "go" | "python" | "java" | "csharp"
export type ToolingRuntime = "awscdk" | "cdktf" | "pulumi"
export type CloudProvider = "AWS" | "GCP" | "AZR" | "DO" | "ALI" | "CF"

export type Package = {
  name: string,
  language: ToolingLanguage,
  library: string,
  runtime: ToolingRuntime
  vendor: CloudProvider
}

export interface PackageList {
  [id: string] : Package
}

export interface PackageReq {
  docId: string,
  spaceId: string,
  planId: string
}
