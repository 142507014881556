import React, { useState, useEffect, Fragment } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

import Card from "components/card/Card";
// import { HSeparator } from "components/separator/Separator";
import { BasicModal } from "common/Modal";
import { useAuth } from "context/AuthContext";
import { profileTypes } from "../../../types/userTypes";
// import { toast } from "react-toastify";
import { MsgContainerTopCenter } from "utils/authError";
// import * as EmailValidator from "email-validator";
import { Link, useLocation } from "react-router-dom";

import Loader from "../../../common/Loader";
import Team from "./Team";
import Billing from "../billing";
import BackdropOverlay from "common/BackdropOverlay";
import AccessAlert from "common/AccessAlert";
import BlurOverlay from "common/BlurOverlay";

const btnData = [
  { id: 1, name: "Billing" },
  { id: 2, name: "Team" },
];

export default function ProfileTeam() {
  const { userData, currentUser, userEmail, getBillingTeamTab, permission } =
    useAuth();

  const [btnId, setBtnId] = useState<number>(1);
  const [loading] = useState<boolean>(false);
  const [saveClicked, setSaveClicked] = useState<boolean>(false);
  // const [userId, setUserId] = useState<string>("");
  // const [userEmail, setUserEmail] = useState<string>("");

  const { pathname } = useLocation();
  const splitArr = pathname.split("/");
  const splitVal = splitArr[splitArr.length - 1];

  const [updateFields, setUpdateFields] = useState<profileTypes>({
    name: "",
    email: "",
    // country: "",
    currentPassword: "",
    newPassword: "",
    matchPassword: "",
  });

  useEffect(() => {
    if (userEmail !== "") {
      setUpdateFields({
        ...updateFields,
        name: userData.name,
        email: userData.email,
      });
    }
    if (pathname) {
      setBtnId(parseInt(splitVal));
      getBillingTeamTab(parseInt(splitVal));
    }
    // eslint-disable-next-line
  }, [currentUser, userEmail, userData, pathname]);

  const onSaveProfile = async () => {
    setSaveClicked(true);
    setTimeout(() => {
      setSaveClicked(false);
    }, 1000);

  };

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  // const textColorBrand = useColorModeValue("brand.500", "white");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleBtnClick = (id: number) => {
    setBtnId(id);
  };

  const updateBtnId = (id: number) => {
    setBtnId(id);
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {permission !== "admin" && (
        <Fragment>
          <AccessAlert /> <BlurOverlay />
        </Fragment>
      )}
      <BackdropOverlay />
      {MsgContainerTopCenter}
      <Flex flexDirection="column">
        <Card p="0px">
          <Flex
            align={{ sm: "flex-start", lg: "center" }}
            justify="space-between"
            w="100%"
            px="22px"
            py="18px"
          >
            <Text color={textColor} fontSize="xl" fontWeight="600">
              Billing & Team
            </Text>
            <Box>
              {btnData.map(({ id, name }) => (
                <Link to={`/dashboard/profile-team/${id}`} key={id}>
                  <Button
                    key={id}
                    variant={btnId === id ? "action" : "ghost"}
                    onClick={() => handleBtnClick(id)}
                    disabled={
                      permission === "viewer" || permission === "developer"
                    }
                  >
                    {name}
                  </Button>
                </Link>
              ))}
              <Loader loading={loading} />
            </Box>
            <Box>
              {btnId === 1 &&
                <Button
                  colorScheme="blue"
                  onClick={onSaveProfile}
                  disabled={permission === "viewer" || permission === "developer"}
                >
                  Save
                </Button>}
            </Box>

          </Flex>

          {btnId === 1 ? (
            <Billing btnId={btnId} onClickSave={saveClicked} />
          ) : (
            <Team
              btnId={btnId}
              onClickSave={saveClicked}
              updateBtnId={updateBtnId}
            />
          )}
        </Card>
      </Flex>
      <BasicModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
    </Box>
  );
}
