import {
  styleMatches,
  TerminalTheme,
  EOLBreaks,
  formatSymbols,
  formatSymColors,
} from './constants';
import { Terminal } from 'xterm';
import { WebLinksAddon } from 'xterm-addon-web-links';
import { FitAddon } from 'xterm-addon-fit';
import { Symbols } from './constants';

/**
 * Handle displaying contents of standard out and standard err
 */
export class WriteCode extends Terminal {
  codes: string = '';
  /**
   * Uses xterm and Custom formatting to display contents to stdout and stderr
   * @param code The text contents to display
   * @param canvas HTML element to display the contents to
   * @param theme Theme to use for styling the contents
   * @param fitAddon Optional fit addon to resize the terminal contents 
   * to fit the canvas on Canvas resize
   */
  constructor(
    private readonly code = `${Symbols.GREEN}[32mHello World${Symbols.STOP}`,
    private readonly canvas: HTMLElement,
    theme = TerminalTheme,
    public readonly fitAddon = new FitAddon(),
  ) {
    super({
      theme: { ...theme },
      allowProposedApi: true,
      fontSize: 16,
      fontWeight: 'bold',
    });
    this.loadAddon(new WebLinksAddon());
    this.loadAddon(this.fitAddon);
    this.open(this.canvas);
  }

  /**
   * Calls the local style config methods on code then Initiates the xterm
   * write code process
   * @returns fit addon to resize the terminal contents
   */
  public readonly applyXterm = () => {
    const lines = this.splitCode(this.code);
    this.write(lines);
    this.fitAddon.fit();
    return this.fitAddon;
  }

  /**
   * Looks for possible areas to add CRLF line breaks on the supplied code
   * and then applies local style config methods
   * @param code The contents to style
   * @returns Styled code
   */
  private readonly splitCode = (code: string) => {
    const __code = code;
    const styled = this.styleQuotes(__code);
    let __lines = this.addEOLbreaks(styled);
    __lines = this.highlightUrl(__lines);
    __lines = this.styleSymbols(__lines);
    const lines = __lines.split('\n');
    return lines.join('\r\n');
  }

  /**
   * Looks for any possible URL matches in the code and highligts them
   * @param code The contents to style
   * @returns Styled code
   */
  private readonly highlightUrl = (code: string) => {
    let __code = code;
    __code = __code.replaceAll(styleMatches.url, `\x1b[34m$1\x1b[0m`);
    return __code;
  }

  /**
   * Adds New Line breaks to the code following the styles specified
   * in the EOLBreaks constant
   * @param code The contents to style
   * @returns Styled code
   */
  private readonly addEOLbreaks = (code: string) => {
    let __code = code;
    __code = __code.replaceAll(EOLBreaks.clsLine, `${EOLBreaks.clsLine}`);
    __code = __code.replaceAll(EOLBreaks.matchSpaces, `\n$&`);
    __code = __code.replaceAll(EOLBreaks.startEntry, `\n$&`);
    __code = __code.replaceAll(EOLBreaks.atLine, `\n$&`);
    return __code;
  }

  /**
   * Looks for symbols specified in the formatSymbols constant and
   * applies the corresponding color from the formatSymColors constant
   * @param code The constents to style
   * @returns Styled code
   */
  private readonly styleSymbols = (code: string) => {
    let __code = code;
    Object.entries(formatSymbols).forEach(([key, value]) => {
      const __key = key as keyof typeof formatSymbols;
      __code = __code.replaceAll(value, formatSymColors[__key]);
    });
    return __code;
  };

  /**
   * Looks for contents enclosed in single or double quotes and highlights them
   * @param code The contents to style
   * @returns Styled code
   */
  private readonly styleQuotes = (code: string) => {
    let __code = code;
    __code = __code.replaceAll(styleMatches.singleQuotes, `\x1b[32m'$1'\x1b[0m`);
    __code = __code.replaceAll(styleMatches.doubleQuotes, `\x1b[32m"$1"\x1b[0m`);
    return __code;
  }
}