import { Flex, Box, useColorModeValue } from "@chakra-ui/react";

// import DiagramEditor from "./DiagramEditor";
// import JSONEditor from "./JSONEditor";
import { JSONcrack } from "jsoncrack/jsoncrack";
import { CodeEditor } from "./codeeditor";

type Props = {};

const CodePlusDesign: React.FC<Props> = () => {
  // const theme = useColorModeValue("light", "dark")

  return (
    <Flex
      flexDir="row"
      flexWrap="wrap"
    >
      <Box flex={"2 300px"}
        height="550px"
        // paddingTop={theme === "light" ? "36px" : "0px"}
      >
        {/* <JSONEditor /> */}
        <CodeEditor/>
      </Box>
      <Box flex="3 450px"
        h="550px"
        overflowY="auto">
        {/* <DiagramEditor /> */}
        <JSONcrack />
      </Box>
    </Flex>
  );
};

export default CodePlusDesign;
