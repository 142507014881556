import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import React, { useMemo, useEffect, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import { RiArrowDropRightLine, RiArrowDropLeftLine } from "react-icons/ri";
import Dropdown from "common/Dropdown";
import BackdropOverlay from "common/BackdropOverlay";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { useAuth } from "context/AuthContext";
// import moment from "moment";
import Loader from "../../../../common/Loader";
import { RunsDataTable } from "./runs";
import { Session } from "functions/sessionhandler";
import { DateTime } from "functions";

const session = new Session()

export default function ColumnsTable(props: {
  columnsData: any;
  tableData: any;
}) {
  const { columnsData, tableData } = props;

  const headData = [
    { id: 1, title: "Space" },
    { id: 2, title: "Plans" },
    { id: 3, title: "Members" },
    { id: 4, title: "Created At" },
  ];

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const [selector, __setSelector] = useState(0);
  const [showBtn, setShowBtn] = useState(false);

  /**
   * Sets the selector value and stores the value to the
   * session storage
   * @param val selector value
   */
  const setSelector = (val: number) => {
    __setSelector(val);
    session.setItem(Session.keys['dash-board-view'],
      JSON.stringify(val)
    )
  }

  // Retrieve session value on page load if it exists
  useEffect(() => {
    const view = session.getItem(Session.keys["dash-board-view"]);
    if (view) __setSelector(+view);
  }, []);


  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { initialState } = tableInstance;
  initialState.pageSize = 5;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const selectColor = useColorModeValue("#422AFB", "#7551ff");

  const {
    spaces,
    starting,
    ending,
    currentPage,
    totalPages,
    planId,
    spaceId,

    onNextPageClick,
    onPrevPageClick,
  } = useAuth();

  /**
   * Switch to cloud settings page and initiate create new plan in the selected space
   */
  const startCreatePlan = () => {
    document.location = `/#/dashboard/cloud-settings/1?action=create_new_plan&spaceId=${spaceId}`
  }

  const [loading, setLoading] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    if (spaces.length > 0) {
      setLoading(false);

      if (count < 5) {
        setTimeout(() => {
          setCount((prevCount) => prevCount + 1);
        }, 1000);
      }
    }
  }, [spaces, loading, count]);

  useEffect(() => {
    if (planId === "no-plan" || planId === "") {
      setShowBtn(false)
    } else {
      setShowBtn(true);
    }
  }, [planId])

  // const showTimeZone = (time: number) => {
  //   const team = userAssociatedTeams[docId];
  //   if (
  //     (team && team.settings.timeZone === "+1") ||
  //     team.settings.timeZone === "+2"
  //   ) {
  //     const addition = parseInt(team.settings.timeZone);
  //     return moment
  //       .utc(time * 1000)
  //       .add(addition, "hours")
  //       .format("DD/MM/YYYY : HH:mm");
  //   } else if (
  //     (team && team.settings.timeZone === "-1") ||
  //     team.settings.timeZone === "-2"
  //   ) {
  //     const subtraction = parseInt(team.settings.timeZone);
  //     return moment
  //       .utc(time * 1000)
  //       .add(subtraction, "hours")
  //       .format("DD/MM/YYYY : HH:mm");
  //   } else {
  //     if (time) {
  //       return moment.utc(time * 1000).format("DD/MM/YYYY : HH:mm");
  //     }
  //   }
  // };

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <BackdropOverlay />
      <Dropdown showbtn={true} disableBtn={!showBtn}/>

      <Flex direction="row" justifyContent="center" alignItems="center">
        <Loader loading={loading} />
      </Flex>
      <Flex w="100%" h="100px" justifyContent="center" alignItems="center" flexDir="column" gap="2rem"
        display={(showBtn || loading) ? "none" : "flex"}
      >
        <Button colorScheme="blue" w="30%" h="50%" minW="260px"
          borderRadius="100px" fontSize="2rem"
          onClick={startCreatePlan}
        >+ Add New Plan</Button>
      </Flex>
      <Flex px="25px" justify="flex-start" mb="20px" align="center"
        cursor="pointer" gap="2rem"
      >
        {["Last Worked", "Runs"].map((opt, key) =>
          <Text
            key={key}
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
            borderBottom={selector === key ? "solid 3px" : "none"}
            transform={selector === key ? "scale(1.1)" : "none"}
            borderColor={selectColor}
            onClick={() => setSelector(key)}
          >
            {opt}
          </Text>)
        }
      </Flex>
      {selector === 0 ?
        <>
          <Table variant="simple" color="gray.500" mb="24px">
            <Thead>
              <Tr>
                {headData.map(({ id, title }) => (
                  <Th key={id} borderColor={borderColor}>
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {title}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody color="black">
              {spaces.length > 0 &&
                spaces.slice(starting, ending).map((space: any, index: number) => (
                  <Tr
                    key={index}
                    color={textColor}
                    textTransform="capitalize"
                    fontSize="14px"
                  >
                    <Td>{space.name}</Td>

                    <Td>
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {space.plans && space.plans.length > 0
                          ? space.plans.map(
                            (plan: any, index: number) => index === 0 && plan.name
                          )
                          : "No Plan"}
                        {space.plans && space.plans.length > 1 && (
                          <Menu menudata={space.plans} menuid="plans" />
                        )}
                      </span>
                    </Td>

                    <Td textTransform="none">
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        {space.members && space.members.length > 0
                          ? space.members.map(
                            (member: any, index: number) =>
                              index === 0 && member.email
                          )
                          : "No Member"}
                        {space.members && space.members.length > 1 && (
                          <Menu menudata={space.members} menuid="members" />
                        )}
                      </span>
                    </Td>

                    <Td>
                      {/* {showTimeZone(space.createdAt.seconds)} */}
                      {DateTime.getDisplayTime(space.createdAt.toDate())}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>

          <Flex direction="row" justify="flex-end" align="center" mr="32px">
            <Flex direction="row" justify={"flex-start"} align="center" gap="8px">
              <RiArrowDropLeftLine
                fontSize="32px"
                onClick={onPrevPageClick}
                cursor="pointer"
              />
              <Text fontSize="13px">{currentPage}</Text>
              <Text fontSize="14px">of</Text>
              <Text fontSize="13px">{totalPages} </Text>
              <Text fontSize="14px">pages</Text>
              <RiArrowDropRightLine
                cursor="pointer"
                fontSize="32px"
                onClick={onNextPageClick}
              />
            </Flex>
          </Flex>
        </> : <RunsDataTable borderColor={borderColor} textColor={textColor} />
      }
    </Card>
  );
}
