/**
 * Styles and templates for the drop down menu for teams and orgs
 */
import {
  MenuItem,
  TextProps,
  MenuButtonProps,
  FlexProps,
  MenuListProps,
  Select,
  SelectProps,
  useColorModeValue,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FC, PropsWithChildren, MouseEventHandler } from "react";
import { MenuList } from './types';
type ButtonHandler = MouseEventHandler<HTMLElement>;

export const SelectedColor = "gray.400";
export const BoxShadowEffect = "0 1px 2px rgba(0,0,0,0.5)"

/**
 * Menu button style
 */
export const MenuButtonStyle: MenuButtonProps = {
  p: "0px",
  mr: "0.75rem",
  textTransform: "capitalize",
  borderRadius: ".5rem",
  border: "1px",
  borderColor: "gray.200",
  padding: ".4rem 1rem",
  fontSize: 'sm',
  fontWeight: '500',
  _hover: { bg: 'gray.200' },
  _expanded: { bg: 'gray.300' }
}


/**
 * MenuButton Flex Child Style
 */
export const MenuButtonFlexStyle: FlexProps = {
  direction: "row",
  justifyContent: "center",
  gap: ".5rem",
  alignItems: "center",
  _hover: { backgroundColor: 'green' }
};

/**
 * Text style
 */
export const TextStyle: TextProps = {
  cursor: "pointer",
  margin: "0 auto",
  fontSize: 'md',
  fontWeight: '600'
}

/**
 * Menu List style
 */
export const MenuListStyle: MenuListProps = {
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  // border: `.5px solid ${SelectedColor}`,
  gap: "1px",
  borderRadius: ".5rem",
  position: "absolute",
  defaultChecked: false,
  p: "1rem .5rem 2rem .5rem",
  width: "fit-content"
}

/**
 * Menu item template
 * @param selected boolean
 * @returns JSX element
 */
export const MenuItemTemplate: FC<PropsWithChildren<{
  selected: boolean, onClick: ButtonHandler
}>> = ({ children, selected = false, onClick }) =>
    <MenuItem
      className='menu-item-element'
      children={children}
      alignItems="center"
      justifyContent="center"
      display="flex"
      backgroundColor={selected ? SelectedColor : "inherit"}
      minWidth="70%"
      borderRadius="5px"
      onClick={onClick}
      border={`1px solid ${SelectedColor}`}
      _hover={{ bg: 'gray.200' }}
      textTransform="capitalize"
      height="1.9rem"
      width="95%"
      fontSize='md'
      fontWeight='500'
    />

/**
 * Horizontal Line
 * @param width number or string
 * @returns JSX Element
 */
export const HorizontalLine: FC<{ width?: string | number }>
  = ({ width = "80%" }) => <div style={{
    width,
    height: "1px",
    backgroundColor: 'gray',
    margin: ".25rem 0 1rem 0"
  }} />

/**
 * Vertical line
 * @param height Number or String 
 * @returns JSX Element
 */
export const VerticalLine: FC<{ height?: string | number }>
  = ({ height = "1rem" }) => <div style={{
    width: "2px",
    height,
    backgroundColor: 'gray',
    margin: "0 .25rem"
  }} />

/**
 * Chevron down icon
 * @returns JSX Element
 */
export const ChevronDown: FC = () =>
  <ChevronDownIcon width={"1.5rem"} height={"1.5rem"} />

interface CustomSelectProps extends SelectProps{

}

export const CustomSelect: FC<CustomSelectProps> = ({children, ...rest}) => {
  const highlight = useColorModeValue('gray.200', 'gray.700')
  
  return(
    <Select
      width="100%"
      borderRadius="5px"
      size={"sm"}
      bg="gray.400"
      fontWeight={500}
      _hover={{ bg: highlight }}
      _focus={{ bg: highlight }}
      _expanded={{ bg: highlight }}
      _active={{ bg: highlight }}
      _selected={{ bg: highlight }}
      _checked={{ bg: highlight }}
      {...rest}
    >
      {children}
    </Select>
  )
}


/**
 * JSX element containing a list of all user associated orgs
 * @returns JSX Element
 */
export const OrgsList: FC<MenuList> = ({ onClick, data, id }) => <>
  <CustomSelect
    onChange={(e) => onClick(e.target.value)}
    defaultValue={id}
  >
  {
    data.map((_org, key) =>
      <option value={_org.id} key={key}>
        {`${_org.name} -- ${_org.id.substring(0, 4)}`}
      </option>
      )
  }
  </CustomSelect>
</>

/**
 * List of Teams available for the user within
 * the currently selected or
 * @returns JSX Element
 */
export const TeamsList: FC<MenuList> = ({ onClick, id, data }) => <>
  {
    data.map((_team, key) =>
      <MenuItemTemplate
        key={key}
        selected={_team.id === id}
        onClick={() => onClick(_team.id)}
      > {_team.name}
      </MenuItemTemplate>)
  }
</>
