import { FC } from "react";
import { InputContainer } from "./inputbox";
import { Input } from "@chakra-ui/react";
import { CustomInputProps } from "../types";


export const CustomInput: FC<CustomInputProps> = props => {
  const { label, isRequired, isInvalid, error ,...rest } = props;
  return (
    <InputContainer
      label={label}
      isRequired={isRequired}
      isInvalid={isInvalid}
      error={error}
    >
      <Input 
        variant="auth"
        fontSize="sm"
        type="text"
        // mb="24px"
        fontWeight="500"
        size="lg"
        {...rest}
      />
    </InputContainer>
  );
};