import { useAuth } from "context/AuthContext";
import { Fragment } from "react";
import AccessAlert from "./AccessAlert";
import BlurOverlay from "./BlurOverlay";

function InvalidRole() {
  const { permission } = useAuth();
  if (
    permission !== "viewer" &&
    permission !== "developer" &&
    permission !== "admin"
  ) {
    return (
      <Fragment>
        <AccessAlert />
        <BlurOverlay />
      </Fragment>
    );
  } else {
    return null;
  }
}

export default InvalidRole;
