import { FC, Fragment } from "react";
import { ModalBody, Text } from "@chakra-ui/react";
import { CustomModalFooter } from "../components";
import { AddCodeProps } from "../types";

export const AddCode: FC<AddCodeProps> = (props) => {
  const { onClose, createCode, packageName, language } = props;
  return (
    <Fragment>
      <ModalBody>
        <Text textAlign="center">
          You are about to create a new custom code block under <br />
          package name: <b>{packageName}</b> <br />
          language: <b>{language}</b> <br /> <br/>
          do you wish to continue?
        </Text>
        <CustomModalFooter
          onCancel={onClose}
          onAccept={() => {
            createCode();
            onClose();
          }}
          cancelLabel="Cancel"
          acceptLabel="Create"
        />
      </ModalBody>
    </Fragment>
  );
};
