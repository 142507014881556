import React, { useState, useEffect, Fragment } from "react";
import Design from "../cloudDesign/Design";
// Chakra imports
import { Box, Button, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import VideoPlayer from "common/VideoPlayer";

import Card from "components/card/Card";
import Dropdown from "common/Dropdown";
import { useAuth } from "context/AuthContext";
import { Link, useLocation } from "react-router-dom";
import BackdropOverlay from "common/BackdropOverlay";
import AccessAlert from "common/AccessAlert";
import InvalidRole from "common/InvalidRole";
import ServiceList from "./ServiceList";
import { AutoSave } from "components/navbar/savechanges";

const btnData = [
  { id: 1, name: "Service List" },
  { id: 2, name: "Diagram View" },
];

export default function CloudRelease() {
  const { getCloudReleaseTab, spaceId, planId, permission } = useAuth();
  const [btnId, setBtnId] = useState<number>(1);

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  // const textColorBrand = useColorModeValue("brand.500", "white");

  const { pathname } = useLocation();
  const splitArr = pathname.split("/");
  const splitVal = splitArr[splitArr.length - 1];

  useEffect(() => {
    if (pathname) {
      setBtnId(parseInt(splitVal));
      getCloudReleaseTab(parseInt(splitVal));
    }
    //  eslint-disable-next-line
  }, [pathname]);

  const handleBtnClick = (id: number) => {
    setBtnId(id);
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <InvalidRole />
      {permission === "viewer" && <AccessAlert readonlyAccess={true} />}
      <BackdropOverlay />
      <Flex flexDirection="column">
        <Card p="0px">
          <Flex
            align={{ sm: "flex-start", lg: "center" }}
            justify="space-between"
            w="100%"
            px="22px"
            py="18px"
          >
            <Text color={textColor} fontSize="xl" fontWeight="600">
              Cloud Release
            </Text>
            <Box>
              {btnData.map(({ id, name }) => (
                <Link
                  to={`/dashboard/cloud-release/${spaceId}/${planId}/${id}`}
                  key={id}
                >
                  <Button
                    key={id}
                    variant={btnId === id ? "action" : "ghost"}
                    onClick={() => handleBtnClick(id)}
                  >
                    {name}
                  </Button>
                </Link>
              ))}
            </Box>
            <Button colorScheme="blue">Save</Button>
          </Flex>
          {btnId === 1 && <ServiceList />}
          {btnId === 2 && (
            <Fragment>
              <Dropdown disableBtn={true} />
              <Flex direction="row" justifyContent="center" alignItems="center">
                <Design />
              </Flex>
            </Fragment>
          )}

          <Box>
            <VideoPlayer />
          </Box>
        </Card>
      </Flex>
    </Box>
  );
}
