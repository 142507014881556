import saveinfo from "./autosaveevents";
import utils, { Error, deployProgress, useDeployModal } from "./utils";
import { FollowDeployModal } from "./followDeploy";
import { FollowDeployModalProps } from "./types";
export * from "./autosaveevents";

/**
 * Autosave events module
 * @method display dispatches an action to display save pop up
 * @method on Subscribes to an event
 * @method emit Dispatches an event
 */
export module AutoSave {
  export const Events = saveinfo;
  export const display = utils;
  export const error = Error;
  export const followDeploy = deployProgress;
  export const DeployStatus = FollowDeployModal;
  export const useDeploy = useDeployModal;
  export type DeployStatusProps = FollowDeployModalProps;

  export enum keys {
    createSpace = ""
  }
}
