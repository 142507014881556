import {
  CustomInput as Input,
  CustomFlexBox as FlexBox,
  CustomFormLabel as FormLabel,
  UserExistsWarn,
  CustomSelect as Select,
  PermissionData,
  WarnText as Text,
  CustomButton as Button
} from "./stylesandtemplates";
import { ModalFooter, Flex } from "@chakra-ui/react";
import { FC, forwardRef } from "react";
import {
  OnChange,
  EmailCustom, FooterCustom, FooterCustomDeploy, FooterCustomDestroy,
  FlexButtonProps
} from './types';

/**
 * Email input field. Input type "email", required *
 * @returns JSX Element
 */
export const EmailInput: FC<EmailCustom> = ({ onChange, onBlur, invite }) => <>
  <FlexBox>
    <FormLabel label="Email" />
    <Text warn={UserExistsWarn} show={invite} />
    <Input
      placeholder="member@mail.com"
      name="email"
      onChange={onChange}
      onBlur={onBlur}
    // value={member.email}
    />
  </FlexBox>
</>

/**
 * Name input field. Input type "text", required *
 * @returns JSX Element
 */
export const NameInput: FC<OnChange> =
  forwardRef((props, ref) => {
    const nameProps = { ...props, ref }
    return (
      <FlexBox>
        <FormLabel label="Name" />
        <Input
          placeholder="name"
          name="name"
          type="text"
          {...nameProps}
        // value={member.name}
        />
      </FlexBox>
    )
  })

/**
 * Permissions input field. Input type "SELECT", required *
 * @returns JSX Element
 */
export const PermissionInput: FC<OnChange> = ({ onChange }) =>
  <>
    <FlexBox>
      <FormLabel label="Permission" />
      <Select
        data={PermissionData}
        onChange={onChange}
        name="permission"
        placeholder="Select Permission"
      // value={member.permission}
      />
    </FlexBox>
  </>

/**
 * Modal Footer, Contains action Buttons
 * @returns JSX Element
 */
export const Footer: FC<FooterCustom> = ({ disabled, onClose, onInvite }) =>
  <ModalFooter>
    <Button
      label="Close"
      bg="gray.200" mr={3}
      onClick={onClose}
    />
    <Button
      colorScheme="blue"
      onClick={onInvite}
      disabled={disabled}
      label="Share"
    />
  </ModalFooter>

/**
 * Modal Footer, Contains action Buttons
 * @returns JSX Element
 */
export const DeployFooter: FC<FooterCustomDeploy> = ({ disabled, onClose, onDeploy }) =>
  <ModalFooter p="1rem 0">
    <Button
      label="Cancel"
      mr={3}
      onClick={onClose}
      colorScheme="red"
    />
    <Button
      colorScheme="blue"
      onClick={onDeploy}
      disabled={disabled}
      label="Deploy"
      mr={0}
    />
  </ModalFooter>

export const DestroyFooter: FC<FooterCustomDestroy> = ({ disabled, onClose, onDestroy }) =>
  <ModalFooter p="1rem 0">
    <Button
      label="Cancel"
      mr={3}
      onClick={onClose}
      colorScheme="red"
    />
    <Button
      colorScheme="blue"
      onClick={onDestroy}
      disabled={disabled}
      label="Yes, Destroy"
      mr={0}
    />
  </ModalFooter>



/**
 * Button Component in a FlexBox
 * @returns JSX Element
 * @param props ButtonProps
 */
export const FlexButton: FC<FlexButtonProps> = props =>
  <Flex alignItems="center" justifyContent="center" w="100%" mb="2rem">
    <Button colorScheme="blue" size="sm"
      {...props}
    />
  </Flex>