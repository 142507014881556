/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth";
// Assets
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { toast } from "react-toastify";
import * as EmailValidator from "email-validator";
import { errorMessage, ErrorCotainer } from "../../../utils/authError";
import { auth } from "firebase-local/config";
import { useUser } from "../../../context/UserContext";
import { useAuth } from "../../../context/AuthContext";
import { Redirect } from "react-router-dom";
import {
  sendEmailVerification,
  onAuthStateChanged,
  reload,
} from "firebase/auth";
import Loader from "../../../common/Loader";

import { PASSWORD_CONFIG } from "variables/config";
import { useValidator } from "./utils/utils";
import { StrengthMeter } from "./components";

// const countryData = [
//   { id: 1, name: "Country", value: "" },
//   { id: 2, name: "Australia", value: "Australia" },
//   { id: 3, name: "United States", value: "United States" },
//   { id: 4, name: "United Kingdom", value: "United Kingdom" },
//   { id: 5, name: "Sweden", value: "Sweden" },
//   { id: 6, name: "Spain", value: "Spain" },
//   { id: 7, name: "Italy", value: "Italy" },
//   { id: 8, name: " United Arab Emirates", value: "United Arab Emirates" },
//   { id: 9, name: "Japan", value: "Japan" },
//   { id: 10, name: " Netherlands", value: "Netherlands" },
// ];

function SignUp() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const [signupFields, setSignupFields] = useState({
    email: "",
    password: "",
    matchPassword: "",
    name: "",
    // country: "",
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [emailConfirmed, setEmailConfirmed] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const { register, currentUser, logout } = useAuth();
  const { emailVerified } = useUser();

  useEffect(() => {
    const { password, email, matchPassword } = signupFields;
    if (!PASSWORD_CONFIG.regex.test(password)) {
      setError(true);
    } else {
      setError(false);
    }

    const keyDownHandler = (event: any) => {
      if (event.key === "Enter") {
        if (password !== "" && email !== "" && matchPassword !== "")
          handleSignupUser(event);
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [currentUser, signupFields, emailVerified]);

  const onChangeHandler = (e: any) => {
    const value = e.target.value;
    setSignupFields({
      ...signupFields,
      [e.target.name]: value,
    });
    if(e.target.name === "password") {
      setShowMeter(true);
    } if (e.target.name === "matchPassword") {
      setCheckMatch(true);
    }
  };

  // const handleMatchPassword = () => {
  //   const { password, matchPassword } = signupFields;
  //   if (password !== "" && password !== matchPassword) {
  //     toast.error("Passwords do not match");
  //   }
  // };

  // const handlePasswordSize = () => {
  //   const { password } = signupFields;
  //   if (!PASSWORD_CONFIG.regex.test(password)) {
  //     toast.error(PASSWORD_CONFIG.message);
  //   }
  // };

  const handleValidateEmail = () => {
    const { email } = signupFields;
    if (email !== "" && !EmailValidator.validate(email)) {
      toast.error("Please enter a valid email address");
    }
  };
  const handleSignupUser = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const { email, password, name } = signupFields;

    try {
      await register(email, password, name)
        .then((res: any) => {
          sendEmailVerification(res.user).then(() => {
            toast.info("Account created successfully");
            if (currentUser) {
              logout();
            }

            setTimeout(() => {
              setEmailSent(true);
            }, 5000);

            // Check for email confirmation

            const unsubscribeOnUserChanged = onAuthStateChanged(
              auth,
              (user: any) => {
                const unsubscribeSetInterval = setInterval(() => {
                  reload(user).then(() => {
                    if (user.emailVerified) {
                      logout();
                      clearInterval(unsubscribeSetInterval);
                      setLoading(false);
                      setEmailConfirmed(true);
                      return unsubscribeOnUserChanged();
                    }
                  });
                }, 3000);
              }
            );
          });
        });
    } catch (error: any) {
      setLoading(false);
      const { message } = error;
      errorMessage(message);
    }
  };

  const [show, setShow] = React.useState(false);
  const [showMeter, setShowMeter] = React.useState(false);
  const [checkMatch, setCheckMatch] = React.useState(false);
  const handleClick = () => setShow(!show);
  const { password, matchPassword } = signupFields;

  if (currentUser && emailVerified) {
    document.location.href = "/#/dashboard";
    // return <Redirect to="/dashboard" />;
  }
  if (emailConfirmed || emailSent) {
    const text = "We have sent a link to your email address, please verify your email address to continue!"
    document.location.href = `/#/login?action=from_signup&text=${encodeURIComponent(text)}`;
    // return <Redirect to="/login" />;
  }

  // Use Validator
  const { valid, message, strength } = useValidator(password);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "5vh" }}
        flexDirection="column"
      >
        {ErrorCotainer}
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign Up
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter the following details to sign up!
            <Loader loading={loading} />
          </Text>
        </Box>
        <Flex
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          {/* <Button
            fontSize="sm"
            me="0px"
            mb="26px"
            py="15px"
            h="50px"
            borderRadius="16px"
            bg={googleBg}
            color={googleText}
            fontWeight="500"
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
          >
            <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
            Sign in with Google
          </Button> */}
          {/* <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex> */}
          <FormControl isRequired>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
              requiredIndicator={<Text color={brandStars}>*</Text>}
            >
              Email
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              placeholder="hi@fabr.ltd"
              mb="24px"
              fontWeight="500"
              size="lg"
              name="email"
              onChange={onChangeHandler}
              onBlur={handleValidateEmail}
            />
          </FormControl>
          <FormControl isRequired isInvalid={showMeter && !valid} mb="24px">
            <Box display="flex" justifyContent="space-between">
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
                requiredIndicator={<Text color={brandStars}>*</Text>}
              >
                Password
              </FormLabel>
              {showMeter && (
                <StrengthMeter strength={strength} />
              )}
            </Box>

            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                name="password"
                onChange={onChangeHandler}
              // onBlur={handlePasswordSize}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage p="0 1rem">{message}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={checkMatch && matchPassword !== password} mb="24px">
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
              requiredIndicator={<Text color={brandStars}>*</Text>}
            >
              Match Password
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Match password"
                // mb="24px"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                name="matchPassword"
                onChange={onChangeHandler}
                // onBlur={handleMatchPassword}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage p="0 1rem">{matchPassword !== password ? "Passwords do not match" : ""}</FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Name
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              placeholder="Enter your name"
              mb="24px"
              fontWeight="500"
              name="name"
              onChange={onChangeHandler}
            />
            {/* <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Country
            </FormLabel>
            <Select
              placeholder="Select option"
              size={"lg"}
              mb="8"
              sx={{ borderRadius: "16px" }}
              name="country"
              onChange={onChangeHandler}
            >
              {countryData.map(({ id, name, value }) => (
                <option key={id} value={value} hidden={value === ""}>
                  {name}
                </option>
              ))}
            </Select> */}
            {/* <Flex justifyContent="space-between" align="center" mb="24px">
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  id="remember-login"
                  colorScheme="brandScheme"
                  me="10px"
                />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="sm"
                >
                  Keep me logged in
                </FormLabel>
              </FormControl>
              <NavLink to="/auth/forgot-password">
                <Text
                  color={textColorBrand}
                  fontSize="sm"
                  w="124px"
                  fontWeight="500"
                >
                  Forgot password?
                </Text>
              </NavLink>
            </Flex> */}
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              disabled={loading || error || matchPassword !== password || !valid}
              onClick={handleSignupUser}
              _hover={{ background: "brand" }}
              type="submit"
            >
              Sign Up
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Already have an account?
              <NavLink to="/login">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Sign In
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignUp;
