/* eslint-disable */

import { NavLink, useLocation } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Icon, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { useAuth } from "context/AuthContext";
import { useUser } from "context/UserContext";
import { Badge } from "@chakra-ui/react";
import { MdUpcoming } from "react-icons/md";

export function SidebarLinks(props: { routes: RoutesType[] }) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");

  const { routes } = props;

  const {
    spaceId,
    planId,
    billingTeamTab,
    cloudSettingsTab,
    cloudReleaseTab,
    cloudDesignTab,
  } = useAuth();

  const { updateDiagramData } = useUser();

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  // route.layout + route.path
  const createUrlPath = (route: any) => {
    if (route.path === "/cloud-observe") {
      const urlPath = `${route.layout}${route.path}/${spaceId}/${planId}`;
      return urlPath;
    } else if (route.path === "/cloud-design") {
      const urlPath = `${route.layout}${route.path}/${spaceId}/${planId}/${cloudDesignTab}`;
      return urlPath;
    } else if (route.path === "/cloud-release") {
      const urlPath = `${route.layout}${route.path}/${spaceId}/${planId}/${cloudReleaseTab}`;
      return urlPath;
    } else if (route.path === "/profile-team") {
      const urlPath = `${route.layout}${route.path}/${billingTeamTab}`;
      return urlPath;
    } else if (route.path === "/cloud-settings") {
      const urlPath = `${route.layout}${route.path}/${cloudSettingsTab}`;
      return urlPath;
    }
    // else if (
    //   route.path === "/profile-team" ||
    //   route.path === "/cloud-settings"
    // ) {
    //   const urlPath = `${route.layout}${route.path}/${tabId}`;
    //   const splitArr = urlPath.split("/");
    //   const splitVal = splitArr[splitArr.length - 1];
    //   console.log(typeof splitVal);
    //   return urlPath;
    // }
    else {
      return route.layout + route.path;
    }
  };

  const closeDrawer = () => {
    updateDiagramData(true);
    const drawerId = document.getElementById("drawer-close-icon");
    if (drawerId) {
      drawerId.click();
    }
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes: RoutesType[]) => {
    return routes.map((route: RoutesType, index: number) => {
      if (
        route.layout === "/dashboard" ||
        route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        return (
          route.name !== "Profile" &&
          route.name !== "Exit" && (
            <NavLink
              key={index}
              to={createUrlPath(route)}
              onClick={closeDrawer}
            >
              {route.icon ? (
                <Box>
                  <HStack
                    spacing={
                      activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                    }
                    py="5px"
                    ps="10px"
                  >
                    <Flex w="100%" alignItems="center" justifyContent="center">
                      <Box
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeIcon
                            : textColor
                        }
                        me="18px"
                      >
                        {route.icon}
                      </Box>
                      <Box
                        me="auto"
                        color={
                          activeRoute(route.path.toLowerCase())
                            ? activeColor
                            : textColor
                        }
                        fontWeight={
                          activeRoute(route.path.toLowerCase())
                            ? "bold"
                            : "normal"
                        }
                      >
                        <Flex
                          direction="row"
                          justify="flex-start"
                          alignItems="center"
                          gap=".3rem"
                        >
                          {route.name}
                          {(route.path == '/cloud-release' || route.path == '/cloud-observe' || route.path == '/marketplace') && (
                            <Tooltip label="Coming soon"><span><Icon as={MdUpcoming} width="20px" height="20px" color="inherit" /></span></Tooltip>
                          )}
                        </Flex>
                      </Box>
                    </Flex>
                    <Box
                      h="36px"
                      w="4px"
                      bg={
                        activeRoute(route.path.toLowerCase())
                          ? brandColor
                          : "transparent"
                      }
                      borderRadius="5px"
                    />
                  </HStack>
                </Box>
              ) : (
                <Box>
                  <HStack
                    spacing={
                      activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                    }
                    py="5px"
                    ps="10px"
                  >
                    <Text
                      me="auto"
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : inactiveColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }
                    >
                      {route.name}
                    </Text>
                    <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                  </HStack>
                </Box>
              )}
            </NavLink>
          )
        );
      }
    });
  };
  //  BRAND
  return <>{createLinks(routes)}</>;
}

export default SidebarLinks;
