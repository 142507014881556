import {
  FlexProps, BoxProps, Text,
  FormLabelProps, InputProps,
  Input, Flex, Box, FormLabel,
  SelectProps, Select, ModalProps,
  Modal, ModalOverlay, ModalContent,
  ModalHeader, ModalCloseButton, ModalBody,
  TextProps, ButtonProps, Button
} from "@chakra-ui/react";
import { FC, ReactNode, forwardRef } from "react";
import { SelectData, Header, Warn, Label } from './types';

const textColor = ''

export const FlexStyle: FlexProps = {
  direction: "row",
  justifyContent: "center",
  alignItems: "center",
  background: "transparent",
  borderRadius: "15px"
}

export const RootBoxStyle: BoxProps = {
  w: { base: "100%", md: "420px" },
  maxW: "100%",
  ml: "16px",
  mr: "16px"
}
export const FormLabelStyle: FormLabelProps = {
  display: "flex",
  ms: "4px",
  fontSize: "sm",
  fontWeight: "500",
  color: textColor,
  mb: "8px",
}

export const LaunchTextStyle: TextProps = {
  fontSize: "md",
  fontWeight: "500",
  color: textColor,
  m: "1rem 0 2rem 0",
  textAlign: "center",
}

export const InputStyle: InputProps = {
  isRequired: true,
  variant: "auth",
  fontSize: "sm",
  mb: "24px",
  fontWeight: "500",
  size: "lg",
}

export const SelectStyle: SelectProps = {
  size: "md",
  mb: "8",
  fontSize: "sm",
  sx: { borderRadius: ".5rem" },
  textTransform: "capitalize",
  border: "1px solid green",
}

const WarningTextStyle: TextProps = {
  color: "red",
  fontSize: "14px",
  ml: "8px",
  mb: "8px"
}

export const EmptyMembersData = {
  name: '',
  email: '',
  permission: '',
  reference: '',
}
interface Ref {
  ref?: React.ForwardedRef<HTMLInputElement>
}
export const CustomInput: FC<InputProps & Ref> =
  forwardRef((props, ref) => {
    const inputProps = { ...props, ref }
    return (
      <Input {...inputProps} />
    )
  })

export const CustomFlexBox: FC<FlexProps> = (props) => {
  const { children, ...rest } = props
  const boxprops = { children, ...RootBoxStyle }
  const flexprops = { ...FlexStyle, ...rest }
  return (
    <Flex {...flexprops}>
      <Box {...boxprops} />
    </Flex>
  );
}

export const CustomFormLabel: FC<FormLabelProps & Label> = props => {
  const { label, ...rest } = props
  const labelProps = { ...FormLabelStyle, ...rest };
  return (
    <FormLabel {...labelProps}>
      {label}
    </FormLabel>
  )
}

export const CustomSelect: FC<SelectProps & SelectData> =
  forwardRef((props, ref) => {
    const { data, ...rest } = props;
    const selectProps = { ...SelectStyle, ...rest, ref }
    return (
      <Select {...selectProps} >
        {data.map(({ id, name }) =>
          <option key={id} value={id}>{name}</option>)}
      </Select>
    )
  })

export const CustomModal: FC<ModalProps & Header> = props => {
  const { children, header, ...rest } = { autoSelect: false, ...props };
  const bodyChildren = children as ReactNode;
  return (
    <Modal {...rest} >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody children={bodyChildren} />
      </ModalContent>
    </Modal>
  )
}

export const WarnText: FC<TextProps & Warn> = props => {
  const { warn, show, ...rest } = { ...WarningTextStyle, ...props };
  const textProps: TextProps = {
    ...rest,
    display: show ? 'inherit' : 'none'
  }
  return (
    <Text {...textProps}>{warn}</Text>
  )
}

export const CustomButton: FC<ButtonProps & Label> = props => {
  const { label, ...buttonProps } = props;
  return (
    <Button {...buttonProps}>
      {label}
    </Button>
  )
}

export enum deployMessages {
  DeployWarn = "Are you sure as this will update your cloud infrastructure according to what's in this plan?",
  header = "Deploying to cloud infrastructure",
  NoPackage = "This plan has no package attached to it please select a package to continue.",
  NoEnv = "This plan targets {vendor}. There are no environments setup up for {vendor}. Please create one to continue.",
}

export enum destroyMessages {
  DestroyWarn = "Are you sure? If you continue all your infrastrcutrue resources associated with the selected PLAN + ENVIRONMENT will be destroyed.",
  header = "Destroy cloud infrastructure",
  noinfra = "There's no infrastructure deployed to this environment from for this plan."
}

export const UserExistsWarn = "User with this email already exists, please add different one";

export const PermissionData = [
  { id: 1, name: "viewer", value: "viewer" },
  { id: 3, name: "developer", value: "developer" },
  { id: 2, name: "admin", value: "admin" },
];
