export const tableColumnsTopLaunches = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Launches",
    accessor: "launches",
  },
  {
    Header: "Rating",
    accessor: "rating",
  },
];
