import {
  Popover,
  PopoverTrigger,
  Text, useDisclosure,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react";
import {
  FC, ReactNode,
  useEffect,
  useState,
  Fragment
} from 'react';
import {
  FollowDeployProps,
  FollowDeployModalProps,
} from "./types";
import saveinfo from "./autosaveevents";
import { Terminal } from "../terminal/terminal";
import { ResizableBox } from "../terminal/resizablebox";

// Reset popover style
const resetStyle = {
  maxWidth: 'unset',
  height: 'unset',
  width: 'unset',
  maxHeight: 'unset',
}

/**
 * Follow  Deploy Modal and popover passed to the AutoSave Component on
 * initiating a deploy job
 * @param {FollowDeployProps} props props 
 * @returns JSX Element
 */
export const FollowDeploy: FC<FollowDeployProps> = props => {
  const { onClose, onOpen, isOpen } = useDisclosure();
  const { onDocSubscription, version, ...rest } = props;
  const [stderr, setStderr] = useState('stderr')
  const [stdout, setStdout] = useState('stdout')

  /**
   * Reads stdout and stderr of the currently launched plan
   */
  const dataHandler = () => {
    if (onDocSubscription) {
      try {
        onDocSubscription(snap => {
          const data = snap.data();
          setStderr(data.stderr);
          setStdout(data.stdout);
        })
      } catch (err) {
        console.error("stdout/err dataHandler()", err as Error)
      }
    }
  }

  /**
   * Disables the timeout delay on the autosave event and opens the modal
   */
  const localOpen = () => {
    saveinfo.emit('disable-timeout-delay', [true]);
    onOpen();
  }

  /**
   * Enables the timeout delay on the autosave event and closes the modal
   */
  const localClose = () => {
    saveinfo.emit('disable-timeout-delay', [false])
    onClose();
  }

  useEffect(dataHandler, [stderr, stdout, onDocSubscription])

  return (
    <Fragment>
      <Popover styleConfig={resetStyle} onClose={localClose} isOpen={isOpen}
        onOpen={localOpen} autoFocus={false} closeOnBlur={false}
      >
        <Trigger>
          <Text fontSize='lg' noOfLines={1}>
            Plan launched and saved as <strong>version {version}</strong> |
            <span className="follow-launch"> Follow Launch</span>
          </Text>
        </Trigger>
      </Popover>
      <FollowDeployModal onClose={localClose} isOpen={isOpen} stderr={stderr} stdout={stdout} {...rest} />
    </Fragment>
  )
}

/**
 * Follow Deploy Modal - Displays the stdout and stderr of the selected run
 * @param {FollowDeployModalProps} props props 
 * @returns JSX Element
 */
export const FollowDeployModal: FC<FollowDeployModalProps> = props => {
  const { plan, onClose, isOpen, stderr, stdout } = props;

  const bgColor = useColorModeValue("#a4b0ef", "gray.900")

  return (
    <Modal onClose={onClose} isOpen={isOpen} autoFocus={false}
      scrollBehavior="outside" size="full"
    >
      <ModalOverlay />
      <ModalContent bg={'transparent'} display="flex" alignItems="center"
        justifyContent="center"
      >
        <ResizableBox bg={bgColor} onClose={onClose}>
          <Text fontSize="1.5rem" fontWeight="600" position="absolute" top=".5rem">{plan}</Text>
          <Terminal ps1="[ Standard Out ]" data={stdout} flex="1" name="stdout" />
          <Terminal ps1="[ Standard Err ]" data={stderr} flex="1" name="stderr" />
        </ResizableBox>
      </ModalContent>
    </Modal>
  )
}

/**
 * Trigger for the popover
 */
const Trigger: FC<{ children: ReactNode }> = PopoverTrigger;
