import { col, runJob, DataRef, DocRef, Observer, TimeStamp, Status, Command } from "./types";
import {
  doc, setDoc, query, orderBy, getDocs,
  collection, getDoc, onSnapshot, FieldValue, serverTimestamp, limit, where
} from "firebase/firestore";
import { uploadBytes, ref } from "firebase/storage";
import { storage, db } from "firebase-local/config";

/**
 * Create an entry in runs sub-collection under spaces sub-collection
 * which is in turn under the current team in Firestore
 */
class NewRunJob implements runJob {
  /**
   * Construct a runs entry Object and save it to firestore database
   * @param memberId UID (document id of the users' collection) for
   * the person who launches the plan
   * @param planId document id of the plan in plans sub-collection
   * @param envId document id of the environment in the environments sub-collection
   * @param runRef reference to space of launch
   */
  constructor(
    private readonly memberId: string,
    private readonly planId: string,
    private readonly envId: string,
    private readonly runRef: DataRef,
    private readonly runCommand: Command,
  ) {
    this.startTime = null //serverTimestamp();
    this.member = this.memberId;
    this.plan = this.planId;
    this.environment = this.envId;
    this.command = this.runCommand;
    this.createdAt = serverTimestamp();
  }

  /**
   * Save the created Object (this) to firestore database
   * @param data Created object (this)
   */
  readonly saveData = async (data = this) => {
    await this.updateVersion();
    const id = doc(this.runRef).id;
    const runRefData = doc(this.runRef, id)
    const { finishTime, environment, startTime,
      member, plan, stderr, stdout, version, runDocSubscription,
      command, status, createdAt
    } = data
    await this.saveSnapshot();
    await setDoc(runRefData,
      {
        finishTime, environment, startTime,
        stderr, stdout, member, plan,
        version, status, command, createdAt
      }
    );

    return {
      onDocSubscription: runDocSubscription(runRefData),
      plan: this["plan-name"], version,
    };
  }

  /**
   * Save a snapshot of plan to firebase storage as a json blob
   */
  private readonly saveSnapshot = async () => {
    const path = this.runRef.path.replace(col.runs, col.plans);
    const envPath = this.runRef.path.replace(col.runs, col.environments);
    const planRef = collection(db, path);
    const envRef = collection(db, envPath);
    const fileRef = ref(storage, `${path}/${this.planId}/snapshot.json`);
    const envFileRef = ref(storage,
      `${path}/${this.planId}/${col.environments}/${this.environment}/default.json`)
    const versionRef = ref(storage,
      fileRef.fullPath.replace('snapshot', this.version));

    // Save plan snapshot to storage as snapshot.json and x.json
    const data = await this.getPlanJSONBlob(planRef);
    if (data)
      [fileRef, versionRef].forEach(async opt =>
        await uploadBytes(opt, data, this["json-metadata"]));

    // Save env snapshot to storage
    const env = await this.getEnvJSONBlob(envRef);
    if (env)
      await uploadBytes(envFileRef, env, this["json-metadata"]);
  }

  /**
   * Get plan from firestore and convert to JSON format
   * @param planRef Collection reference to plans
   * @returns Promise json Blob
   */
  private readonly getPlanJSONBlob = async (planRef: DataRef) =>
    await getDoc(doc(planRef, this.planId))
      .then(snap => {
         this["plan-name"] = snap.data().name
        return JSON.stringify(snap.data(), null, 2)
      })
      .then(json => new Blob([json], this["blob-mime"]))

  /**
   * Get env from firestore and convert to json blon
   * @envRef Collection reference to environments
   * @returns Promise json blob
   */
  private readonly getEnvJSONBlob = async (envRef: DataRef) =>
    await getDoc(doc(envRef, this.environment))
      .then(snap => snap.data() as any)
      .then(data => JSON.stringify(data, null, 2))
      .then(json => new Blob([json], this['blob-mime']))
      .catch((err: Error) => console.error('NewRunJob {} -- ', err))

  /**
    * Check if the current plan being launched has a version in runs and
    * increment the version accordingly.
    */
  private readonly updateVersion = async () => {
    const versionQuery = query(this.runRef,
      where('plan', '==', this.plan),
      orderBy('createdAt', 'desc'),
      limit(1)
    );
    await getDocs(versionQuery)
      .then(snap => snap.docs.map(doc => doc.data()) as runJob[])
      .then(plans => {
        if (plans.length) {
          const [{ version }] = plans;
          this.version = `${+version + 1}`
        }
      });
  }

  /**
   * A listener to the run standard out and standard error
   * @param docRef Document reference to runs
   * @returns snapshot listener
   */
  private readonly runDocSubscription = (docRef: DocRef) => {
    /**
     * Subscription to the run doc
     * @param cb next callback function
     */
    const subscription = (cb: Observer) => onSnapshot(docRef, cb)

    return subscription
  };

  /**
   * json file meta-data
   */
  private readonly 'json-metadata' = {
    contentType: 'application/json'
  };

  /**
   * json blob mime
   */
  private readonly 'blob-mime' = {
    type: 'application/json'
  }

  private 'plan-name' = ''

  finishTime: TimeStamp = null;
  stderr = '';
  stdout = '';
  version = '1';
  member;
  plan;
  environment;
  startTime: TimeStamp = null;
  command: Command;
  status: Status = "queued";
  createdAt: FieldValue;
}

export default NewRunJob;
