import React, { FC, Fragment } from 'react';
import { useCodeEditor } from './usecodeeditor';
import Editor from '@monaco-editor/react';
import { Box } from '@chakra-ui/react';
import { EditMeta } from '../toolbar';
import { Prompt } from 'react-router-dom';

export const CodeEditor: FC<{}> = props => {

  const { configs, index, onChangeHandler, deleteHandler, blockNavigation } = useCodeEditor();

  return (
    <Fragment>
      <Prompt
        when={blockNavigation}
        message="Some changes made may have not been saved, are you sure you want to continue?"
      />
    <Box w="100%" h="100%" position="relative">
      {index > 1 &&
        <EditMeta 
          deleteHandler={() => {
            deleteHandler(index);
          }}
          index={index}
        />
      }

      <Editor
        width="100%"
        height="100%"
        options={{
          automaticLayout: true,
          
        }}
        onChange={(value, _event) => {
          onChangeHandler(value, index);
        }}
        {...configs}
      />
    </Box>
    </Fragment>
  );
};
