/**
 * Access Db operations and use firebase functions
 * @client instance of class ClientAccess
 * @checkTeamMember check if a user's email is already 
 * listed as a member within the team
 * @sendInvite send an invite for a team member
 * @ClientAccess
 */
import Client from "./clientaccess";
import __runJob from "./newrunjob";
import { packages } from "./packagehandler";
import { col as _col, PackageList as pkgList } from "./types";
import * as __DateTime from "./datetime"; 

export default Client;
export const RunJob = __runJob;
export const col = _col;
export const pkgs = packages;
export type PackageList = pkgList
export const DateTime = __DateTime;
