import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Box,
  Button,
  FormLabel,
  Input,
  Select,
  FormControl,
  ButtonGroup,
  FormErrorMessage,
  // Checkbox,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { useAuth } from "context/AuthContext";
import { useState, useEffect, Fragment } from "react";
import {
  // collection,
  deleteDoc,
  doc,
  updateDoc,
  // setDoc,
  // writeBatch,
} from "firebase/firestore";
import { db } from "firebase-local/config";
// import { toast } from "react-toastify";
import { MsgContainerTopCenter } from "utils/authError";
import Loader from "../../../common/Loader";
import { memberType, memberRole } from "../../../types/userTypes";
import { Link } from "react-router-dom";
import { findIndex } from "lodash";
import { dispatchPopup } from "components/popups";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Prompt } from "../cloudSettings/environment/prompt";
import { col } from "functions";
import { AutoSave } from "components/navbar/savechanges";

import "./teams.css";

const headData = [
  { id: 1, title: "Name" },
  { id: 2, title: "Email" },
  { id: 3, title: "Role" },
  { id: 4, title: "Actions" },
];

export default function Teams({
  btnId,
  onClickSave,
  updateBtnId,
}: {
  btnId: number;
  onClickSave: boolean;
  updateBtnId?: (id: number) => void;
}) {
  const {
    members,
    userEmail,
    docId,
    getMembersData,
    teamName,
    billingData,
    getCurrentUserTeams,
  } = useAuth();

  // const [memberIds, setMemberIds] = useState<string[]>([]);
  const [changeTeamName, setChangeTeamName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [membersRole, setMembersRole] = useState<memberRole[]>([]);
  const [disableEdit, setDisableEdit] = useState<boolean>(true);

  // useEffect(() => {
  //   if (btnId === 2 && onClickSave) {
  //     onSaveMembers();
  //     setMemberIds([]);
  //   }
  //   // eslint-disable-next-line
  // }, [onClickSave, btnId]);

  useEffect(() => {
    setChangeTeamName(teamName);
  }, [teamName]);

  // Invite member
  const inviteMember = dispatchPopup("InviteMember", {});

  // const membersRef = collection(db, "orgs", docId, "teams", docId, "members");


  // const onSaveMembers = () => {
  //   setLoading(true);
  //   if (changeTeamName !== teamName) {
  //     const teamRef = doc(db, "orgs", docId, "teams", docId);
  //     updateDoc(teamRef, {
  //       name: changeTeamName,
  //     }).then(() => {
  //       toast.success("Team Name Changed successfully!");
  //       getMembersData();
  //       setLoading(false);
  //       getCurrentUserTeams();
  //     });
  //   }

  //   if (membersRole.length > 0) {
  //     const batch = writeBatch(db);
  //     membersRole.forEach((memberRole: memberRole) => {
  //       batch.update(doc(membersRef, memberRole.id), {
  //         permission: memberRole.permission,
  //       });
  //     });
  //     batch.commit().then(() => {
  //       toast.success("Members permission updated successfully!");
  //       getMembersData();
  //       setLoading(false);
  //     });
  //   }

  //   if (memberIds.length > 0) {
  //     const batch = writeBatch(db);
  //     memberIds.forEach((docId) => {
  //       batch.delete(doc(membersRef, docId));
  //     });

  //     batch.commit().then(() => {
  //       toast.success("Members removed successfully!");
  //       getMembersData();
  //       setLoading(false);
  //     });
  //   }
  // };

  /**
   * Handle removing a member from the invite list
   * @param id member id
   */
  const onDeleteMember = async (id: string) => {
    try {
      const docRef = doc(
        db, col.orgs, docId, col.teams, docId, col.members, id
      );
      await deleteDoc(docRef);
      AutoSave.display("Member removed successfully!");
      getMembersData();
    } catch (error) {
      AutoSave.error("Error removing member!");
      console.error('Error deleting member', error);
    }
  };

  /**
   * Handle editting and updatting the team name
   * @returns 
   */
  const onEditTeamName = async () => {
    try {
      if(changeTeamName === teamName) {
        setDisableEdit(true);
        AutoSave.display("No changes to save");
        return;
      }
      setLoading(true);
      const docRef = doc(
        db, col.orgs, docId, col.teams, docId
      );
      await updateDoc(docRef, {
        name: changeTeamName,
      }).then(() => {
        AutoSave.display("Team name updated successfully!");
        getMembersData();
        setLoading(false);
        getCurrentUserTeams();
        setDisableEdit(true);
      });
    } catch (error) {
      setLoading(false);
      AutoSave.error("Error updating team name!");
      console.error('Error updating team name', error);
    }
  };

  /**
   * Handle canceling the edit of the team name by resetting the input value
   */
  const cancelEdit = () => {
    setChangeTeamName(teamName);
    setDisableEdit(true);
  }

  // const onSelectMembers = (e: any, id: string) => {
  //   if (e.target.checked) {
  //     setMemberIds([...memberIds, id]);
  //   } else {
  //     const filterMemberIds = memberIds.filter(
  //       (memberId: string) => memberId !== id
  //     );
  //     setMemberIds(filterMemberIds);
  //   }
  // };

  const onChangeRole = (e: any, member: memberType) => {
    const role = { id: member.id, permission: e.target.value };
    if (membersRole.length > 0) {
      membersRole.forEach((memberRole: memberRole) => {
        if (memberRole.id === member.id) {
          const modifyRole = [...membersRole];
          let index = findIndex(modifyRole, { id: member.id });
          modifyRole.splice(index, 1, {
            id: member.id,
            permission: e.target.value,
          });
          setMembersRole(modifyRole);
        } else {
          setMembersRole([...membersRole, role]);
        }
      });
    } else {
      setMembersRole([...membersRole, role]);
    }
  };

  const getRoleValue = (member: memberType) => {
    let roleValue: string;
    if (membersRole.length > 0) {
      membersRole.forEach((memberRole: memberRole) => {
        if (member.id === memberRole.id) {
          roleValue = memberRole.permission;
        }
      });
    } else {
      roleValue = member.permission;
    }
    return roleValue;
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt="-32px"
        mb="32px"
      >
        <Loader loading={loading} />
      </Flex>

      {MsgContainerTopCenter}
      {billingData && billingData.plan === "Solo" ? (
        <Flex direction="row" justifyContent="center" alignItems="center">
          <Flex direction="column" justifyContent="center" alignItems="center">
            <Text>
              Please upgrade plan to Team or Enterprise to collaborate with team
              members.
            </Text>

            <Link to="/dashboard/profile-team/1">
              <Button
                colorScheme="blue"
                mt="16px"
                mb="16px"
                onClick={() => updateBtnId(1)}
              >
                Upgrade
              </Button>
            </Link>
          </Flex>
        </Flex>
      ) : (
        <Fragment>
          <Flex
            px="25px"
            justify="space-between"
            mb="20px"
            align="center"
            flexWrap="wrap"
          >
            <Flex
              direction="row"
              justifyContent="center"
              alignItems="center"
              background="transparent"
              borderRadius="15px"
            >
              <Box w={{ base: "100%", md: "250px" }} maxW="100%">
                <FormControl isInvalid={!changeTeamName || !changeTeamName.length} >
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Team Name
                  </FormLabel>
                  <Flex flexDir="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    gap="1rem"
                  >
                    <Input
                      color={textColor}
                      placeholder="team name"
                      fontSize="sm"
                      type="text"
                      fontWeight="500"
                      size="lg"
                      borderRadius="16px"
                      minWidth="250px"
                      disabled={disableEdit}
                      value={changeTeamName}
                      onChange={(e: any) => setChangeTeamName(e.target.value)}
                    />
                    {disableEdit ?
                    <Box className="teams-edit-icon">
                      <Text className="teams-edit-icon-helper">Edit</Text>
                      <EditIcon
                        boxSize={6}
                        cursor="pointer"
                        onClick={() => setDisableEdit(false)}
                      />
                    </Box>
                      
                      :
                      <ButtonGroup minW={"200px"}>
                        <Button
                          colorScheme="red"
                          minW="100px"
                          onClick={cancelEdit}
                        >
                          Cancel
                        </Button>
                        <Button
                          colorScheme="blue"
                          minW="100px"
                          onClick={onEditTeamName}
                          disabled={!changeTeamName || !changeTeamName.length || loading}
                        >
                          Save
                        </Button>
                      </ButtonGroup>
                    }
                  </Flex>
                  <FormErrorMessage
                    padding="0 .5rem"
                  >
                    Team name cannot be empty
                  </FormErrorMessage>
                </FormControl>
              </Box>
            </Flex>
            <Button
              variant="outline"
              colorScheme="blue"
              fontWeight="500"
              fontSize="15px"
              // onClick={onOpen}
              onClick={inviteMember}
            >
              Invite new member
            </Button>
          </Flex>

          <Table variant="simple" color="gray.500" mb="24px">
            <Thead>
              <Tr>
                {headData.map(({ id, title }) => (
                  <Th key={id} borderColor={borderColor}>
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {title}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody color="black">
              {members.map((member: memberType, index: number) => (
                <Tr key={index} color={textColor} fontSize="14px">
                  <Td textTransform="capitalize">
                    {member.name} {member.email === userEmail && "(You)"}
                  </Td>
                  <Td>{member.email}</Td>
                  <Td textTransform="capitalize">
                    <Select
                      value={getRoleValue(member)}
                      w="125px"
                      onChange={(e) => onChangeRole(e, member)}
                    >
                      <option value="viewer">Viewer</option>
                      <option value="developer">Developer</option>
                      <option value="admin">Admin</option>
                    </Select>
                    {/* {member.permission} */}
                  </Td>

                  <Td>
                    {!(userEmail === member.email) && (
                      <Prompt
                        prompt={`User: ${member.name}`}
                        mesOveride="This user will be removed from this team and they will lose access"
                        action={() => {
                          onDeleteMember(member.id);
                        }}
                      >
                        <DeleteIcon
                          cursor="pointer"
                        />
                      </Prompt>

                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Fragment>
      )}
    </Card>
  );
}
