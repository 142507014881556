import { AutoSave } from 'components/navbar/savechanges';
import { useUser } from 'context/UserContext';
import { dispatchPopup } from 'components/popups';

const { Events } = AutoSave;


export const useEditorToolbar = () => {
  const { modules, packageInfo, moduleIndex, newModule, setModuleIndex, } = useUser();


  /**
   * Select a module to edit on click
   * @param index module index
   */
  const handleSelect = async (index: number) => {
    setModuleIndex(index);
    const language = moduleIndex === 0 ? "json" : packageInfo?.language;
    Events.emit("editor-open-file", [{ language, index }]);
  }

  const handleAdd = async () => {
    const index = await newModule();
    setModuleIndex(index || moduleIndex);
  }

  /**
   * Triggers a new code popup box to add a new code block
   */
  const newCode = dispatchPopup("AddCode", {
    packageName: packageInfo?.name,
    language: packageInfo?.language,
    createCode: handleAdd
  });

  return {
    selected: moduleIndex,
    handleSelect,
    newCode,
    data: modules,
    ext: packageInfo?.ext,
  }
}
