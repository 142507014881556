import { Box } from "@chakra-ui/react";

function BlurOverlay() {
  return (
    <Box
      zIndex="99"
      position="absolute"
      right={0}
      bg="#F7FAFC"
      height="100%"
      top={{ base: "0", lg: "170px", xl: "0", md: "170px", sm: "170px" }}
      opacity={0.5}
      w={{ base: "calc(100vw - 316px)", md: "100%", sm: "100%" }}
      cursor="not-allowed"
    />
  );
}

export default BlurOverlay;
