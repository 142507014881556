import { Fragment, useCallback, useEffect, useState } from "react";
import { Box, Button, Flex, Text, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import BackdropOverlay from "common/BackdropOverlay";
import Card from "components/card/Card";
import Dropdown from "common/Dropdown";
import { useAuth } from "context/AuthContext";
import Code from "./Code";
import Design from "./Design";
import CodePlusDesign from "./CodePlusDesign";
import { MsgContainerTopCenter } from "utils/authError";
import AccessAlert from "common/AccessAlert";
import InvalidRole from "common/InvalidRole";
import { Share } from "./shareandlaunch/share";
import { Deploy } from "./shareandlaunch/deploy";
import { useAutoSave } from "./utils";
import { RunsDataTable } from "../dataTables/components/runs";
import { Destroy } from "./shareandlaunch/destroy";
import { processQueryparams } from "../cloudSettings/environment/utils";
import { dispatchPopup } from "components/popups";
import { Toolbar } from "./toolbar";

const btnData = [
  { id: 1, name: "Code + Visualize" },
  { id: 2, name: "Code" },
  { id: 3, name: "Visualize" },
];

export default function CloudSettings() {
  const {
    getCloudDesignTab,
    spaceId,
    planId,
    // docId, spaces,
    permission
  } = useAuth();

  const { pathname } = useLocation();
  const splitArr = pathname.split("/");
  const splitVal = splitArr[splitArr.length - 1];

  // Modal control
  const { onClose: onShareClose, onOpen: onShareOpen, isOpen: isShareOpen } = useDisclosure();

  // Launch Control
  const { onClose: onDeployClose, onOpen: onDeployOpen, isOpen: isDeployOpen } = useDisclosure();

  // Destroy Control
  const { onClose: onDestroyClose, onOpen: onDestroyOpen, isOpen: isDestroyOpen } = useDisclosure({ defaultIsOpen: false });

  // Autosave handler
  const { btnId, setBtnId, handleBtnClick, deployStatus } = useAutoSave();
  const [noPlan, setNoPlan] = useState(true)


  /**
   * Handle action in the query string
   */
  const handlePathActions = useCallback(() => {
    const action = processQueryparams()['action']
    switch (action) {
      case "deploy_plan":
        onDeployOpen();
        break;
      case "destroy_plan":
        onDestroyOpen();
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handlePathActions, []);


  useEffect(() => {
    if (pathname) {
      setBtnId(parseInt(splitVal));
      getCloudDesignTab(parseInt(splitVal));
    }
    if (planId === "no-plan" || planId === "") {
      setNoPlan(true)
    } else {
      setNoPlan(false)
    }

    //  eslint-disable-next-line
  }, [pathname, planId, splitVal]);

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  /**
   * Switch to cloud settings page and initiate create new plan in the selected space
   */
  const startCreatePlan = dispatchPopup("AddPlan", { spaceId });

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Share isOpen={isShareOpen} onClose={onShareClose} />
      <Deploy isOpen={isDeployOpen} onClose={onDeployClose}
        onOpen={onDeployOpen}
      />
      <Destroy isOpen={isDestroyOpen} onClose={onDestroyClose} />
      <InvalidRole />
      {permission === "viewer" && <AccessAlert readonlyAccess={true} />}
      <BackdropOverlay />
      {MsgContainerTopCenter}
      <Flex flexDirection="column">
        <Card p="0px">
          <Flex
            align={{ sm: "flex-start", lg: "center" }}
            justify="space-between"
            w="100%"
            px="22px"
            py="18px"
          >
            <Text color={textColor} fontSize="xl" fontWeight="600">
              Cloud Design
            </Text>
            <Box>
              {btnData.map(({ id, name }) => (
                <Link
                  key={id}
                  to={`/dashboard/cloud-design/${spaceId}/${planId}/${id}`}
                >
                  <Button
                    key={id}
                    variant={btnId === id ? "action" : "ghost"}
                    onClick={() => handleBtnClick(id)}
                  >
                    {name}
                  </Button>
                </Link>
              ))}
            </Box>
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              gap="8px"
              flexWrap="wrap"
            >
              <Button colorScheme="blue" disabled={permission === "viewer" || noPlan}
                onClick={onShareOpen}
              >
                Share
              </Button>
              <Button colorScheme="blue" disabled={permission === "viewer" || noPlan}
                onClick={onDeployOpen}
                name="deploy"
              >
                Deploy
              </Button>
              <Button colorScheme="blue" disabled={permission === "viewer" || noPlan || !deployStatus}
                onClick={onDestroyOpen}
                name="destroy"
              >
                Destroy
              </Button>
            </Flex>
          </Flex>

          <Dropdown disableBtn={noPlan} />
          {/* <Flex direction="row" justifyContent="center" alignItems="center">
            <Loader loading={loading} />
          </Flex> */}
          <Box >
            {noPlan ?
              <Fragment>
                <Flex w="100%" h="300px" justifyContent="center" alignItems="center" flexDir="column" gap="2rem">
                  <Text fontWeight="medium">Add a new plan to get started</Text>
                  <Button colorScheme="blue" w="30%" h="20%" minW="260px"
                    borderRadius="100px" fontSize="2rem"
                    onClick={startCreatePlan}
                  >+ Add New Plan</Button>
                </Flex>
              </Fragment> :
              <Fragment>
                {(btnId === 1 || btnId === 2) && <Toolbar />}
                {btnId === 1 && <CodePlusDesign />}
                {btnId === 2 && <Code />}
                {btnId === 3 && <Design />}
              </Fragment>}
          </Box>
        </Card>
        <Card p="0px" marginTop="10px">
          <RunsDataTable borderColor={borderColor} textColor={textColor} />
        </Card>
      </Flex>
    </Box>
  );
}
