import {
  PopoverContent,
  PopoverContentProps,
  Text, PopoverTrigger, Button, Icon, PopoverProps, Popover,
  Spinner
} from '@chakra-ui/react';
import { FC, ReactNode} from 'react';
import { BsCheck2Circle } from "react-icons/bs";
import { VscError } from "react-icons/vsc";

interface Msg {
  msg?: string;
  Elmnt?: boolean;
}

interface TriggerProps {
  spinner?: boolean;
  element?: boolean;
  error?: boolean;
}

type Content = PopoverContentProps & Msg;
const focusNone = { boxShadow: 'none', border: 'none' }
const Trigger: FC<{ children: ReactNode }> = PopoverTrigger;

export const _PopoverContent: FC<Content> = props => {
  const { msg, Elmnt, ...rest } = props
  return (
    <PopoverContent
      bg='transparent'
      boxShadow={'none'}
      _focus={focusNone}
      _active={focusNone}
      border='none'
      transition={{ duration: '2s' }}
      fontSize='lg'
      fontWeight='500'
      {...rest}
    >
      {Elmnt ? <div id='blank-message-pop-div' /> :
        <Text id="save-pop-text-id">
          {msg}
        </Text>
      }
    </PopoverContent>
  )
}

/**
 * Custom popover trigger
 */
export const _PopoverTrigger: FC<TriggerProps> = props => {
  const { spinner, element, error, ...rest } = props;
  return (
    <Trigger {...rest}>
      <Button
        bg='transparent' margin={0} p='0'
        w={element ? '0' : '1.5rem'}
        h={element ? '0' : '1.5rem'}
      >
        {spinner ?
          <Spinner size='md' color='green' speed='1.5s' /> :
          <Icon
            as={error ? VscError : BsCheck2Circle}
            color={error ? 'red.500' : 'green'} 
            w='1.75rem' h='1.75rem'
          />
        }
      </Button>
    </Trigger>
  )
}

/**
 * Custom popover component
 */
export const _Popover: FC<PopoverProps> = props => {
  return (
    <Popover
      closeOnBlur={false}
      placement='right'
      arrowPadding={0}
      autoFocus={false}
      styleConfig={{
        width: 'unset',
        maxWidth: 'unset'
      }}
      {...props}
    />
  )
}
