/**
 * Paste text from clipboard
 * @returns Promise<String>
 */
export const pasteText = async (): Promise<string> => {
  return await navigator.clipboard.readText()
}

/**
 * Paste text from clipboard to credentials area
 * @param textBoxId string
 */
export const clickToPaste = async (textBoxId: string) => {
  const text = await pasteText();
  const inputBox = document.getElementById(textBoxId) as HTMLInputElement;
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype, 'value'
  ).set;
  nativeInputValueSetter.call(inputBox, text);
  inputBox.dispatchEvent(new Event('input', { bubbles: true }));
}

/**
 * Process the url to get the query params
 * @returns {Record<string, string>} query params map
 */
export const processQueryparams = (): Record<string, string> => {
  const url = window.location.toString().split("?")[1];

  if(!url) return {}
  const pathMap = url
    .split("&")
    .map(param => param.split("="))
    .reduce<Record<string, string>>((values, [key, value]) => {
      values[key] = value;
      return values
    }, {});

  return pathMap;
}
