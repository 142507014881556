import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton
} from "@chakra-ui/react";
import { FC } from "react";
import { CustomModalProps } from "../types/types";

/**
 * Custom Modal for Use with various popups
 * @param props Modal props
 * @return JSX.Element
 */
export const CustomModal: FC<CustomModalProps> = props => {
  const { title, children, ...rest } = props;

  return (
    <Modal closeOnOverlayClick={false} {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        {children}
      </ModalContent>
    </Modal>
  );
};
