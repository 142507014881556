import React, { useState, useEffect } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue,
  Input,
  FormLabel,
  useDisclosure,
  Select,
} from "@chakra-ui/react";

import Card from "components/card/Card";
import { HSeparator } from "components/separator/Separator";
import { BasicModal } from "common/Modal";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "firebase-local/config";
import { useAuth } from "context/AuthContext";
import { billingType } from "types/userTypes";
import { toast } from "react-toastify";
// import moment from "moment";
import { MsgContainerTopCenter } from "utils/authError";
import Loader from "../../../common/Loader";
import { DateTime } from "functions";

const planData = [
  { id: 1, name: "Solo", value: "Solo" },
  { id: 2, name: "Team", value: "Team" },
  { id: 3, name: "Enterprise", value: "Enterprise" },
];
const durationData = [
  { id: 1, name: "Monthly", value: "Monthly" },
  { id: 2, name: "Yearly", value: "Yearly" },
];

export default function Billing({
  btnId,
  onClickSave,
}: {
  btnId?: number;
  onClickSave?: boolean;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [billingFields, setBillingFields] = useState<billingType>({
    plan: "",
    renewal: "",
    cycle: "",
    email: "",
  });
  // const [docId, setDocId] = useState<string>("");

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  // const textColorBrand = useColorModeValue("brand.500", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { currentUser, userEmail, billingData, docId } = useAuth();

  useEffect(() => {
    if (userEmail !== "") {
      setBillingFields(billingData);
    }
    // eslint-disable-next-line
  }, [currentUser, userEmail, billingData]);

  useEffect(() => {
    if (btnId === 1 && onClickSave) {
      onSaveBilling();
    }
    // eslint-disable-next-line
  }, [btnId, onClickSave]);

  // const getBillingData = (email: string) => {
  //   const usersRef = collection(db, "users");
  //   onSnapshot(usersRef, (snapshot) => {
  //     snapshot.docs.forEach((doc) => {
  //       if (doc.data().email === email) {
  //         setBillingFields(doc.data().billing);
  //         setDocId(doc.id);
  //       }
  //     });
  //   });
  // };

  const onChangeHandler = (e: any) => {
    setBillingFields({
      ...billingFields,
      [e.target.name]: e.target.value,
    });
    // if (e.target.value === "Monthly") {
    //   const nextRenewal = moment(Date.now())
    //     .add(1, "months")
    //     .utc()
    //     .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    //   setBillingFields({
    //     ...billingFields,
    //     renewal: nextRenewal,
    //     cycle: "Monthly",
    //   });
    // }
    // if (e.target.value === "Yearly") {
    //   const nextRenewal = moment(Date.now())
    //     .add(1, "years")
    //     .utc()
    //     .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    if (e.target.name === "cycle") {
      const nextRenewal = DateTime.getRenewalDate(e.target.value)
      setBillingFields({
        ...billingFields,
        renewal: nextRenewal,
        cycle: e.target.value,
      });
    }
  };

  const onSaveBilling = () => {
    if (docId !== "") {
      setLoading(true);
      const billingRef = doc(db, "orgs", docId);
      updateDoc(billingRef, {
        billing: billingFields,
      })
        .then(() => {
          toast.success("Billing updated successfully!");
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.message);
          setLoading(false);
        });
    }
  };

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }} mt="-64px">
      {/* Main Fields */}
      {MsgContainerTopCenter}
      <Flex flexDirection="column">
        <Card p="0px">
          {/* <Flex
            align={{ sm: "flex-start", lg: "center" }}
            justify="space-between"
            w="100%"
            px="22px"
            py="18px"
          >
            <Text color={textColor} fontSize="xl" fontWeight="600">
              Billing Details
            </Text>
            <Button colorScheme="blue" onClick={onSaveBilling}>
              Save
            </Button>
          </Flex> */}
          <Flex
            justifyContent="center"
            flexDirection="row"
            alignItems="center"
            mb="32px"
            mt="-32px"
          >
            <Loader loading={loading} />
          </Flex>
          <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            background="transparent"
            borderRadius="15px"
          >
            <Box
              w={{ base: "100%", md: "420px" }}
              maxW="100%"
              ml="16px"
              mr="16px"
            >
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Current Plan
              </FormLabel>
              <Select
                size={"lg"}
                fontSize="sm"
                mb="8"
                sx={{ borderRadius: "16px" }}
                name="plan"
                value={billingFields.plan}
                onChange={onChangeHandler}
              >
                {planData.map(({ id, name, value }) => (
                  <option key={id} value={value}>
                    {name}
                  </option>
                ))}
              </Select>
            </Box>
          </Flex>
          <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            background="transparent"
            borderRadius="15px"
          >
            <Box
              w={{ base: "100%", md: "420px" }}
              maxW="100%"
              ml="16px"
              mr="16px"
            >
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Billing Cycle
              </FormLabel>
              <Select
                size={"lg"}
                mb="8"
                fontSize="sm"
                sx={{ borderRadius: "16px" }}
                name="cycle"
                onChange={onChangeHandler}
                value={billingFields.cycle}
              >
                {durationData.map(({ id, name, value }) => (
                  <option key={id} value={value}>
                    {name}
                  </option>
                ))}
              </Select>
            </Box>
          </Flex>

          <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            background="transparent"
            borderRadius="15px"
          >
            <Box
              w={{ base: "100%", md: "420px" }}
              maxW="100%"
              ml="16px"
              mr="16px"
            >
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Billing Email
              </FormLabel>
              <Input
                isRequired={true}
                variant="auth"
                fontSize="sm"
                type="email"
                placeholder="hi@fabr.ltd"
                mb="24px"
                fontWeight="500"
                size="lg"
                name="email"
                value={billingFields.email}
                onChange={onChangeHandler}
              />
            </Box>
          </Flex>
          <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            background="transparent"
            borderRadius="15px"
          >
            <Box
              w={{ base: "100%", md: "420px" }}
              maxW="100%"
              ml="16px"
              mr="16px"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Next Renewal
              </FormLabel>
              <Text
                fontSize="sm"
                placeholder="hi@fabr.ltd"
                mb="24px"
                fontWeight="600"
                size="lg"
                mt="24px"
              >
                {DateTime.getDisplayTime(billingFields.renewal)}
              </Text>
            </Box>
          </Flex>
          <HSeparator mb="36px" mt="30px" />
          <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            background="transparent"
            borderRadius="15px"
          >
            <Box
              w={{ base: "100%", md: "420px" }}
              maxW="100%"
              ml="16px"
              mr="16px"
            >
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Danger Zone
              </FormLabel>
              <Button
                fontSize="sm"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                bg="red.400"
                color="white"
                _hover={{ bg: "red.500" }}
                onClick={onOpen}
              >
                Cancel subscription
              </Button>
            </Box>
          </Flex>
        </Card>
      </Flex>
      <BasicModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      {/* Delete Product */}
    </Box>
  );
}
