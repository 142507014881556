import { addDoc, collection } from "@firebase/firestore";
import { col } from "functions/types";
import { db } from "firebase-local/config";
import { useAuth } from "context/AuthContext";
import { AutoSave } from "components/navbar/savechanges";

export const useEnvs = () => {

  const { docId, getOrgsData } = useAuth();

  const addEnv = async (data: any, spaceId: string) => {
    try {
      const envRef = collection(
        db, col.orgs, docId, col.teams, docId, col.spaces, spaceId, col.environments
      );
      await addDoc(envRef, { ...data, provided: false });
      getOrgsData();
      AutoSave.display('Env added successfully')
    } catch (err) {
      AutoSave.error('Error adding env')
      console.error('useEnvs() -- Error adding env', err);
    }
  }

  return { addEnv }
}
