import { Alert, AlertIcon, AlertDescription } from "@chakra-ui/react";

function AccessAlert({ readonlyAccess }: { readonlyAccess?: boolean }) {
  const text1 =
    "You only have read-only access to this page due to your assigned permissions";
  const text2 =
    "You need additional access to this page, please contact one of the admin users at your organisation";
  return (
    <Alert status="info" borderRadius=".25rem" variant="left-accent">
      <AlertIcon />
      <AlertDescription fontSize="16px" fontWeight="light">
        {readonlyAccess ? text1 : text2}
      </AlertDescription>
    </Alert>
  );
}

export default AccessAlert;
