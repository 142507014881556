export interface IconTools {
  secret: boolean;
  showSecret: boolean;
  inputId: string;
  hideShowSecret: () => void;
  disabled: boolean;
}

export interface FieldError { status: boolean; field: string }
export interface FieldData { field: string; value: string }

export interface ProviderInput {
  provider: string;
  field: string;
  JSON?: boolean;
  type?: InputTypes;
  secret?: boolean;
  value?: string;
  disabled?: boolean;
  onGetError?: (e?: FieldError) => void;
  onSetField?: (e?: FieldData) => void;
  initialData?: any;
}
export interface ProviderSettings {
  type: InputTypes;
  secret: boolean;
  field: string;
}

export type CommonFieldNames = "name" | "vendor" | "region";

export interface CommonFields {
  name?: string;
  vendor?: ProviderNames;
  region?: string;
}

export interface ProvidersMap {
  providers: { [provider: string]: ProviderSettings[] };
}

export type InputTypes = 'text' | 'password' | 'textarea' | 'coming soon';

export type SecretField = ProviderInput;

export type ProviderNames = 'AWS' | 'GCP' | 'HRK' | 'DO' | 'AZR';

/**
 * AWS region names and codes
 */
const awsRegions = [
  { code: 'us-east-2', name: 'US East (Ohio)' },
  { code: 'us-east-1', name: 'US East (N. Virginia)' },
  { code: 'us-west-1', name: 'US West (N. California)' },
  { code: 'us-west-2', name: 'US West (Oregon)' },
  { code: 'af-south-1', name: 'Africa (Cape Town)' },
  { code: 'ap-east-1', name: 'Asia Pacific (Hong Kong)' },
  { code: 'ap-south-2', name: 'Asia Pacific (Hyderabad)' },
  { code: 'ap-southeast-3', name: 'Asia Pacific (Jakarta)' },
  { code: 'ap-southeast-4', name: 'Asia Pacific (Melbourne)' },
  { code: 'ap-south-1', name: 'Asia Pacific (Mumbai)' },
  { code: 'ap-northeast-3', name: 'Asia Pacific (Osaka)' },
  { code: 'ap-northeast-2', name: 'Asia Pacific (Seoul)' },
  { code: 'ap-southeast-1', name: 'Asia Pacific (Singapore)' },
  { code: 'ap-southeast-2', name: 'Asia Pacific (Sydney)' },
  { code: 'ap-northeast-1', name: 'Asia Pacific (Tokyo)' },
  { code: 'ca-central-1', name: 'Canada (Central)' },
  { code: 'eu-central-1', name: 'Europe (Frankfurt)' },
  { code: 'eu-west-1', name: 'Europe (Ireland)' },
  { code: 'eu-west-2', name: 'Europe (London)' },
  { code: 'eu-south-1', name: 'Europe (Milan)' },
  { code: 'eu-west-3', name: 'Europe (Paris)' },
  { code: 'eu-south-2', name: 'Europe (Spain)' },
  { code: 'eu-north-1', name: 'Europe (Stockholm)' },
  { code: 'eu-central-2', name: 'Europe (Zurich)' },
  { code: 'me-south-1', name: 'Middle East (Bahrain)' },
  { code: 'me-central-1', name: 'Middle East (UAE)' },
  { code: 'sa-east-1', name: 'South America (São Paulo)' },
]

/**
 * GCP regions
 */
const gcpRegions = [
  { name: '(Asia Pacific) Changhua County, Taiwan', code: 'asia-east1' },
  { name: '(Asia Pacific) Hong Kong', code: 'asia-east2' },
  { name: '(Asia Pacific) Tokyo, Japan', code: 'asia-northeast1' },
  { name: '(Asia Pacific) Mumbai, India', code: 'asia-south1' },
  { name: '(Asia Pacific) Jurong West, Singapore', code: 'asia-southeast1' },
  { name: '(Asia Pacific) Sydney, Australia', code: 'australia-southeast1' },
  { name: '(EU) Hamina, Finland', code: 'europe-north1' },
  { name: '(EU) St. Ghislain, Belgium', code: 'europe-west1' },
  { name: '(EU) London, England, UK', code: 'europe-west2' },
  { name: '(EU) Frankfurt, Germany', code: 'europe-west3' },
  { name: '(EU) Eemshaven, Netherlands', code: 'europe-west4' },
  { name: '(North America) Montréal, Québec, Canada', code: 'northamerica-northeast1' },
  { name: '(South America) São Paulo, Brazil', code: 'southamerica-east1' },
  { name: '(US Central) Council Bluffs, Iowa, USA', code: 'us-central1' },
  { name: '(US East) Moncks Corner, South Carolina, USA', code: 'us-east1' },
  { name: '(US East) Ashburn, Northern Virginia, USA', code: 'us-east4' },
  { name: '(US West) The Dalles, Oregon, USA', code: 'us-west1' },
  { name: '(US West) Los Angeles, California, USA', code: 'us-west2' },
]

/**
 * Azure regions
 */
const azureRegions = [
  { code: 'eastus', name: '(US) East US' },
  { code: 'eastus2', name: '(US) East US 2' },
  { code: 'southcentralus', name: '(US) South Central US' },
  { code: 'westus2', name: '(US) West US 2' },
  { code: 'westus3', name: '(US) West US 3' },
  { code: 'australiaeast', name: '(Asia Pacific) Australia East' },
  { code: 'southeastasia', name: '(Asia Pacific) Southeast Asia' },
  { code: 'northeurope', name: '(Europe) North Europe' },
  { code: 'swedencentral', name: '(Europe) Sweden Central' },
  { code: 'uksouth', name: '(Europe) UK South' },
  { code: 'westeurope', name: '(Europe) West Europe' },
  { code: 'centralus', name: '(US) Central US' },
  { code: 'southafricanorth', name: '(Africa) South Africa North' },
  { code: 'centralindia', name: '(Asia Pacific) Central India' },
  { code: 'eastasia', name: '(Asia Pacific) East Asia' },
  { code: 'japaneast', name: '(Asia Pacific) Japan East' },
  { code: 'koreacentral', name: '(Asia Pacific) Korea Central' },
  { code: 'canadacentral', name: '(Canada) Canada Central' },
  { code: 'francecentral', name: '(Europe) France Central' },
  { code: 'germanywestcentral', name: '(Europe) Germany West Central' },
  { code: 'norwayeast', name: '(Europe) Norway East' },
  { code: 'switzerlandnorth', name: '(Europe) Switzerland North' },
  { code: 'uaenorth', name: '(Middle East) UAE North' },
  { code: 'brazilsouth', name: '(South America) Brazil South' },
  { code: 'eastus2euap', name: '(US) East US 2 EUAP' },
  { code: 'qatarcentral', name: '(Middle East) Qatar Central' },
  { code: 'centralusstage', name: '(US) Central US (Stage)' },
  { code: 'eastusstage', name: '(US) East US (Stage)' },
  { code: 'eastus2stage', name: '(US) East US 2 (Stage)' },
  { code: 'northcentralusstage', name: '(US) North Central US (Stage)' },
  { code: 'southcentralusstage', name: '(US) South Central US (Stage)' },
  { code: 'westusstage', name: '(US) West US (Stage)' },
  { code: 'westus2stage', name: '(US) West US 2 (Stage)' },
  { code: 'asia', name: 'Asia' },
  { code: 'asiapacific', name: 'Asia Pacific' },
  { code: 'australia', name: 'Australia' },
  { code: 'brazil', name: 'Brazil' },
  { code: 'canada', name: 'Canada' },
  { code: 'europe', name: 'Europe' },
  { code: 'france', name: 'France' },
  { code: 'germany', name: 'Germany' },
  { code: 'global', name: 'Global' },
  { code: 'india', name: 'India' },
  { code: 'japan', name: 'Japan' },
  { code: 'korea', name: 'Korea' },
  { code: 'norway', name: 'Norway' },
  { code: 'singapore', name: 'Singapore' },
  { code: 'southafrica', name: 'South Africa' },
  { code: 'switzerland', name: 'Switzerland' },
  { code: 'uae', name: 'United Arab Emirates' },
  { code: 'uk', name: 'United Kingdom' },
  { code: 'unitedstates', name: 'United States' },
  { code: 'unitedstateseuap', name: 'United States EUAP' },
  { code: 'eastasiastage', name: '(Asia Pacific) East Asia (Stage)' },
  { code: 'southeastasiastage', name: '(Asia Pacific) Southeast Asia (Stage)' },
  { code: 'eastusstg', name: '(US) East US STG' },
  { code: 'southcentralusstg', name: '(US) South Central US STG' },
  { code: 'northcentralus', name: '(US) North Central US' },
  { code: 'westus', name: '(US) West US' },
  { code: 'jioindiawest', name: '(Asia Pacific) Jio India West' },
  { code: 'centraluseuap', name: '(US) Central US EUAP' },
  { code: 'westcentralus', name: '(US) West Central US' },
  { code: 'southafricawest', name: '(Africa) South Africa West' },
  { code: 'australiacentral', name: '(Asia Pacific) Australia Central' },
  { code: 'australiacentral2', name: '(Asia Pacific) Australia Central 2' },
  { code: 'australiasoutheast', name: '(Asia Pacific) Australia Southeast' },
  { code: 'japanwest', name: '(Asia Pacific) Japan West' },
  { code: 'jioindiacentral', name: '(Asia Pacific) Jio India Central' },
  { code: 'koreasouth', name: '(Asia Pacific) Korea South' },
  { code: 'southindia', name: '(Asia Pacific) South India' },
  { code: 'westindia', name: '(Asia Pacific) West India' },
  { code: 'canadaeast', name: '(Canada) Canada East' },
  { code: 'francesouth', name: '(Europe) France South' },
  { code: 'germanynorth', name: '(Europe) Germany North' },
  { code: 'norwaywest', name: '(Europe) Norway West' },
  { code: 'switzerlandwest', name: '(Europe) Switzerland West' },
  { code: 'ukwest', name: '(Europe) UK West' },
  { code: 'uaecentral', name: '(Middle East) UAE Central' },
  { code: 'brazilsoutheast', name: '(South America) Brazil Southeast' }
]

const doRegions = [
  { code: 'nyc1', name: 'New York City, United States 1' },
  { code: 'nyc2', name: 'New York City, United States 2 (legacy)' },
  { code: 'nyc3', name: 'New York City, United States 3' },
  { code: 'ams2', name: 'Amsterdam, the Netherlands 2 (legacy)' },
  { code: 'ams3', name: 'Amsterdam, the Netherlands 3' },
  { code: 'sfo1', name: 'San Francisco, United States	1 (legacy)' },
  { code: 'sfo2', name: 'San Francisco, United States 2 (legacy)' },
  { code: 'sfo3', name: 'San Francisco, United States 3' },
  { code: 'sgp1', name: 'Singapore' },
  { code: 'lon1', name: 'London, United Kingdom' },
  { code: 'fra1', name: 'Frankfurt, Germany' },
  { code: 'tor1', name: 'Toronto, Canada' },
  { code: 'blr1', name: 'Bangalore, India' },
  { code: 'syd1', name: 'Sydney, Australia' },
]

export const providersData = [
  { id: 1, name: "Alibab Cloud / Aliyun (coming)", value: "ALI" }, //ALI
  { id: 2, name: "Aiven (coming)", value: "AVN" }, //AVN
  { id: 3, name: "AWS", value: "AWS" },
  { id: 4, name: "Azure", value: "AZR" },
  { id: 5, name: "Cloudflare (coming)", value: "CF" }, //CF
  { id: 6, name: "Digital Ocean", value: "DO" },
  { id: 7, name: "GCP", value: "GCP" },
  { id: 8, name: "Genesis Cloud (coming)", value: "GNS" },
  { id: 9, name: "Heroku (coming)", value: "HRK" }, //HRK
  { id: 10, name: "Linode (coming)", value: "LND" }, //LND
  { id: 11, name: "OVHcloud (coming)", value: "OVH" }, //OVH
  { id: 12, name: "Scaleaway (coming)", value: "SW" }, //SW
];

export type RegionTypes = 'AWS' | 'GCP' | 'AZR' | 'DO';

export const Regions = {
  AWS: awsRegions,
  GCP: gcpRegions,
  AZR: azureRegions,
  DO: doRegions,
}
