import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/App.css";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
} from "react-router-dom";
import AdminLayout from "./layouts/admin";
import RTLLayout from "./layouts/rtl";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import SignInCentered from "./views/auth/signIn";
import SignUpCentered from "./views/auth/signUp";
import ForgotPassword from "./views/auth/resetPassword";
import AuthContextProvider from "./context/AuthContext";
import UserContextProvider from "./context/UserContext";

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <AuthContextProvider>
        <UserContextProvider>
          <HashRouter basename="/">
            <Switch>
              <Route exact path={`/signup`} component={SignUpCentered} />
              <Route exact path={`/login`} component={SignInCentered} />
              <Route
                exact
                path={`/forgot-password`}
                component={ForgotPassword}
              />
              <Route path={`/dashboard`} component={AdminLayout} />
              <Route path={`/rtl`} component={RTLLayout} />
              <Redirect from="/" to="/dashboard/main" />
            </Switch>
          </HashRouter>
        </UserContextProvider>
      </AuthContextProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
