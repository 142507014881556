import {
  Box,
  FlexOptions,
  FlexboxProps,
  InteractivityProps,
} from "@chakra-ui/react";
import {
  _PopoverContent as PopoverContent,
  _PopoverTrigger as PopoverTrigger,
  _Popover as Popover
} from "./stylesandtemplates";
import { FC, useEffect, useRef, useState } from "react";
import saveinfo, { Options } from "./autosaveevents";
import "./cssstyles/main.css";
import { FollowDeploy } from "./followDeploy";
import { FollowDeployProps } from "./types";
import { CustomModal, usePopups } from "components/popups";


type SaveProps = FlexOptions &
  FlexboxProps &
  InteractivityProps & { duration?: number };
/**
 * Default configs to use if none are provided
 */
const defaultOpt: SaveProps = {
  direction: "row",
  gap: "0.5rem",
  cursor: "pointer",
  justifyContent: "center",
  alignItems: "flex-start",
  duration: 10000,
};

/**
 * Element that acts as a message board to display all incomming messages
 * with the specified settings
 * @param props Custom Saveprops
 * @returns JSX Element
 */
const SavePop: FC<{ options?: SaveProps }> = ({ options }) => {
  const flexOpts = { ...defaultOpt, ...options };
  const [disp, setDisp] = useState<"flex" | "none">("none");
  const [msg, setMsg] = useState<string>("");
  const [spinner, setSpinner] = useState(false);
  const [element, setElement] = useState(false);
  const [duration, setDuration] = useState(flexOpts.duration);
  const [error, setError] = useState(true);
  const [opt, setOpt] = useState<'msg' | 'el'>('msg');
  const cleanup = useRef<null | (() => void)>(null)
  const Launch = useRef(null);
  const [launchProps, setLaunchProps] = useState<FollowDeployProps>()

  useEffect(() => {
    /**
     * Run cleanup function after the specified duration expires
     */
    const Timer = setTimeout(Cleanup, duration);

    /**
     * Setup a listner for incomming messages then call the message handler
     * method
     */
    const listener = saveinfo.on("auto-save-action", (data, options) => {
      cleanup.current = messageHandler(data, options);
    });

    /**
     * Setup a listener for incomming messages on launch
     */
    const launch = saveinfo.on("follow-launch-action", (data, options) => {
      cleanup.current = messageHandler(data, options)
    });

    /**
     * Setup a listener that disables timeout when follow launch window is open
     */
    const timeout = saveinfo.on('disable-timeout-delay', (data) => {
      const [state] = data;
      if (state) {
        clearTimeout(Timer)
      } else {
        Cleanup()
      }
    })

    /**
     * Cleanup on dismount
     */
    return () => {
      clearTimeout(Timer);
      listener.remove("auto-save-action");
      launch.remove("follow-launch-action");
      timeout.remove("disable-timeout-delay");
    };
    // eslint-disable-next-line
  }, [msg, disp, duration, element, spinner, cleanup, error, opt]);

  /**
   * Handle messages to determine whether they are elements or strings
   * then selects appropriate render method to execute
   * @param text Incomming data, string or element
   * @param options Configurations for the incomming message
   * @returns A function that cleans up all the configs made
   */
  const messageHandler = (data: any, options: Options) => {
    const updateDuration = options && options.duration;
    const [text] = data
    switch (options.action) {
      case 'loading':
        setMsg(text); setElement(false); setSpinner(true); setError(false);
        setDisp('flex'); setOpt('msg');
        return () => {
          setDisp('none'); setMsg(''); setSpinner(false);
        };

      case 'error':
        setError(true); setMsg(text); setSpinner(false); setElement(false);
        updateDuration && setDuration(options.duration);
        setOpt('msg'); setDisp('flex');
        return () => {
          setDisp('none'); setMsg(''); setError(false);
          setDuration(flexOpts.duration);
        };

      case 'launch':
        setDuration(updateDuration ? options.duration : 1000 * 20)
        setOpt('el'); setSpinner(false); setError(false);
        setLaunchProps(text)
        Launch.current = <FollowDeploy {...text} />
        setDisp('flex');
        return () => {
          setDisp('none'); setDuration(flexOpts.duration);
          setOpt('msg'); Launch.current = null;
        }

      default:
        setMsg(text); setSpinner(false); setElement(false); setError(false);
        updateDuration && setDuration(options.duration);
        setOpt('msg'); setDisp('flex');
        return () => {
          setDisp('none'); setMsg('');
          setDuration(flexOpts.duration);
        };
    }
  }

  /**
   * Cleanup function executes after a specified delay period
   */
  const Cleanup = () => {
    if (cleanup.current) {
      cleanup.current();
      cleanup.current = null;
    } if (Launch.current) {
      Launch.current = null;
    }
  }

  const { Popup, isOpen, onClose, props, title } = usePopups();
  return (
    <>
      <Box className="save-pop-up-class" display={disp}>
        {opt === 'msg' ?
          <Popover isOpen={disp === 'flex'}>
            <PopoverTrigger {...{ spinner, element, error }} />
            <PopoverContent msg={msg} Elmnt={element} />
          </Popover> :
          <FollowDeploy {...launchProps} />
        }
      </Box>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
      >
        <Popup {...props} onClose={onClose}/>
      </CustomModal>
    </>
  );
};

export default SavePop;
