import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLogout,
  MdDesignServices,
  MdSettings,
  MdHomeRepairService,
  MdRefresh,
  MdPeople,
} from "react-icons/md";
import CloudDesign from "./views/admin/cloudDesign/CloudDesign";
import CloudRelease from "./views/admin/cloudRelease/CloudRelease";
import CloudObserve from "./views/admin/cloudObserve/CloudObserve";
import ProfileSettings from "./views/admin/profileSettings/ProfileSettings";
// Admin Imports
import MainDashboard from "views/admin/default";
// import NFTMarketplace from "views/admin/marketplace";
// import Profile from "views/admin/profile";
// import DataTables from "views/admin/dataTables";
import Logout from "./views/auth/signOut";
// import RTL from "views/admin/rtl";
// import Billing from "views/admin/billing";
import ProfileTeam from "views/admin/profileTeam";
import CloudSettings from "views/admin/cloudSettings";
import Marketplace from "views/admin/marketplace";

// Auth Imports
// import SignInCentered from "views/auth/signIn";
// import { useAuth } from "context/AuthContext";

const routes = [
  {
    name: "Dashboard",
    layout: "/dashboard",
    path: "/main",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Cloud Design",
    layout: "/dashboard",
    icon: (
      <Icon as={MdDesignServices} width="20px" height="20px" color="inherit" />
    ),
    path: "/cloud-design",
    component: CloudDesign,
  },
  {
    name: "Cloud Release",
    layout: "/dashboard",
    icon: <Icon as={MdRefresh} width="20px" height="20px" color="inherit" />,
    path: "/cloud-release",
    component: CloudRelease,
  },
  {
    name: "Cloud Observe",
    layout: "/dashboard",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/cloud-observe",
    component: CloudObserve,
  },
  {
    name: "Marketplace",
    layout: "/dashboard",
    icon: <Icon as={MdHomeRepairService} width="20px" height="20px" color="inherit" />,
    path: "/marketplace",
    component: Marketplace,
  },
  {
    name: "Cloud Settings",
    layout: "/dashboard",
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    path: "/cloud-settings",
    component: CloudSettings,
  },
  {
    name: "Billing & Team",
    layout: "/dashboard",
    path: "/profile-team",
    icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
    component: ProfileTeam,
  },
  {
    name: "Profile",
    layout: "/dashboard",
    path: "/profile-settings",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: ProfileSettings,
  },
  {
    name: "Exit",
    layout: "/dashboard",
    path: "/exit",
    icon: <Icon as={MdLogout} width="20px" height="20px" color="inherit" />,
    component: Logout,
  },

];

export default routes;
