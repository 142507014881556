// Chakra imports
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import Footer from "components/footer/Footer";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
// Custom components
import { Link, NavLink } from "react-router-dom";
// Assets
import { FaChevronLeft } from "react-icons/fa";
import Logo from "../../assets/logos/infra-logo.png";

function AuthIllustration(props: {
  children: JSX.Element | string;
  illustrationBackground: string;
}) {
  const { children, illustrationBackground } = props;
  // Chakra color mode
  return (
    <Flex position="relative" h="max-content">
      <Flex
        h={{
          sm: "initial",
          md: "unset",
          lg: "100vh",
          xl: "97vh",
        }}
        w="100%"
        maxW={{ md: "66%", lg: "1313px" }}
        mx="auto"
        pt={{ sm: "50px", md: "0px" }}
        px={{ lg: "30px", xl: "0px" }}
        ps={{ xl: "70px" }}
        justifyContent="start"
        direction="column"
      >
        {/* <NavLink
          to="/dashboard"
          style={() => ({
            width: "fit-content",
            marginTop: "40px",
          })}
        >
          <Flex
            align="center"
            ps={{ base: "25px", lg: "0px" }}
            pt={{ lg: "0px", xl: "0px" }}
            w="fit-content"
          >
            <Icon
              as={FaChevronLeft}
              me="12px"
              h="13px"
              w="8px"
              color="secondaryGray.600"
            />
            <Text ms="0px" fontSize="sm" color="secondaryGray.600">
              Back to Simmmple
            </Text>
          </Flex>
        </NavLink> */}
        {children}
        <Box
          display={{ base: "none", lg: "block" }}
          h="88%"
          minH="88"
          w={{ lg: "50vw", "2xl": "44vw" }}
          position="absolute"
          right="0px"
          bg="purple.600"
          borderBottomLeftRadius={{ lg: "120px", xl: "200px" }}
        >
          <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            h="100vh"
            mt="20vh"
          >
            <Box
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="400px"
            >
              <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                mb="16px"
              >
                <img src={Logo} alt="logo" width="100px" height="100px" />
              </Flex>
              <Text
                align="center"
                fontSize="32px"
                fontWeight="bold"
                color="white"
                mb="16px"
              >
                Welcome
              </Text>

              <Text align="left" mb="16px" color="white">
                FABR Infra lets application developers plan, provision and
                observe cloud infrastructure, faster and cheaper. 
              </Text>
              <Text align="left" color="white">
                Based on <Link to="https://github.com/openfabr/cdf">OpenFABR CDF</Link> - our open source initiative, FABR Infra turns cloud operations into a collaborative, team activity.
              </Text>
            </Box>
          </Flex>
          {/* <Flex
            bg={`url(${illustrationBackground})`}
            justify="center"
            align="end"
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius={{ lg: "120px", xl: "200px" }}
          /> */}
        </Box>

        <Footer />
      </Flex>
      <FixedPlugin />
    </Flex>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
