import { FC, Fragment, useCallback, useEffect, useState } from "react";
import {
  ModalBody,
  Text,
  Input,
  useColorModeValue,
  FormControl,
  Flex,
  ModalBodyProps,
  FormErrorMessage
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { CredsInput, InputLabel, ProvidersInput, RegionSelect } from "./components";
import { doc } from "firebase/firestore";
import { db } from "firebase-local/config";
import { useAuth } from "context/AuthContext";
import Env from "./provider";
import { FieldData, ProviderNames, ProviderSettings, RegionTypes } from "./types";
import { AutoSave } from "components/navbar/savechanges";
import { CustomModalFooter } from "../components";
import { validateObj } from "./utils";

interface EditEnvProps extends ModalBodyProps {
  onClose: () => void;
  envId: string;
  spaceId: string;
}

const initialSecret = "*".repeat(12);

/**
 * Replace the asterisks with the initial data if they were not changed
 * @param obj secrets object
 * @param initialData initial data
 * @returns new secrets object
 */
const removeAsterisks = (obj: any, initialData: any) => new Promise((resolve, reject) => {
  try {
    for (const key in obj) {
      if (obj[key] === initialSecret) {
        obj[key] = initialData[key];
      }
    }
    resolve(obj);
  } catch (error) {
    reject(error);
  }
});

/**
 * Edit environment popup
 * @param props Edit environment popup props
 * @returns JSX.Element
 */
export const EditEnv: FC<EditEnvProps> = props => {

  const { onClose: __close, envId, spaceId } = props;
  const { getOrgsData } = useAuth();

  const [edit, setEdit] = useState(false);
  const [fields, setFields] = useState<ProviderSettings[]>([]);
  const [vendor, setVendor] = useState("");
  const [initialData, setInitialData] = useState<any>({});
  const [lockvendor, setLockVendor] = useState(false);
  const [envData, setEnvData] = useState<any>({
    name: "", vendor: "", region: "", credentials: {}
  })

  const textColor = useColorModeValue("#000", "#fff");


  const setCredentials = (e: FieldData) => {
    const { field, value } = e;
    const { credentials } = envData;
    const newCreds = { ...credentials, [field]: value };
    setEnvData((prev: any) => ({ ...prev, credentials: newCreds }));
  }


  /// Env validation
  const [errors, setErrors] = useState<any>({
    name: false, vendor: false, region: false
  });
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  /**
   * Validate the individual fields as the values are typed
   * @param e Input event
   * @returns void
   */
  const validate = (e: any) => {
    const { name, value } = e.target;
    if (value.trim() === "") {
      setErrors((prev: any) => ({ ...prev, [name]: true }));
    } else {
      setErrors((prev: any) => ({ ...prev, [name]: false }));
    }
  }

  /**
   * Local onclose function
   */
  const onClose = () => {
    Env.cleanup();
    __close();
  }

  /**
   * Change the state whether to edit the credentials or not
   * @returns void
   */
  const toggleEdit = () => setEdit(!edit);

  const { docId } = useAuth();

  /**
   * Handles updating enviroment credentials
   */
  const updateEnv = async () => {
    try {
      setLoading(true);
      const docRef = doc(
        db, "orgs", docId, "teams", docId, "spaces", spaceId,
        "environments", envId
      );

      const { credentials: __creds, ...rest } = envData;

      const credentials = await removeAsterisks(__creds, initialData);

      const updateData = { ...rest, vendor, credentials };
      await Env.updateEnv(docRef, updateData);
      getOrgsData();
      setLoading(false);
      AutoSave.display("Enviroment update successfull");
      onClose();
    } catch (e) {
      setLoading(false);
      AutoSave.error("Error updating env");
      console.log('EditEnv() -- Error updating env', e);
      onClose();
    }
  }

  /**
   * Callback funtion to initialize the editing process
   */
  const initEdit = useCallback(async () => {
    if (docId !== "" && spaceId !== "") {
      const docRef = doc(
        db, "orgs", docId, "teams", docId, "spaces", spaceId,
        "environments", envId
      );
      const { fields, commFields: envData, creds } =
        await Env.initCreds(docRef);
      setEnvData({ ...envData, credentials: creds });
      setFields(fields);
      if (envData.vendor) {
        setVendor(envData.vendor);
      }
      if (Env.isReady(envData.vendor)) {
        setLockVendor(true);
      } else {
        setLockVendor(false);
      }
      setInitialData(creds);
    }
  }, [docId, spaceId, envId]);

  /**
   * Local onchange function, that updates the credentials in the
   * global providers instance and locally
   * @param e Input Event
   * @returns void
   */
  const onChange = (e: any) => {
    const { name, value } = e.target;
    setEnvData((prev: any) => ({ ...prev, [name]: value }));
    validate(e);
  }

  /**
   * Initialize editing the env by getting the required configs
   */
  useEffect(() => { initEdit(); }, [initEdit]);

  /**
   * Auto update the envs' input fields for the selected vendor
   */
  useEffect(() => {
    if (vendor && vendor !== "") {
      const ven = vendor as ProviderNames;
      const __fields = Env.getProvider(ven, "edit");
      setFields(__fields);
    }

  }, [vendor]);

  /**
   * Validates input fields by checking if any is empty
   */
  useEffect(() => {
    try {
      const { credentials, vendor: _, ...rest } = envData;
      const fields = { ...rest, ...credentials }
      const valid = validateObj(fields);
      setDisabled(!valid || loading || vendor === "")

    } catch (e) {
      console.log('EditEnv() -- Error validating fields', e);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, vendor, loading, envData]);

  return (
    <Fragment>
      <ModalBody onBlur={() => Env.cleanup()}>
        <Flex
          position="absolute" top="1.5rem" right="5rem" zIndex="1"
          dir="row" onClick={toggleEdit} cursor="pointer"
          alignItems="center" gap=".5rem" fontWeight="medium"
          border="1px solid gray" borderRadius=".5rem" p="0 1rem"
          _hover={{ transform: "scale(1.2)" }}
          _active={{ bg: "#000", color: "#fff" }}
        >
          <EditIcon />
          <Text>Edit</Text>
        </Flex>
        <FormControl isRequired isInvalid={errors.name}>
          <InputLabel>Name</InputLabel>
          <Input color={textColor} name="name" value={envData.name}
            onChange={onChange} disabled={!edit}
            mb="1rem" borderRadius="1rem"
            placeholder="Enter environment name"
            onBlur={validate}
            _invalid={{ mb: ".25rem" }}
          />
          <FormErrorMessage mb=".5rem">
            Please enter a name for the environment
          </FormErrorMessage>
        </FormControl>
        <ProvidersInput label="Provider" name="vendor"
          onChange={(e) => {
            const value = e.target.value;
            setVendor(value);
            validate(e);
          }} value={envData.vendor}
          disabled={!edit || lockvendor} color={textColor}
          isInvalid={errors.vendor}
          onFocus={validate}
        />
        {Env.hasRegions(vendor as RegionTypes) &&
          <RegionSelect vendor={vendor as RegionTypes}
            value={envData.region} onChange={onChange}
            isInvalid={errors.region}
            onFocus={validate}
            disabled={!edit}
          />
        }
        {vendor && fields.map(field =>
          <CredsInput {...field} provider={vendor!} key={field.field}
            disabled={!edit} initialData={initialData}
            onSetField={setCredentials}
          />
        )}
      </ModalBody>
      <CustomModalFooter
        onAccept={updateEnv}
        onCancel={onClose}
        disabled={!edit || disabled}
        acceptLabel={loading ? "Please wait..." : "Update Env"}
        cancelLabel="Cancel"
      />
    </Fragment>
  )
}