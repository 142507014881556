import { useDisclosure } from "@chakra-ui/react";
import saveinfo, { Options } from "./autosaveevents";

/**
 * Handles sending messages to Display component for rendering
 * @param msg text or HTML string
 * @param options display configs optional
 */
const Emit = (msg: string, options?: Options) => {
  const opts: Options = { action: 'message', ...options }
  saveinfo.emit('auto-save-action', [msg], opts)
}

/**
 * Handles displaying launch progress info
 * @param props FollowLaunch props
 * @param options display configs optional
 */
export const deployProgress = (props: any, options?: Options) => {
  const opts: Options = { action: 'launch', ...options }
  saveinfo.emit('follow-launch-action', [props], opts)
}

/**
 * Handles sending error messages to Display component for rendering
 * @param msg Text to display
 */
export const Error = (msg: string) => {
  const opts: Options = { action: 'error' }
  saveinfo.emit('auto-save-action', [msg], opts)
}

/**
 * Hook to use with the deploy modal
 * @returns modal control methods and attribs
 */
export const useDeployModal = () => {
  const {isOpen, onOpen, onClose} = useDisclosure();
  return {status: isOpen, open: onOpen, close: onClose};
}

export default Emit;
