import { httpsCallable} from 'firebase/functions';
import {functions as _functions, CreateUser, CreateUserResults} from './types';
import {functions as funcs} from 'firebase-local/config'
 
class DataAccess {
  /**
   * Constructor
   * @param get Firebase getFunctions method
   * @param callable Firebase httpsCallable functions
   * @param functions A list containing predefined string function
   * names
   */
  constructor(
    private readonly get = funcs,
    private readonly callable = httpsCallable,
    private readonly functions = _functions
  ){}

  /**
   * Make a call to the cloud function (Create a new user)
   * @param user User details
   * @returns User's details including email verification
   * link
   */
  createUser = async (user: CreateUser) => {
    const creatUser = 
    this.callable(this.get, this.functions.CreateUser);
    const res =  await creatUser(user)
    return res.data as CreateUserResults;
  }
}

export default DataAccess;
