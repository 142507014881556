import { Table, Thead, Tr, Th, Td, Flex, Tbody, Text, useColorModeValue } from "@chakra-ui/react";
import { useAuth } from "context/AuthContext";
import { FC, useEffect, useState, useMemo, useCallback } from "react";
import { collection, doc, DocumentData, getDoc, DocumentReference, onSnapshot, query, where, orderBy } from "firebase/firestore";
import { db } from "firebase-local/config";
import { AutoSave } from "components/navbar/savechanges";
import { RiArrowDropRightLine, RiArrowDropLeftLine } from "react-icons/ri";
import { runJob, Status } from "functions/types";

import "./cssstyle/main.css"
import { useLocation } from "react-router";
import { DateTime } from "functions";

const { DeployStatus, useDeploy: useLaunch } = AutoSave;

const headData = [
  { id: 1, title: "Plan" },
  { id: 2, title: "Action" },
  { id: 3, title: "Environment" },
  { id: 4, title: "Launched By" },
  { id: 5, title: "Start Time" },
  { id: 6, title: "Finish Time" },
  { id: 7, title: "Status" },
  { id: 8, title: "Version" },
];

interface RowFields {
  command: string;
  environment: string;
  finishTime: string;
  launchedBy: string;
  plan: string;
  startTime: string;
  version: string;
  status: Status | "loading" | "";
}

interface TableRowProps {
  textColor: string;
  data: runJob;
}

interface RunsTableProps {
  borderColor: string;
  textColor: string;
}

/**
 * Show a list of runs for the selected space and plan
 * @param props RunsTableProps
 * @returns JSX Element
 */
export const RunsDataTable: FC<RunsTableProps> = props => {
  const { borderColor, textColor } = props;


  const { docs, currentPage, totalPages, navigatePage } = useRunsPreview();

  return (
    <>
      <Table variant="simple" color="gray.500" mb="24px">
        <Thead>
          <Tr>
            {headData.map(({ id, title }) => (
              <Th key={id} borderColor={borderColor}>
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color="gray.400"
                >
                  {title}
                </Flex>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {docs.map((doc, idx) => <TableRow key={doc.plan + idx + doc.version}
            data={doc} textColor={textColor}
          />)}
        </Tbody>
      </Table>
      <Flex direction="row" justify="flex-end" align="center" mr="32px">
        <Flex direction="row" justify={"flex-start"} align="center" gap="8px">
          <RiArrowDropLeftLine
            fontSize="32px"
            name="prev"
            onClick={() => navigatePage("prev")}
            cursor="pointer"
          />
          <Text fontSize="13px">{currentPage}</Text>
          <Text fontSize="14px">of</Text>
          <Text fontSize="13px">{totalPages} </Text>
          <Text fontSize="14px">pages</Text>
          <RiArrowDropRightLine
            cursor="pointer"
            fontSize="32px"
            name="next"
            onClick={() => navigatePage("next")}
          />
        </Flex>
      </Flex>
    </>
  )
}

/**
 * Fetches RunJob data and converts it to RowFields data
 */
class RowData implements RowFields {
  command: string = "No data";
  plan: string = "No data";
  environment: string = "No data";
  launchedBy: string = "No data";
  startTime: string = "";
  finishTime: string = "";
  version: string = "No data";
  status: Status = "queued";

  /**
   * Fetches collumn data related to the listed run doc and assigns the 
   * values to the row data fields
   * @param docId Firebase Document ID
   * @param spaceId Firebase Space ID
   * @param data run doc data
   */
  getFields = async (docId: string, spaceId: string, data: runJob) => {
    try {
      const envdocRef = this.getEnvref(data.environment, docId, spaceId);
      const plandocRef = this.getPlanref(data.plan, docId, spaceId);
      const usersDocRef = this.getUserref(data.member);

      this.command = data.command;
      this.environment = await this.getDocasync(envdocRef);
      this.plan = await this.getDocasync(plandocRef);
      this.launchedBy = await this.getDocasync(usersDocRef);
      this.finishTime = this.setTime(data.finishTime);
      this.startTime = this.setTime(data.startTime);
      this.version = data.version;
      this.status = data.status
    } catch (error) {
      console.error("getFields() ", error as Error)
    }
  }

  /**
   * Gets a firebase document reference for the environment id provided 
   * @param id Environment id
   * @param docId Firebase Document ID
   * @param spaceId Firebase Space ID
   * @returns Environment Document reference
   */
  private getEnvref = (id: string, docId: string, spaceId: string) => {
    try {
      const envdocRef = doc(
        db, "orgs", docId, "teams", docId,
        "spaces", spaceId, "environments",
        id
      );
      return envdocRef;
    } catch (error) {

      console.error("getEnvref() ", error as Error)
      return "ERROR"
    }
  }

  /**
   * Gets a firebase document reference for the plan id provided 
   * @param id Plan id
   * @param docId Firebase Document ID
   * @param spaceId Firebase Space ID
   * @returns Plan Document reference
   */
  private getPlanref = (id: string, docId: string, spaceId: string) => {
    try {
      const envdocRef = doc(
        db, "orgs", docId, "teams", docId,
        "spaces", spaceId, "plans",
        id
      );
      return envdocRef;
    } catch (error) {
      console.error("getPlanref() ", error as Error)
      return "ERROR"
    }
  }


  /**
   * Gets a firebase document reference for the user id provided 
   * @param id User ID
   * @returns User Document reference
   */
  private getUserref = (id: string) => {
    try {
      const envdocRef = doc(
        db, "users", id
      );
      return envdocRef;
    } catch (error) {
      console.error("getUservref() ", error as Error)
      return "ERROR"
    }
  }

  /**
   * Retrieves a firebase documents data for the document reference provided
   * @param ref Document reference
   * @returns Document Data
   */
  private readonly getDocasync = async (ref: DocumentReference<DocumentData> | "ERROR") => {
    if (ref === "ERROR") return "No data";
    try {
      const data = await getDoc(ref);
      const res = data.data();
      if (res && res.name) return res.name;
      return "No data"
    } catch (error) {
      console.error("getDocasync() ", error as Error)
      return "No data"
    }
  }

  /**
   * Processes the time value provided then call the time formatting 
   * method on it
   * @param time Timestamp value
   * @returns Time in string format or empty string on Error
   */
  private readonly setTime = (time: any) => {
    try {
      if (!time) {
        return ""
      } else {
        const date = time.toDate();
        return DateTime.getDisplayTime(date);
      }
    } catch (error) {
      console.error("setTime() ", error as Error)
      return ""
    }
  }

  // /**
  //  * Formarts time to the format DD/MM/YYYY : HH:mm"
  //  * @param date string Date
  //  * @returns Time in string format
  //  */
  // private readonly formartDate = (date: string) => {
  //   const format = "DD/MM/YYYY : HH:mm"
  //   return moment(date).format(format);
  // }

  /**
   * Table row data
   * @returns {RowFields} Row fields data
   */
  rows = (): RowFields => {
    const { plan, command, environment, finishTime, startTime, status, version, launchedBy } = this;
    return { plan, command, environment, launchedBy, startTime, finishTime, status, version, }
  }
}

/**
 * Table row element 
 * @param {TableRowProps} props Table row Props
 * @returns JSX Element
 */
const TableRow: FC<TableRowProps> = props => {
  const { textColor, data } = props;
  const [rowData, setRowData] = useState<RowFields>({
    command: "",
    environment: "", finishTime: "",
    launchedBy: "", plan: "",
    startTime: "", status: "",
    version: ""
  });
  const [outputData, setOutputData] = useState({ stderr: "", stdout: "" })
  const { spaces, docId, spaceId } = useAuth();
  const [loading, setLoading] = useState(true);

  const colorMode = useColorModeValue("light", "dark");

  const { open, close, status } = useLaunch();

  /**
   * A function to highlight the status field based on the value
   * @param key RowField keys
   * @param val RowField values
   * @returns color string
   */
  const highlight = (key: string, val: string) => {
    if (key !== "status") return "inherit";

    const status = val as Status;
    switch (status) {
      case "succeeded":
        return "green";
      case "failed":
        return "red";
      case "queued":
        return "#ffbf00";
      case "running":
        return "#ffbf00";
      default:
        return "inherit";
    }
  }

  /**
   * Sets the cursor based on the field name
   * @param key RowFIeld Keys
   * @returns cursor options
   */
  const cursor = (key: string) => {
    if (key === "plan" || key === "status") return "pointer";
    return "inherit"
  }

  /**
   * Sets onClick handler to the table row values
   * @param key RowField keys
   */
  const setClick = (key: string) => {
    if (key === "plan" || key === "status") {
      open();
    }
  }

  /**
   * Sets over options on table row data based on the value
   * @param key RowField keys
   * @returns hover options
   */
  const hover = (key: string) => {
    if (key === "status") {
      return "scale(1.2)"
    }
    return "none"
  }

  /**
   * Callback hook to call RowData instance and handle displaying
   * the run job values
   */
  const generateData = useCallback(async () => {
    if (spaces.length) {
      const row = new RowData();
      await row.getFields(docId, spaceId, data)
      const td = row.rows();
      if (td && td.plan) {
        setRowData(td);
        setLoading(false);
        setOutputData({
          stderr: data.stderr,
          stdout: data.stdout
        });
      }
    }
  }, [spaceId, docId, data, spaces]);

  useEffect(() => { generateData() }, [generateData])

  return (
    <>
      <DeployStatus isOpen={status} onClose={close} plan={rowData.plan} {...outputData} />
      <Tr
        color={textColor}
        textTransform="capitalize"
        fontSize="14px"
      >
        {
          Object.entries(rowData).map(([key, val]) =>
            <Td className={loading ? `data-table-${colorMode}-bg` : "data-table-content"}
              key={key} color={highlight(key, val)}
              cursor={cursor(key)}
            >
              <Text
                onClick={() => setClick(key)}
                _hover={{ transform: hover(key) }}
              >
                {val}
              </Text>
            </Td>
          )
        }
      </Tr>
    </>
  )
}

/**
 * Custom hook to handle data processing and viewing in the runs table
 * @returns utility functions and attributes
 */
function useRunsPreview() {
  const {
    spaces,
    docId,
    spaceId,
    planId,
    getSpaceId,
    getPlanId
  } = useAuth();

  const [runs, setRuns] = useState<runJob[]>([]);
  const [page, setPage] = useState<runJob[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [start, setStart] = useState(0);
  const [stop, setStop] = useState(1);
  const [offset, setOffset] = useState(10);

  const { pathname } = useLocation();
  const splitSpaceId = pathname.split("/");
  const urlSpaceId = splitSpaceId[3];
  const urlPlanId = splitSpaceId[4];

  /**
   * Initialize paging for the runs table data (determines the total pages
   * based on the users team preferences for a page size)
   */
  const initPages = useCallback((pageData: any[]) => {
    const offset = 10; //userAssociatedTeams[teamId].settings.entries || 10;
    const __totalPages = Math.ceil(pageData.length / offset) || 1;
    setTotalPages(__totalPages);
    setOffset(offset);
    setCurrentPage(1);
    setStart(0);
    setStop(offset);
  }, []);


  /**
   * Handles navigating between pages (next or prev page)
   * @param action string "next" or "prev"
   * @returns void
   */
  const navigatePage = (action: "next" | "prev") => {
    switch (action) {
      case "next":
        if (currentPage === totalPages) return;
        setCurrentPage(currentPage + 1);
        setStop(stop + offset);
        setStart(start + offset);
        break;

      case "prev":
        if (currentPage === 1) return;
        setCurrentPage(currentPage - 1);
        let pos = start - offset;
        if (pos < 0) pos = 0;
        setStop(pos + offset);
        setStart(pos);
        break;

      default:
        console.error("navigatePage(): Undefined action");
        break;
    }
  }

  /**
   * Get all the runs docs for the selected space and plan
   */
  const getRuns = () => {
    if (spaces.length) {

      // Do not proceed until docId and spaceId have loaded
      if (!docId || !spaceId) return console.error(`useRunsPreview().getRuns() missing docId or spaceId in query. docsId>> ${docId} spaceId>> ${spaceId}`);
      const runsRef = collection(db, "orgs", docId, "teams", docId, "spaces", spaceId, "runs");
      const docQuery = query(runsRef,
        where("plan", "==", planId),
        orderBy('createdAt', 'desc'),
      );

      onSnapshot(docQuery, (data) => {
        const docs = data.docs.map(doc => doc.data() as runJob);
        setRuns(docs);
      });
    }
  }

  useEffect(getRuns, [spaces, spaceId, docId, planId]);

  useEffect(() => {
    if (urlSpaceId) {
      getSpaceId(urlSpaceId);
    }

    if (urlPlanId) {
      getPlanId(urlPlanId);
    }
    // eslint-disable-next-line
  }, [urlSpaceId, urlPlanId]);

  const contents = useMemo(() => runs, [runs]);

  useEffect(() => { initPages(contents); }, [contents, initPages]);

  useEffect(() => {
    const __page = contents.slice(start, stop);
    setPage(__page);
  }, [stop, start, contents]);

  return { docs: page, currentPage, totalPages, navigatePage }
}