import {
  Popover,
  PopoverArrow,
  PopoverHeader,
  PopoverContent,
  PopoverTrigger as _PopoverTrigger,
  Button,
  forwardRef,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  ButtonGroup,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, ReactNode, useRef } from "react";

interface PromptProps {
  children: ReactNode;
  prompt: string;
  action: () => void;
  mesOveride?: ReactNode;
}
const footerProps = {
  border: "0",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  pb: 4,
};
export const Prompt: FC<PromptProps> = (props) => {
  const initialFocusRef = useRef();
  const { onClose, isOpen, onOpen } = useDisclosure();
  const { children, prompt, action } = props;

  /**
   * Local action to call the provided action and close the pop up
   * when done
   */
  const localAction = () => {
    action();
    onClose();
  };

  return (
    <Popover
      initialFocusRef={initialFocusRef}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      closeOnBlur
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverHeader
          children="ATTENTION!!!"
          textAlign="center"
          fontWeight="600"
        />
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody textAlign="center">
          {props.mesOveride ? (
            props.mesOveride
          ) : (
            <>
              <span>This action will delete</span>
              <span>
                <b>{` ${prompt} `}</b>
              </span>
              <span>this cannot be undone.</span>
            </>
          )}
        </PopoverBody>
        <PopoverFooter {...footerProps}>
          <ButtonGroup size="md">
            <Button
              colorScheme="red"
              children="Confirm"
              onClick={localAction}
            />
            <Button
              colorScheme="blue"
              children="Cancel"
              ref={initialFocusRef}
              onClick={onClose}
            />
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
export const PopoverTrigger: FC<{ children: ReactNode }> = _PopoverTrigger;
export const CustomButton = forwardRef((props, ref) => <Button ref={ref} />);
