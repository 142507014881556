import { useEffect } from "react";
import { useAuth } from "../../../context/AuthContext";
import { Redirect } from "react-router-dom";
import { Text } from "@chakra-ui/react";

function Logout() {
  const { currentUser, logout } = useAuth();

  useEffect(() => {
    logout();
  }, [logout]);

  if (!currentUser) {
    return <Redirect to="/login" />;
  }
  return (
    <Text mt="24" align="center" fontSize="24">
      Logging out...
    </Text>
  );
}

export default Logout;
