import React from "react";

// Chakra imports
import {
  Icon,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import { MdOutlineMoreHoriz } from "react-icons/md";

export default function Banner(props: {
  [x: string]: any;
  menudata?: any;
  menuid?: string;
}) {
  const { ...rest } = props;
  const { menudata, menuid } = props;

  const textColor = useColorModeValue("secondaryGray.500", "white");
  const textHover = useColorModeValue(
    { color: "secondaryGray.900", bg: "unset" },
    { color: "secondaryGray.500", bg: "unset" }
  );
  const iconColor = useColorModeValue("brand.500", "white");
  const bgList = useColorModeValue("white", "whiteAlpha.100");
  const bgShadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  // Ellipsis modals
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  return (
    <Menu isOpen={isOpen1} onClose={onClose1}>
      <MenuButton
        alignItems="center"
        justifyContent="center"
        bg={bgButton}
        _hover={bgHover}
        _focus={bgFocus}
        _active={bgFocus}
        w="30px"
        h="20px"
        ml="8px"
        lineHeight="100%"
        onMouseEnter={onOpen1}
        onMouseLeave={onClose1}
        borderRadius="5px"
        {...rest}
      >
        <Icon as={MdOutlineMoreHoriz} color={iconColor} w="24px" h="24px" />
      </MenuButton>
      <MenuList
        minW="unset"
        border={textColor}
        backdropFilter="blur(63px)"
        bg={bgList}
        boxShadow={bgShadow}
        borderRadius="20px"
        p="15px"
        onMouseEnter={onOpen1}
        onMouseLeave={onClose1}
      >
        <MenuItem
          transition="0.2s linear"
          color={textColor}
          _hover={textHover}
          p="0px"
          borderRadius="8px"
          _active={{
            bg: "transparent",
          }}
          _focus={{
            bg: "transparent",
          }}
          mb="10px"
        ></MenuItem>
        {menudata.length > 0 &&
          menudata.map((menu: any, index: number) => (
            <MenuItem
              key={menu.id}
              transition="0.2s linear"
              p="0px"
              borderRadius="8px"
              color={textColor}
              _hover={textHover}
              _active={{
                bg: "transparent",
              }}
              _focus={{
                bg: "transparent",
              }}
              mb="10px"
            >
              <Flex align="center">
                <Text
                  fontSize="sm"
                  fontWeight="400"
                  mt={index === 1 && "-10px"}
                >
                  {menuid === "plans"
                    ? index !== 0 && menu.name
                    : index !== 0 && menu.email}
                </Text>
              </Flex>
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  );
}
