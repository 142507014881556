import { useEffect, useRef, useState } from 'react';

export const useResize = (mode: 'resize' | 'full') => {
  const ref = useRef(null);

  const [size, setSize] = useState({ width: 1200, height: 716 });

  // Query screen size on mount
  useEffect(() => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    if (screenWidth < 1200) {
      setSize({ width: screenWidth, height: screenHeight });
    }
  }, []);

  // Watch window resize
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      if (width < 1200) setSize({ ...size, width,});
    });

    resizeObserver.observe(window.document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, [size]);


  /**
   * Toggle between full screen and resize
   */
  useEffect(() => {
    if (mode === 'full') {
      setSize({ width: window.innerWidth, height: 2 * window.innerHeight });
    } else {
      setSize({ width: 1200, height: 716 });
    }
  }, [mode]);

  return { ...size, ref, setSize};
}