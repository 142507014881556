import {
  CustomModal as Modal,
  destroyMessages,
  LaunchTextStyle as TextStyle,
  CustomSelect as Select,
  CustomFormLabel as Label,
  CustomFlexBox as FlexBox
} from './stylesandtemplates';
import { DestroyFooter } from './components';
import { Text } from '@chakra-ui/react';
import { FC, useRef, useState, Fragment } from 'react';
import useEnvironments from './utils';
import { PopProps } from './types';
import { Flex, Spinner } from '@chakra-ui/react';


export const Destroy: FC<PopProps> = ({ isOpen, onClose }) => {
  const { environments, runs, getRuns, runDestroy, isLoading} = useEnvironments(isOpen);
  const [envsId, setEnvsId] = useState('');
  const initialRef = useRef(null);

  /**
   * Method to invoke when the launch button is clicked
   */
  const onClickDestroy = () => {
    if (true) {
      runDestroy(envsId);
      onClose();
    }
  }

  /**
   * Handle setting envId from the select input
   * @param e Select event
   */
  const onChangeHandler = (e: any) => {
    const envId = e.target.value;  // Changed from envName to envId
    getRuns(envId);
    console.log("runs>>> ", runs)
    setEnvsId(envId);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} header={destroyMessages.header}
      initialFocusRef={initialRef} closeOnOverlayClick={false}
    > { isLoading ? <Fragment>
      <Text {...TextStyle}>Loading please wait ...</Text>
      <Flex justifyContent="center" alignItems="center">
        <Spinner size="xl" speed='.7s' colorScheme="blue" />
      </Flex>
    </Fragment>:
      <Fragment> 
            <Label label='Select environment' />
      <FlexBox >
        <Select data={environments} name={"environments"}
          placeholder={'Select Environment'}
          ref={initialRef}
          onChange={onChangeHandler}
        />


      </FlexBox>

      {(envsId !== '' && runs[0] && runs[0].data().command !== "deployed" && runs[0].data().status !== "succeeded")
        ? <Text {...TextStyle}>{destroyMessages.noinfra}</Text>
        : <Text {...TextStyle}>{destroyMessages.DestroyWarn}</Text>
      }

      <DestroyFooter disabled={(runs[0] && runs[0].data().command !== "deployed" && runs[0].data().status !== "succeeded")}
        onClose={onClose} onDestroy={onClickDestroy} />
    </Fragment> }
    </Modal>
  )
}
