import { Button, ModalFooter } from '@chakra-ui/react';
import { FC } from 'react';
import { CustomModalFooterProps } from '../types';

export const CustomModalFooter: FC<CustomModalFooterProps> = props => {
  const {
    onAccept, onCancel, cancelLabel, acceptLabel,
    disabled, ...rest
  } = props;
  
  const cancel = cancelLabel || 'Cancel';
  const accept = acceptLabel || 'Accept';

  /**
   * If no action is specified for the cancel button,
   * log a message to the console
   */
  const handleCancel = onCancel || (() => {
    console.log('No action specified for cancel button')
  });

  /**
   * If no action is specified for the accept button,
   * log a message to the console
   */
  const handleAccept = onAccept || (() => {
    console.log('No action specified for accept button')
  });

  return (
    <ModalFooter {...rest}>
      <Button
        mr={3}
        background="red.500"
        onClick={handleCancel}
      >
        {cancel}
      </Button>
      <Button
        colorScheme="blue"
        onClick={handleAccept}
        disabled={disabled}
      >
        {accept}
      </Button>
    </ModalFooter>
  )
}