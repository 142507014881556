import {
  CustomModal as Modal,
  LaunchTextStyle as TextStyle,
  CustomSelect as Select,
  CustomFormLabel as Label,
  CustomFlexBox as FlexBox,
  deployMessages,
} from './stylesandtemplates';
import { DeployFooter, FlexButton } from './components';
import { Text, Spinner, Flex } from '@chakra-ui/react';
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import useEnvironments from './utils';
import { PopProps } from './types';
import { pkgs } from 'functions';
import { uniqBy } from 'lodash';
import { Session } from 'functions/sessionhandler';

/**
 * Deploy pop up window | Deploy a package to a specific environment
 * @param props Uses Disclosure props
 * @return JSX Element
 */
export const Deploy: FC<PopProps> = ({ isOpen, onClose, onOpen: resume }) => {
  const {
    environments,
    updatePlanHandler,
    createNewEnv,
    runDeploy, packageId,
    vendor,
    isLoading,
  } = useEnvironments(isOpen);
  const [envsId, setEnvsId] = useState('')
  const [addPackage, setAddPackage] = useState(false);
  const [newPkgId, setNewPkgId] = useState('');
  const [loading, setLoading] = useState(false);

  const initialRef = useRef(null);

  /**
   * Method to invoke when the launch button is clicked
   */
  const onClickLaunch = () => {
    if (envsId !== '') {
      runDeploy(envsId);
      onClose();
    }
  }

  const onClickCreateNewEnv = () => {
    createNewEnv(resume);
    onClose();
  }

  /**
   * Handle setting envId from the select input
   * @param e Select event
   */
  const onChangeHandler = (e: any) => {
    if (e.target.name === "package-select") {
      const pkgId = e.target.value;
      setNewPkgId(pkgId);
      pkgs.setPackage(pkgId);
      return;
    }
    const envId = e.target.value;  // Changed from envName to envId
    setEnvsId(envId);
  }

  /**
   * Method to invoke when the attach package button is clicked
   * allows the user interface to render packages DDL
   */
  const onClickAttachPackage = () => {
    setAddPackage(true);
  }

  /**
   * Method to invoke when the update plan button is clicked
   * initiates plan doc update
   */
  const localUpdatePlanHandler = async () => {
    setLoading(true);
    await updatePlanHandler(newPkgId);
    setLoading(false);
  }

  // Check if env is set in session Storage
  useEffect(() => {
    if (!isOpen) return;

    const session = new Session()
    const envId = session.getItem(Session.keys['deploy-prefer-env']);
    if (envId) setEnvsId(envId);

  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} header={deployMessages.header}
      initialFocusRef={initialRef} closeOnOverlayClick={false}
    >
      {(loading || isLoading) ?
        // Delay 2 secs while content loads
        <Fragment>
          <Text {...TextStyle}>Loading please wait ...</Text>
          <Flex justifyContent="center" alignItems="center">
            <Spinner size="xl" speed='.7s' colorScheme="blue" />
          </Flex>
        </Fragment> :

        packageId === "" ?
          <Fragment>
            <Text {...TextStyle}>
              {deployMessages.NoPackage}
            </Text>
            {!addPackage ?
              <FlexButton onClick={onClickAttachPackage} label="Attach a package" />
              :
              <Fragment>
                <Select data={uniqBy(pkgs.packages, 'id')
                  .map((pkg) => { return { id: pkg.id, name: pkg.package.name } })}
                  name="package-select" onChange={onChangeHandler}
                  placeholder={'Select Package'}
                  value={newPkgId}
                />
                <FlexButton onClick={localUpdatePlanHandler}
                  label={loading ? "Please wait ..." : "Update Plan Package"}
                  disabled={newPkgId === '' || loading}
                />
              </Fragment>
            }
          </Fragment> :
          environments.length ?
            // Deploy to an existing env
            <Fragment>
              <Text {...TextStyle}>{deployMessages.DeployWarn}</Text>
              <Label label='Select environment' />
              <FlexBox >
                <Select data={environments} name={"environments"}
                  placeholder={'Select Environment'}
                  ref={initialRef}
                  onChange={onChangeHandler}
                  value={envsId}
                />
              </FlexBox>
              <DeployFooter disabled={envsId === ''}
                onClose={onClose} onDeploy={onClickLaunch} />
            </Fragment> :
            // Create a new env for package vendor if none exists
            <Fragment>
              <Text {...TextStyle}>
                {deployMessages.NoEnv.replaceAll('{vendor}', vendor)}
              </Text>
              <FlexButton onClick={onClickCreateNewEnv} label="+ Create A New Environment" />
            </Fragment>
      }
    </Modal>
  )
}
