import { Box } from "@chakra-ui/react";
// import DiagramEditor from "./DiagramEditor";
import { JSONcrack } from "jsoncrack/jsoncrack";

type Props = {};

const Design: React.FC<Props> = () => {
  return (
    <Box width="100%" height="550px">
      {/* <DiagramEditor /> */}
      <JSONcrack/>
    </Box>
  );
};

export default Design;
