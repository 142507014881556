import {
  Input, Icon, InputProps, Flex, FormLabel, Textarea,
  Box, Tooltip, Button, useColorModeValue, TextareaProps,
  forwardRef,
  SelectProps,
  Select,
  FormLabelProps,
  FormControl,
  FormErrorMessage
} from '@chakra-ui/react';
import {
  FC,
  Fragment,
  useEffect,
  useState
} from 'react';
import { FaPaste } from 'react-icons/fa';
import { HiEye, HiEyeOff } from 'react-icons/hi';
import { clickToPaste } from './utils';
import { InputStyle, ButtonStyle, IconStyle, LabelStyle } from './styles';
import { IconTools, ProviderInput, RegionTypes, SecretField } from './types';
import { Regions, providersData } from './types';

const initialSecret = '*'.repeat(12);

/**
 * Credential input
 * @param props SecretField type Object
 * @returns JSX Element
 */
export const CredsInput: FC<SecretField> = props => {
  const { provider, type, field, onGetError, initialData, onSetField, secret, ...rest } = props
  const id = provider + field;
  const [creds, setCred] = useState("");
  const [error, setError] = useState({
    message: "",
    status: false
  });

  /**
   * Onchange handler | Updates error state to parent
   * @param e Input event
   */
  const onChange = (e: any) => {
    let value: string = e.target.value;
    if (type === "password" && value.includes(initialSecret)) {
      value = value.replace(initialSecret, "");
    }
    setCred(value);
    validate(e);
    onGetError && onGetError({ field, status: error.status });
  }

  /**
   * Validate input field | Set error if the field is empty
   * @param e Input event
   */
  const validate = (e: any) => {
    const { value } = e.target;
    if (value.trim() === "") {
      setError({ message: `${field} is required`, status: true });
    } else {
      setError({ message: "", status: false });
    }
  }

  /**
   * Validate and update parent error state on blur
   * @param e Input event
   */
  const onBlur = (e: any) => {
    validate(e)
    onGetError && onGetError({ field, status: error.status });
  }

  // Update parent state
  useEffect(() => {
    onSetField({ field, value: creds });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creds, field]);

  // Set initial data if any
  useEffect(() => {
    if (initialData && initialData[field]) {
      if (type === "password" && initialData[field] !== "") {
        setCred(initialSecret);
      } else {
        setCred(initialData[field]);
      }
    }
  }, [initialData, field, type]);

  const textFields = { type, field, provider, onBlur, secret, onChange }
  const textAreaFields = { field, provider, onBlur, onChange }

  return (
    <>
      <FormControl isRequired isInvalid={error.status}>
        <FormLabel {...LabelStyle}
          htmlFor={id}
          children={field}
          fontSize={type === 'coming soon' ? 'lg' : 'sm'}
        />
        {type === 'textarea' &&
          <TextArea {...textAreaFields}
            value={creds}
            {...rest} />
        } {(type === 'text' || type === 'password') &&
          <TextInput {...textFields}
            value={creds}
            {...rest} />
        }
        <FormErrorMessage mb="1rem">
          {error.message}
        </FormErrorMessage>
      </FormControl>
    </>
  )
}

/**
 * Text input for text-box input types
 * @returns JSX Element
 */
export const TextInput: FC<InputProps & ProviderInput> = forwardRef((props, ref) => {
  // Hooks
  const borderFocus = useColorModeValue('blue', 'blue.400');
  const borderColor = useColorModeValue(
    'secondaryGray.100', 'rgba(135, 140, 189, 0.3)'
  );
  const textColor = useColorModeValue("#000", "#fff");
  const [showSecret, setShowSecret] = useState(false);

  // Static 
  const { provider, field, secret, type, ...rest } = props
  const id = provider + field;
  const inputProps = { ...InputStyle, ...rest, id };

  return (
    <Flex mb='.5rem' mt='0'
      flexDir='row'
      alignItems='center'
      justifyContent='center'
      border='1px solid'
      borderColor={borderColor}
      borderRadius='1rem'
      _focusWithin={{
        border: '1.5px solid',
        borderColor: borderFocus
      }}
    >
      <Input {...inputProps} placeholder={field} ref={ref} id={id}
        autoComplete="new-password"
        autoCorrect='nope'
        type={secret && showSecret ? 'text' : type}
        name={field} isRequired
        color={textColor}
        borderRadius="1rem"
        _invalid={{
          borderColor: 'none',
        }}
      />
      <PasteIcon inputId={id} secret={secret && type === "password"} showSecret={showSecret}
        hideShowSecret={() => setShowSecret(!showSecret)}
        disabled={props.disabled}
      />
    </Flex>
  )
})

/**
 * Text area for textarea input types
 * @returns JSX Element
 */
const TextArea: FC<TextareaProps & ProviderInput> = forwardRef((props, ref) => {
  const { provider, field, ...rest } = props
  const id = provider + field;
  const textColor = useColorModeValue("#000", "#fff");

  return (
    <Textarea {...rest} ref={ref} id={id} placeholder={field} name={field}
      autoCorrect='nope' isRequired
      color={textColor}
    />
  )
})

/**
 * Paste Icon => Handles pasting text into the field whose id is provided
 * @textBoxId Text field id
 * @returns JSX Element
 */
const PasteIcon: FC<IconTools> = ({ showSecret: hidden = true, secret = false,
  inputId, hideShowSecret, disabled
}) => {
  return (
    <Box position={'relative'} >
      <Flex direction={'row-reverse'} gap='.5rem' mr='.5rem'>
        <Tooltip label='Paste' placement='top'>
          <Button {...ButtonStyle} onClick={() => clickToPaste(inputId)}
            disabled={disabled}
          >
            <Icon {...IconStyle} as={FaPaste} />
          </Button>
        </Tooltip>
        {secret &&
          <Tooltip label={hidden ? 'Hide' : 'Show'} placement='top'>
            <Button {...ButtonStyle} onClick={hideShowSecret}
              disabled={disabled}
            >
              <Icon {...IconStyle} as={hidden ? HiEyeOff : HiEye} />
            </Button>
          </Tooltip>
        }
      </Flex>
    </Box>
  );
};

interface CustomSelect {
  vendor: RegionTypes;
  disabled?: boolean;
  isInvalid?: boolean;
}
export const RegionSelect: FC<SelectProps & CustomSelect> = props => {
  const { vendor, isInvalid, ...rest } = props;
  return (
    <FormControl isRequired isInvalid={isInvalid}>
      <FormLabel {...LabelStyle}
        children='Select region'
        htmlFor='vendor-region-select-input'
      />
      <Select {...rest}
        name='region'
        id='vendor-region-select-input'
        placeholder='select region'
        mb="4"
        fontSize="sm"
        sx={{ borderRadius: "16px" }}
      >
        {
          Regions[vendor].map(({ code, name }) => {
            return <option key={code} value={code}>
              {code} - {name}
            </option>;
          })
        }
      </Select>
      <FormErrorMessage mb=".5rem">
        Please select a region
      </FormErrorMessage>
    </FormControl>
  )
}

export const InputLabel: FC<FormLabelProps> = props => {
  return (
    <FormLabel
      display="flex"
      ms="4px"
      fontSize="sm"
      fontWeight="500"
      mb="8px"
      {...props}
    />
  )
}

interface ProvidersInputProps extends SelectProps {
  color: string;
  value: string;
  onChange: (e: any) => void;
  disabled?: boolean;
  name: string;
  label: string;
  isInvalid?: boolean;
}

export const ProvidersInput: FC<ProvidersInputProps> = props => {
  const { color, label, isInvalid, ...rest } = props;

  return (
    <FormControl isRequired isInvalid={isInvalid}>
      <FormLabel display="flex" ms="4px" fontSize="sm"
        fontWeight="500" color={color} mb="8px"
      >
        {label}
      </FormLabel>
      <Select
        placeholder="Select option" size={"md"} mb="1rem"
        fontSize="sm" sx={{ borderRadius: "16px" }}
        {...rest}
        name="vendor"
      >
        {providersData.map(({ id, name, value }) => (
          <option key={id} value={value}
            style={{ color: name.includes("(coming)") ? "gray" : "inherit" }}
          >
            {name}
          </option>
        ))}
      </Select>
      <FormErrorMessage mb="1rem">
        Please select a provider
      </FormErrorMessage>
    </FormControl>
  )
}
