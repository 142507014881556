import { Box } from "@chakra-ui/react";
import { CodeEditor } from "./codeeditor";

// import JSONEditor from "./JSONEditor";

type Props = {};

const Code: React.FC<Props> = () => {
  return (
    <Box height="550px" overflowY="auto">
      {/* <JSONEditor /> */}
      <CodeEditor />
    </Box>
  );
};

export default Code;
