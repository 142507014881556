import { CustomModal as Modal } from "./stylesandtemplates";
import {
  EmailInput, PermissionInput,
  Footer, NameInput
} from './components';
import { FC, useRef } from 'react';
import { useShare } from "./utils";
import { PopProps } from "./types";

/**
 * Invite a member to join a team
 * @param isOpen boolean
 * @param onClose handle close menu 
 * @returns JSX Element
 */
export const Share: FC<PopProps> = ({ isOpen, onClose }) => {
  const {
    onChangeHandler,
    onCheckMember,
    invite,
    onInviteMember
  } = useShare(onClose)

  const initialRef = useRef(null);

  return (
    <Modal isOpen={isOpen} onClose={onClose} header="Share"
      initialFocusRef={initialRef} >
      <NameInput onChange={onChangeHandler} ref={initialRef}/>
      <EmailInput onChange={onChangeHandler} invite={!invite}
        onBlur={onCheckMember} 
      />
      <PermissionInput onChange={onChangeHandler} />
      <Footer onClose={onClose} disabled={!invite}
        onInvite={onInviteMember}
      />
    </Modal>
  )
}
