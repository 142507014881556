import { FC, Fragment, useState, useEffect } from "react";
import { InputContainer } from "./inputbox";
import { Box, Checkbox, useColorModeValue } from "@chakra-ui/react";
import { uniqBy } from "lodash";

type ChecklistData = { name: string, id: string }[];

interface CheckListProps {
  data: ChecklistData;
  getData: (data: ChecklistData) => void;
  label: string;
  textColor?: string;
  error?: string;
  isRequired?: boolean;
  isInvalid?: boolean;
}

export const CheckList: FC<CheckListProps> = props => {
  const {
    label,
    isRequired,
    isInvalid,
    error,
    data,
    getData,
    ...rest
  } = props;

  const [list, setList] = useState<ChecklistData>([]);

  // call getData when list changes
  useEffect(() => {
    getData(list);

  }, [list, getData]);

  const checkColor = useColorModeValue("#7a7a8d70", "#49494969")


  return (
    <InputContainer
      label={label}
      isRequired={isRequired}
      isInvalid={isInvalid}
      error={error}
      {...rest}
    >
      <Box maxH="150px" display="flex" flexDir="column" overflow="auto"
        sx={{ borderRadius: "16px" }}
        border="1px solid #E2E8F0" minH="50px" p="1rem 0"
      >
        {uniqBy(data, 'id').map((item, index) =>
          <Fragment>
            <Checkbox
              p="0.25rem 1rem"
              bg={checkColor}
              mb="2px"
              name={item.name}
              key={index}
              onChange={(e) => {
                if (e.target.checked) {
                  setList([...list, item]);
                } else {
                  setList(list.filter((i) => i.id !== item.id));
                }
              }}
            >
              {item.name}
            </Checkbox>
          </Fragment>
        )}
      </Box>
    </InputContainer>
  );
};