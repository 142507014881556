import {db} from 'firebase-local/config';
import {
  collection,
  setDoc,
  doc,
  getDocs
} from 'firebase/firestore';
import { 
  CheckUser,
  col, MemberInvite,
  MemberInviteReturns, UID
} from './types';
import DataAccess from './adminaccess';

class ClientAccess {
  constructor(
    private readonly admin = new DataAccess()
  ){}

  /**
   * Invite a member to join a specific team
   * @param uid users' collection id for the user inviting
   * @param member details for the member being invited
   * @returns Invitation status 'SUCCESS' operation successfull
   * 'USEREXISTS' user is already a team member
   * 'FAILEDTOCREATE' An error occurred
   */
  async inviteTeamMembers(uid: UID, member: MemberInvite): Promise<MemberInviteReturns> {
    try {
      const ref = this.teamMembersRef(uid);
      const {id, isUser} = await this.userExists(member.email);
      const {id: newId} = doc(ref);
      if(isUser){
        const {email, name, permission } = member
        const data = {email, name, permission, reference: id }
        await setDoc(doc(ref, newId), data);
        return 'SUCCESS'
      } else {
        const {name, email} = member;
        const user = { data: {name, email}}
        const res = await this.admin.createUser(user)
          const data = {...member, reference: res.id}
          await setDoc(doc(ref, newId), data)
          //TODO placed for debugging should be removed
          console.log("inviteTeamMemebers().createUser() result>>>", res)
          return 'SUCCESS';
        }
    } catch (error) {
      console.error("inviteTeamMemebers() create failed! ", error as Error);
      return 'FAILEDTOCREATE';
    }  
  }

  /**
   * Generates a members collection reference document
   * @param uid Document reference id
   * @returns collection reference
   */
  private teamMembersRef = (uid: UID) => 
    collection(db, col.orgs, uid, col.teams, uid, col.members)
  
  /**
   * Generates a users collection reference document
   * @returns collection reference
   */
  private readonly usersRef = () =>
    collection(db, col.users);

  /**
   * Check if a user with the given email exists in the users collection
   * @param email email address to check
   * @returns an Object with the status and user id
   * @example Object {id: UID, isUser: boolean}
   */
  private readonly userExists = async (email: string): Promise<CheckUser> => {
    const ref = this.usersRef()
    const users = await getDocs(ref)
      .then(snap => snap.docs.filter( doc => doc.data().email === email))
      .then( ([doc]) => {
        if(doc){
          return {isUser: true, id: doc.id}
        } else {
          return {isUser: false, id: ''}
        }
      })
    return users;
  }
}

export default ClientAccess;
