import { doc, getDoc } from "firebase/firestore";
import { db } from "firebase-local/config";
import { col } from "functions";
import { ToolingLanguage } from "functions/types";


/**
 * Get package details from firestore for the given package id
 * @param id package id
 * @returns an object containing package name, file extension and language
 * used in the package implementation
 */
export  const getPackageDetails = async (id: string) => {
  const docRef = doc(db, col.pkgs, id);
  const snap = await getDoc(docRef);
  const data = snap.data();
  const name = data?.name;
  const language = data?.language as ToolingLanguage;
  return {
    name,
    ext: getLanguageExt(language),
    language,
    id
  };
};


/**
 * Get the file extension for the package language
 * @param language language used in the package implementation
 * @returns string file extension
 */
export const getLanguageExt =  (language: ToolingLanguage) => {
  switch (language) {
    case "typescript":
      return ".ts";
    case "java":
      return ".java";
    case "python":
      return ".py";
    case "csharp":
      return ".cs";
    case "go":
      return ".go";
    default:
      return ".ts";
  }
};
