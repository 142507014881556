import { useAuth } from "context/AuthContext"
import { useEffect, useState } from "react"
import { col } from "functions"
import { db } from "firebase-local/config"
import {
  collection,
  limit,
  query,
  where,
  onSnapshot,
  addDoc,
  doc,
  getDocs,
  writeBatch,
  serverTimestamp,
} from "firebase/firestore"
import { AutoSave } from "components/navbar/savechanges"

interface spaceMember {
  name: string;
  email: string;
}

const membersConverter = {
  toFirestore: (data: spaceMember) => {
    return {
      name: data.name,
      email: data.email,
    };
  },
  fromFirestore: (snapshot: any, options: any) => {
    const data = snapshot.data(options);
    return {
      name: data.name,
      email: data.email,
      id: snapshot.id,
    };
  },
};

export const useSpace = () => {
  const { docId, userData, spaceId, getOrgsData } = useAuth()
  const [teamMembers, setTeamMembers] = useState<any[]>([])

  useEffect(() => {
    if (spaceId === "" || docId === "") return;

    const membersRef = collection(db, col.orgs, docId,
      col.teams, docId, col.members)
      .withConverter(membersConverter);

    const membersQuery = query(membersRef,
      where("email", "!=", userData.email),
    );

    const unsubscribe = onSnapshot(membersQuery, (snapshot) => {
      const members = snapshot.docs.map(doc => doc.data());
      setTeamMembers(members);
    });

    return () => unsubscribe();

  }, [docId, userData, spaceId])

  const addSpace = async (data: any, members: spaceMember[] = []) => {
    try {
      const spaceRef = collection(
        db, col.orgs, docId, col.teams, docId, col.spaces
      );
      const teamMembersRef = collection(db, col.orgs, docId,
        col.teams, docId, col.members
      ).withConverter(membersConverter);
      const teamAdminQuery = query(teamMembersRef,
        where("email", "==", userData.email),
        limit(1)
      );

      const snapshot = await getDocs(teamAdminQuery);

      const [teamAdmin] = snapshot.docs.map(doc => doc.data());

      members.unshift(teamAdmin);

      const write = writeBatch(db)

      const id = await addDoc(spaceRef, {
        ...data,
        createdAt: serverTimestamp()
      }).then(async res => res.id);

      const spaceMembersRef = collection(db, col.orgs, docId,
        col.teams, docId, col.spaces, id, col.members
      );

      members.forEach(member => {
        const memberRef = doc(spaceMembersRef);
        write.set(memberRef, {
          name: member.name,
          email: member.email,
        });
      });

      // Commit all the writes
      await write.commit();
      getOrgsData();
      AutoSave.display('Space added successfully')
    } catch (error) {
      console.log('addSpace(): ', error);
      AutoSave.error('Error adding space')
    }
  }


  return { teamMembers, addSpace }
}