import { FC } from "react";
import { Box, Button, Flex, Text, Image, useColorModeValue } from "@chakra-ui/react";

interface SaasTemplateProps {
  info: string[],
  platform: string,
  title: string,
  logo: any
}

export const SaasTemplate: FC<SaasTemplateProps> = props => {
  
  const createPlan = () => {
    document.location = `/#/dashboard/cloud-settings/1?action=launch_plan`;
  }

  const bgColor = useColorModeValue("#d0e2f3", "#4d18b0");

  const { info, platform, title, logo } = props;
  return (
    <Flex flexDir="column" gap="2rem" pos="relative" width="200px"
      border="1px solid black" p="8px" borderRadius="1rem"
      boxShadow="1px 2px #000" bg={bgColor}
    >
      <Flex flexDir="row" alignItems="center" gap=".25rem">
        <Image src={logo}
          boxSize="75px"
          objectFit="cover"
          alt="logo"
        />
        <Text fontWeight="bold" fontSize="1.2rem" >{title}</Text>
      </Flex>
      <Box mb="1rem">
        {[...info, platform].map((line, id) =>
          <Flex flexDir="row" key={id}>
            <Text flex="1">-</Text>
            <Text flex="4">{line}</Text>
          </Flex>
        )}
      </Box>
      <Button
        pos="absolute"
        bottom="-1rem"
        right="-1rem"
        colorScheme="blue"
        onClick={createPlan}
      >
        Launch
      </Button>
    </Flex>
  )
}