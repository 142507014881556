import { FC } from "react";
import { InputContainer } from "./inputbox";
import { Select } from "@chakra-ui/react";
import { CustomSelectProps } from "../types";
import { uniqBy } from "lodash";


export const CustomSelect: FC<CustomSelectProps> = props => {
  const { label, isRequired, isInvalid, error, data, ...rest } = props;

  return (
    <InputContainer
      label={label}
      isRequired={isRequired}
      isInvalid={isInvalid}
      error={error}
    >
      <Select
        size={"lg"}
        // mb="8"
        fontSize="sm"
        sx={{ borderRadius: "16px" }}
        {...rest}
      >
        {uniqBy(data, 'id').map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </Select>
    </InputContainer>
  );
};
