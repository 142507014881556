import { useColorModeValue } from "@chakra-ui/system";
import { useUser } from "context/UserContext";
import { FC, useCallback, useEffect, useState } from "react";
import "./cssstyles/main.css"


export const JSONcrack: FC = () => {
  const {diagramData} = useUser();
  const [loading, setLoading] = useState(true);

  const theme = useColorModeValue("light", "dark");

  const sendToEmbed = useCallback((jsonCrackEmbed: HTMLIFrameElement ) => {
    const options = {
      theme,
      direction: "RIGHT", 
    };
    jsonCrackEmbed.contentWindow.postMessage({
      json: diagramData,
      options
    }, "*");
  }, [diagramData, theme])

  useEffect (() => {
    const jsonCrackEmbed = document.getElementById("jsoncrackEmbed") as HTMLIFrameElement;
    jsonCrackEmbed.addEventListener("load", () => {
      setTimeout(() => {
        sendToEmbed(jsonCrackEmbed);
        setLoading(false);
      }, 500 )
    })
    const update = setTimeout(() => {
      sendToEmbed(jsonCrackEmbed)
    }, 500);

    return () => {
      clearTimeout(update)
    }
  }, [sendToEmbed]);

  return (
    <div id="json-crack-editor-id" className={loading ? `json-crack-${theme}-editor` : "jsoncrack" }>
      <iframe id="jsoncrackEmbed" title="editor" 
        src="https://jsoncrack.com/widget" 
      />
    </div>
  )
}