
export type Actions = 'loading' | 'error' | 'launch' | 'message'
/**
 * Interface Options
 */
export interface Options {
  duration?: number;
  action?: Actions
}
/**
 * Handler function prototype
 */
type Handler = (data: any[], options?: Options) => void;

/**
 * Events object
 */
interface Events {
  [event: string]: Handler[];
}

/**
 * Simple event emitter
 */
class EventHandler {
  private events: Events = {};
  constructor() {}

  /**
   * Subscribe to an event
   * @param event event name
   * @param handler function to be executed on event emitted
   */
  on(event: string, handler: Handler) {
    if (this.events[event]) {
      this.events[event].push(handler);
    } else {
      this.events[event] = [handler];
    }
    return { remove: this.remove };
  }

  /**
   * Dispatch an event
   * @param event event name
   * @param data event data
   * @returns void
   */
  emit(event: string, data: any[], options?: Options) {
    if (!this.events[event]) return;
    this.events[event].forEach((ev) => ev(data, options));
  }

  /**
   * Remove event
   * @param event Event name
   * @returns void
   */
  private remove = (event: string) => delete this.events[event];
}

/**
 * Event emitter instance
 */
const saveinfo = new EventHandler();

export default saveinfo;
