import BarLoader from "react-spinners/BarLoader";

type Props = {
  loading: boolean;
};

const overrideCss = {
  display: "block",
  margin: "2rem 0 0 0",
  borderColor: "white",
};

const Loader: React.FC<Props> = ({ loading }) => {
  return (
    <BarLoader
      color="#422AFB"
      loading={loading}
      cssOverride={overrideCss}
      height={5}
      width={200}
    />
  );
};

export default Loader;
