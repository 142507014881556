import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "context/AuthContext";

export default function BackdropOverlay() {
  const { spaces } = useAuth();

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: "999" }}
        open={spaces.length === 0}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
