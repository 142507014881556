import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Box,
  Textarea,
} from "@chakra-ui/react";
import { useAuth } from "context/AuthContext";

export function BasicModal({ onOpen, isOpen, onClose }: any) {
  const { billingData } = useAuth();
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cancel Subscription</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {billingData && billingData.plan === "Solo" ? (
              <Text mt="16px" mb="16px">
                Please upgrade plan to Team or Enterprise, Solo is a defaul plan
              </Text>
            ) : (
              <Box>
                <Text>
                  We are sorry to see you considering cancelation. <br /> Any
                  perticular reason you are cancelation ?
                </Text>
                <Textarea
                  placeholder="mention reason to leave"
                  resize="none"
                  mt="16px"
                />
              </Box>
            )}
            <br />
            Or talk to us about our service at <br />
            [@fabr.ltd] <br /> (mailto:hi@fabr.ltd) <br /> as we're always
            adding new features.
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              bg="red.400"
              color="white"
              _hover={{ bg: "red.500", color: "white" }}
              disabled={billingData && billingData.plan === "Solo"}
            >
              Cancel Subscription
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
