export const userArrayTpesValues = {
  id: "",
  email: "",
  name: "",
  // country: "",
  // timeZone: "UTC",
  // entries: 10,
  // billing: {
  //   plan: "",
  //   cycle: "",
  //   renewal: "",
  //   email: "",
  // },
  team: {
    id: "",
    name: "default team",
    settings: {
      timeZone: "UTC",
      entries: 10
    },
    members: [
      {
        id: "",
        name: "",
        email: "",
        role: "admin",
      },
    ],
  },
  spaces: [
    {
      createdAt: 1234,
      id: "",
      name: "default space",
      plans: [{ id: "", name: "default plan" }],
      teams: {
        id: "",
        name: "default team",
        members: [
          {
            id: "",
            name: "",
            email: "",
            role: "admin",
          },
        ],
      },

      environments: [
        {
          id: "",
          name: "default environment",
        },
      ],
    },
  ],
};
