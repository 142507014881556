// Chakra imports
import { Flex, Text } from "@chakra-ui/react";

// Custom components
// import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

import Logo from "../../../assets/logos/infra-logo.png";

export function SidebarBrand() {
  //   Chakra color mode
  // let logoColor = useColorModeValue("navy.700", "white");
  const goHome = () => (document.location = "/#/")

  return (
    <Flex alignItems="center" flexDirection="column"
      cursor="pointer" onClick={goHome}
    >
      <Flex
        direction="row"
        justifyContent="center"
        alignItems="center"
        mb="16px"
      >
        <img src={Logo} alt="logo" width="50px" height="50px" />
        <Text fontSize="3xl" as="b">
          FABR Infra
        </Text>
      </Flex>
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
