import { useEffect, useRef } from "react";
import { WriteCode } from "./writecode";
import { TerminalThemDark, TerminalThemeLight } from "./constants";
import { useColorModeValue } from "@chakra-ui/system";
import { ITheme } from "xterm";

const themes: Record<string, ITheme> = {
  dark: TerminalThemDark,
  light: TerminalThemeLight
}

/**
 * Custom hook for passing data to the terminal
 * @param data data to write
 * @returns methods to aid in display
 */
export const useTerminal = (data: string) => {
  const containerRef = useRef(null);
  const fitRef = useRef(null);

  const theme = useColorModeValue('light', 'dark');

  /**
   * Handle updating the contents of stdout and stderr and calling the various
   * styling methods
   * @returns Cleanup function
   */
  const codeHandler = () => {
    const codeContainer = containerRef.current;

    if (!codeContainer) return;

    const terminal = new WriteCode(data, codeContainer, themes[theme]);

    fitRef.current = terminal.applyXterm();

    return () => {
      terminal.dispose();
    }
  }

  useEffect(codeHandler, [containerRef, data, theme])

  return { containerRef, fitRef }
}
