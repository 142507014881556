import React, { useState, useEffect, Fragment } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue,
  FormLabel,
  useDisclosure,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";

import Card from "components/card/Card";
// import { HSeparator } from "components/separator/Separator";
import { BasicModal } from "common/Modal";
import { useAuth } from "context/AuthContext";
// import { profileTypes } from "../../../types/userTypes";
import { toast } from "react-toastify";
import { MsgContainerTopCenter } from "utils/authError";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "firebase-local/config";
import Loader from "../../../common/Loader";
import Spaces from "./Spaces";
import { useLocation, Link } from "react-router-dom";
import BackdropOverlay from "common/BackdropOverlay";
import AccessAlert from "common/AccessAlert";
import BlurOverlay from "common/BlurOverlay";
import InvalidRole from "common/InvalidRole";

const timeZoneData = [
  { id: 1, name: "UTC", value: "UTC" },
  { id: 2, name: "+1", value: "+1" },
  { id: 3, name: "+2", value: "+2" },
  { id: 4, name: "-2", value: "-2" },
  { id: 5, name: "-1", value: "-1" },
];

const btnData = [
  { id: 1, name: "Spaces" },
  { id: 2, name: "Display Preference" },
];

export default function CloudSettings() {
  const { 
    teamId, permission,
    getCloudSettingsTab, 
    userAssociatedTeams,
    docId
   } = useAuth();

  const [btnId, setBtnId] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [saveClicked, setSaveClicked] = useState<boolean>(false);

  const { pathname } = useLocation();
  const splitArr = pathname.split("/");
  const splitVal = splitArr[splitArr.length - 1];

  const [preferenceFields, setPreferenceFields] = useState({
    timeZone: "",
    entries: 10,
  });

  useEffect(() => {
    const selectedTeam = userAssociatedTeams.find(team => team.id === teamId);
    if (selectedTeam) {
      const {settings} = selectedTeam;
      setPreferenceFields(settings);
    }
    if (pathname) {
      setBtnId(parseInt(splitVal));
      getCloudSettingsTab(parseInt(splitVal));
    }
    // eslint-disable-next-line
  }, [userAssociatedTeams, pathname]);

  const onChangeHandler = (e: any) => {
    const value = e.target.value;
    setPreferenceFields({
      ...preferenceFields,
      [e.target.name]: value,
    });
  };

  const onSavePreference = () => {
    if (btnId === 2) {
      const { timeZone, entries } = preferenceFields;
      if (teamId !== "") {
        setLoading(true);
        const teamsRef = doc(db, "orgs", docId, "teams", teamId);
        updateDoc(teamsRef, {
          settings: {
            timeZone,
            entries
          }
        })
          .then(() => {
            toast.success("Preference updated successfully!");
            setLoading(false);
          })
          .catch((err) => {
            toast.error(err.message);
            setLoading(false);
          });
      }
    } else {
      setSaveClicked(true);
      setTimeout(() => {
        setSaveClicked(false);
      }, 1000);
    }
  };

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  // const textColorBrand = useColorModeValue("brand.500", "white");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleBtnClick = (id: number) => {
    setBtnId(id);
  };

  const { timeZone, entries } = preferenceFields;

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <InvalidRole />
      {permission === "viewer" && (
        <Fragment>
          <AccessAlert />
          <BlurOverlay />
        </Fragment>
      )}
      <BackdropOverlay />
      {/* Main Fields */}
      {MsgContainerTopCenter}
      <Flex flexDirection="column">
        <Card p="0px">
          <Flex
            align={{ sm: "flex-start", lg: "center" }}
            justify="space-between"
            w="100%"
            px="22px"
            py="18px"
          >
            <Text color={textColor} fontSize="xl" fontWeight="600">
              Cloud Settings
            </Text>
            <Box>
              {btnData.map(({ id, name }) => (
                <Link key={id} to={`/dashboard/cloud-settings/${id}`}>
                  <Button
                    key={id}
                    variant={btnId === id ? "action" : "ghost"}
                    onClick={() => handleBtnClick(id)}
                    disabled={id === 2 && permission === "viewer"}
                  >
                    {name}
                  </Button>
                </Link>
              ))}
              <Loader loading={loading} />
            </Box>
            <Button
              colorScheme="blue"
              onClick={onSavePreference}
              disabled={permission === "viewer"}
            >
              Save
            </Button>
          </Flex>

          {btnId === 2 ? (
            <Fragment>
              <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                background="transparent"
                borderRadius="15px"
              >
                <Box
                  w={{ base: "100%", md: "420px" }}
                  maxW="100%"
                  ml="16px"
                  mr="16px"
                >
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Time Zone
                  </FormLabel>
                  <Select
                    placeholder="Select option"
                    size={"lg"}
                    mb="8"
                    fontSize="sm"
                    sx={{ borderRadius: "16px" }}
                    name="timeZone"
                    value={timeZone}
                    onChange={onChangeHandler}
                  >
                    {timeZoneData.map(({ id, name, value }) => (
                      <option key={id} value={value}>
                        {name}
                      </option>
                    ))}
                  </Select>
                </Box>
              </Flex>
              <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                background="transparent"
                borderRadius="15px"
              >
                <Box
                  w={{ base: "100%", md: "420px" }}
                  maxW="100%"
                  ml="16px"
                  mr="16px"
                  mb="24px"
                >
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Entries
                  </FormLabel>

                  <NumberInput
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    placeholder="Entries"
                    mb="24px"
                    fontWeight="500"
                    size="lg"
                    name="entries"
                    value={entries}
                    onChange={(value) =>
                      setPreferenceFields({
                        ...preferenceFields,
                        entries: +value,
                      })
                    }
                    min={1}
                    max={50}
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </Box>
              </Flex>
            </Fragment>
          ) : (
            <Spaces saveClicked={saveClicked} btnId={btnId} />
          )}

          <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            background="transparent"
            borderRadius="15px"
          ></Flex>
        </Card>
      </Flex>
      <BasicModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      {/* Delete Product */}
    </Box>
  );
}
