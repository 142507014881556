/*eslint-disable*/

import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

export default function Footer() {
  const textColor = useColorModeValue("gray.400", "white");
  return (
    <Flex
      zIndex="3"
      flexDirection="column" 
      alignItems="center" 
      justifyContent="center"
      px="30px"
      pb="30px"
    >
      <Text
        color={textColor}
        textAlign={{
          base: "center",
          xl: "start",
        }}
      >
        &copy; 2022-{new Date().getFullYear()}
        <Text as="span" fontWeight="500" ms="4px">
          FABR Ltd. All Rights Reserved.
        </Text>
      </Text>
      <List 
        display="flex"
        textAlign={{
          base: "center",
          xl: "start",
        }}
      >
        <ListItem
          me="20px"
        >
          <Link
            fontWeight="500"
            color={textColor}
            href="mailto:support@fabr.ltd"
          >
            Email Support
          </Link>
        </ListItem>
        <ListItem
          me="20px"
        >
          <Link
            fontWeight="500"
            color={textColor}
            href="https://fabrhq.com/tos"
          >
            Terms of Use
          </Link>
        </ListItem>
        <ListItem>
          <Link
            fontWeight="500"
            color={textColor}
            href="https://fabrhq.com"
          >
            Back to FABR
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}
