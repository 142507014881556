import moment from "moment";

/**
 * Date and time instance, contains all date and time functions
 * and user timezone preferences
 */
export class DateTime {
  // Use browser's timezone by default if no user preference set
  public timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Time offset from UTC to be set from user preferences
  public timeOffset: string | number = null;

  /**
   * DateTime constructor
   */
  constructor() {
    this.getUTCTimeOffset();
  }

  /**
   * Sets the time offset from utc to use for all date/time
   * functions from user preferences
   * @param timezone - Timezone to get offset from
   */
  public readonly setTimeOffset = (timezone: string) => {
    if (timezone === 'UTC') {
      this.timeZone = 'UTC';
    } else {
      this.timeZone = 'UTC ' + timezone;
    }
    this.timeOffset = this.getOffset(timezone);
  };

  /**
   * Gets time offset from timezone i.e +1 returns +0100
   * @param timezone - Timezone to get offset from
   * @returns - Time offset from UTC
   */
  private readonly getOffset = (timezone: string) => {
    if (timezone === 'UTC') return '0000';
    else return `${[...timezone].join('0')}00`;
  };

  /**
   * Gets the time offset from UTC and timezone using the browser's
   * settings
   */
  private readonly getUTCTimeOffset = () => {
    const offset = new Date().getTimezoneOffset() * -1;
    this.timeOffset = offset;
    if (offset < 0) {
      this.timeZone = `UTC ${offset / 60}`;
    } else {
      this.timeZone = `UTC +${offset / 60}`;
    }
  }
}

/**
 * Create a common instance of DateTime class
 */
const dateTime = new DateTime();

/**
 * Instance of DateTime class -- contains configs for user display
 * preferences
 */
export const __dateTime = dateTime;

/**
 * Predefined date formats
 */
export enum formats {
  zulu = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
  display = 'DD/MM/YYYY : HH:mm',
}

/**
 * Converts a date to zulu format for storage
 * @param date - Date to format
 * @returns date in zulu format
 */
export const toZuluFormat = (date: Date | string) => {
  return moment(date).format(formats.zulu);
}

/**
 * Gets the display time from a date value provided
 * @param date - Date to format
 * @returns Date in display format
 */
export const getDisplayTime = (date: string | Date) => {
  if (__dateTime.timeOffset === '0000') {
    return moment(date).utc()
      .format(formats.display) + ' ' + __dateTime.timeZone;
  }
  return moment(date).utcOffset(__dateTime.timeOffset)
    .format(formats.display) + ' ' + __dateTime.timeZone;
}

/**
 * Creates a timestamp in zulu format
 * @returns current date and time in zulu format
 */
export const timeStamp = () => {
  return moment(Date.now()).format(formats.zulu);
}

/**
 * Get the renewal date for the billing plan
 * @param key - plan type 'Monthly' | 'Yearly'
 * @returns date in zulu format
 */
export const getRenewalDate = (key: 'Monthly' | 'Yearly') => {
  if (key === 'Monthly') return moment(Date.now()).add(1, 'month')
    .utc().format(formats.zulu);
  else return moment(Date.now()).add(1, 'year')
    .utc().format(formats.zulu);
}
