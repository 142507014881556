import { FC, ReactNode, useEffect, useRef } from "react";
import { Box, BoxProps, Icon, useColorModeValue, Text } from "@chakra-ui/react";
import { ImPlus } from "react-icons/im";
// import { BsChevronDoubleLeft, BsChevronDoubleRight } from "react-icons/bs";

import "../cssstyles/toolbar.css";
import { useEditorToolbar } from "./utils";

export const Toolbar: FC<{}> = (props) => {

  const selectedBg = useColorModeValue("blue.500", "#7551ff");
  const bg = useColorModeValue("#d0e2f3", "#90CDf4")
  const plusBg = useColorModeValue("#fff", "navy.800")
  const { handleSelect, selected, newCode, data, ext } = useEditorToolbar();
  
  const containerRef = useRef(null);


  // Redirect mouse wheel to horrizontal scrolling
  useEffect(() => {
    const el = containerRef.current;

    if(!el) return;

    const handleWheel = (e: any) => {
      e.preventDefault();
      el.scrollLeft += e.deltaY;
    }

    el.addEventListener("wheel", handleWheel);

    return () => {
      el.removeEventListener("wheel", handleWheel);
    }
  }, [containerRef])

  return (
    <Box
      className="toolbar-container"
    >
      <Box flexDir="row" justifyContent="flex-start" gap="1rem"
        className="toolbar-scroll-container"
        ref={containerRef}
      >
        {   data.map((item, index) => (
            <CodeBlock key={index} p="0.5rem 0"
              onClick={() => handleSelect(index)}
              bg={selected === index ? selectedBg : bg}
              color={selected === index ? "#fff" : "#000"}
              enableDelete={index > 1 ? true : false}
              index={index} selected={selected === index}
            >
              {index === 0 ? "config.json" : `custom${index - 1}${ext}`}
            </CodeBlock>
          ))
        }
      </Box>
      <Box
          position="sticky"
          left="0" zIndex={1} bg={plusBg}
          width={"2rem"}
          height={"2.5rem"}
          display="flex"
          justifyContent="center"
          boxShadow={"1px 1px 10px 2px rgba(0,0,0,0.1)"}
          alignItems="center"
          p="0 .25rem"
          cursor={"pointer"}
          className="toolbar-plus-container"
        >
          <ToolTip>
            new
          </ToolTip>
          <Icon as={ImPlus} boxSize={6} color="blue.500"
            onClick={newCode}
          />
          {/* <Icon as={BsChevronDoubleLeft} /> */}
        </Box>
    </Box>
  );
};


export const ToolTip: FC<{ children: ReactNode }> = (props) => {
  return (
    <Box
      className="tool-tip-container"
    >
      <Box
        className="tool-tip-content"
      >
        <Text
          fontSize="0.8rem"
          minW={"5rem"}
        >
          {props.children}
        </Text>
      </Box>
    </Box>
  );
};


interface CodeBlockProps extends BoxProps {
  children: ReactNode;
  enableDelete?: boolean;
  index: number;
  selected?: boolean;
}

export const CodeBlock: FC<CodeBlockProps> = (props) => {
  const { children, enableDelete, selected, index, ...rest } = props;

  return (
    <Box className="tool-bar-container">
      <Box
        className="tool-bar-icon"
        cursor="pointer"
        borderRadius="0.25rem"
        maxH="2.5rem"
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        minW={"6rem"} minH={"2.5rem"}
        {...rest}
      >
        <Text
          maxH="2.5rem"
          width={"fit-content"}
          whiteSpace="nowrap"
        >
          {children}
        </Text>
      </Box>
    </Box>
  );
};

