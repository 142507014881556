import { db } from 'firebase-local/config'
import { addDoc, collection, getDocs, limit, query, where } from 'firebase/firestore'
import { col } from './types'


interface InviteData {
  email: string;
  name: string;
  permission: string;
}

interface collectionData {
  spaceId: string;
  docId: string;
  teamId: string;
}

export class UserInvite {
  constructor(
    private readonly data: InviteData,
    private readonly collectionData: collectionData
  ) { }

  /**
   * Handle user invite
   */
  public readonly handlInvite = async () => {
    await this.inviteUser();
  }

  /**
   * Check if a user with the same email exists
   * @param email Users email address
   * @returns boolean
   */
  static userExists = async (email: string) => {
    const usersRef = collection(db, col.users);
    const q = query(usersRef,
      where('email', '==', email),
      limit(1));
    const user = await getDocs(q);
    return user.docs.length > 0;
  }

  /**
   * Invite a user to a team and the selected space
   */
  private readonly inviteUser = async () => {
    const { docId: orgId, teamId, spaceId } = this.collectionData;
    const membersRef = collection(db, col.orgs,
      orgId, col.teams, teamId, col.members);
    const spaceRef = collection(db, col.orgs,
      orgId, col.teams, teamId, col.spaces, spaceId, col.members);
    const { permission, ...spacemembersData } = this.data;
    const membersData = { ...this.data, reference: orgId };
    await addDoc(membersRef, membersData);
    await addDoc(spaceRef, spacemembersData);
  }
}
