import { AutoSave } from "components/navbar/savechanges";
import { useAuth } from "context/AuthContext";
import { db } from "firebase-local/config";
import { doc, deleteDoc, writeBatch } from "firebase/firestore";
import { col } from "functions";
import { uniqBy } from "lodash";
import { useState, useEffect, Dispatch, SetStateAction, useCallback } from "react";
import { singleSpaceType, planType, environmentType, spacesType } from "types/userTypes";


/**
 * SpaceStatus - interface for space status object, indicates whether
 * a space is active or archived
 * @property {string} id Space id
 * @property {"active" | "archived"} status Space status
 */
interface SpaceStatus {
  [id: string]: {
    status: "active" | "archived";
    name?: string;
  };
}

type SetSelectSpace = Dispatch<SetStateAction<singleSpaceType>>;

/**
 * UseSpaceReturn - return type for useSpaces() custom hook contains
 * custom methods and state variables
 * @property {singleSpaceType} selectedSpace Selected space
 * @property {planType[]} selectedSpacePlans Selected space plans
 * @property {environmentType[]} selectedSpaceEnvs Selected space environments
 * @property {boolean} disableSave Disable save button
 * @property {(space: spacesType) => void} selectSpace Handle space selection
 * @property {(e: any) => void} setArchived Handle setting archived status
 * @property {SetSelectSpace} setSelectSpace Handle setting selected space
 * @property {(e: any) => void} updateName Handle updating space name
 * @property {Object} del Delete methods
 * @property {() => void} sort Handle sorting spaces
 * @property {() => void} sort.handleSort Handle sorting spaces
 * @property {(a: spacesType, b: spacesType) => number} sort.sortSpaces Handle sorting spaces
 * @property {(envId: string) => void} del.onDeleteEnv Handle deleting an environment
 * @property {(planId: string) => void} del.onDeletePlan Handle deleting a plan
 */
interface UseSpaceReturn {
  selectedSpace: singleSpaceType;
  selectedSpacePlans: planType[];
  selectedSpaceEnvs: environmentType[];
  disableSave: boolean;
  selectSpace: (space: spacesType) => void;
  setArchived: (e: any) => void;
  setSelectedSpace: SetSelectSpace;
  updateName: (e: any) => void;
  del: {
    onDeleteEnv: (envId: string) => void;
    onDeletePlan: (planId: string) => void;
  };
  sort: {
    handleSort: () => void;
    sortSpaces: (a: spacesType, b: spacesType) => number;
  };
}

/**
 * Custom hook to handle spaces data in the cloud settings page
 * @return {UseSpaceReturn} custom methods and state variables
 */
export const useSpaces = (save: boolean = false): UseSpaceReturn => {
  const {
    getOrgsData,
    docId,
    spaceId,
    spaces,
    getSpaceId,
    getPlanId,
    getSpaceStatus,
  } = useAuth();

  //   Selected space
  const [spaceStatus, setSpaceStatus] = useState<SpaceStatus>({});
  const [selectedSpace, setSelectedSpace] = useState<singleSpaceType>();
  const [selectedSpacePlans, setSelectedSpacePlans] = useState<planType[]>([]);
  const [selectedSpaceEnvs, setSelectedSpaceEnvs] = useState<environmentType[]>(
    []
  );
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [sort, setSort] = useState<boolean>(false);
  const [sortClicked, setSortClicked] = useState<boolean>(false);


  /**
   * Handle space selection within the Cloud settings page
   * @param space Spaces collection document
   * @returns void
   */
  const selectSpace = (space: spacesType) => {
    setSelectedSpace(space);
    const spaceId = space.id || "";
    getSpaceId(spaceId);
    const plans = space.plans || [];
    if (!plans.length) return getPlanId("");
    const planId = uniqBy(plans, "id")[0].id;
    getPlanId(planId);
  }

  /**
   * Set selected space plans and environments
   */
  useEffect(() => {
    if (!selectedSpace) return;

    const plans = selectedSpace.plans || [];
    const envs = selectedSpace.environments || [];
    setSelectedSpacePlans(plans);
    setSelectedSpaceEnvs(envs);
  }, [selectedSpace]);

  useEffect(() => {
    if (spaces.length) selectSpace(spaces[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaces]);

  /**
   * Handle saving space archived status values if any
   */
  const onSaveSpace = useCallback(async () => {
    const batch = writeBatch(db);

    Object.keys(spaceStatus).forEach((key) => {
      const spaceRef = doc(
        db, col.orgs, docId, col.teams, docId, col.spaces, key
      );
      batch.update(spaceRef, { ...spaceStatus[key] });
    });

    await batch.commit();
    setSpaceStatus({});
    setDisableSave(true);
    AutoSave.display("Space saved successfully");
  }, [spaceStatus, docId]);

  useEffect(() => {
    if (!save) return;
    if (save && disableSave) {
      AutoSave.display("No changes to save");
      return;
    }
    onSaveSpace();
  }, [save, disableSave, onSaveSpace]);

  /**
   * Handle deleting a plan from the current space
   * @param planId plan id
   */
  const onDeletePlan = async (planId: string) => {
    const plansRef = doc(
      db, col.orgs, docId, col.teams, docId, col.spaces,
      spaceId, col.plans, planId
    );

    await deleteDoc(plansRef)
      .then(() => {
        AutoSave.display("Plan deleted successfully");
        getOrgsData();
      })
      .catch((error) => {
        AutoSave.error("Error deleting plan");
        console.error("useSpaces() --> Error removing document: ", error);
      });
  };

  /**
   * Handle deleting an environment from the current space
   * @param envId environment id
   */
  const onDeleteEnv = async (envId: string) => {
    const envsRef = doc(
      db, col.orgs, docId, col.teams, docId, col.spaces,
      spaceId, col.environments, envId
    );

    await deleteDoc(envsRef)
      .then(() => {
        AutoSave.display("Environment deleted successfully");
        getOrgsData();
      })
      .catch((error) => {
        AutoSave.error("Error deleting environment");
        console.error("useSpaces() --> Error removing document: ", error);
      });
  };

  /**
   * Set archived status for a spaces available in the current team
   * ready to commit on save
   * @param e Input event
   */
  const setArchived = (e: any) => {
    const id = e.target.name;
    const status = e.target.value;
    setDisableSave(false);
    getSpaceStatus(id, status);
    setSpaceStatus(prev => {
      prev[id] = { ...prev[id], status };
      return prev
    });
  }

  /**
   * Update the input field name for a space
   * @param e Input event
   */
  const updateName = (e: any) => {
    const id = e.target.name;
    const name = e.target.value;
    setDisableSave(false);
    setSpaceStatus(prev => {
      prev[id] = { ...prev[id], name };
      return prev
    });
    setSelectedSpace({
      ...selectedSpace,
      name
    });
  };

  /**
   * Toggle sort parameters for spaces to display appropriately or in reverse
   */
  const handleSort = () => {
    setSort(!sort);
    setSortClicked(true);
  };

  /**
   * Toggle sort order for spaces to display appropriately or in reverse
   * @param a space a
   * @param b space b
   * @returns number
   */
  const sortSpaces = (a: spacesType, b: spacesType) => {
    if (sort && sortClicked) {
      if (a.name > b.name) {
        return 1;
      } else if (a.name < b.name) {
        return -1;
      } else return 0;
    } else if (!sort && sortClicked) {
      if (a.name > b.name) {
        return -1;
      } else if (a.name < b.name) {
        return 1;
      } else return 0;
    }
  };

  return {
    selectedSpace,
    selectedSpacePlans,
    selectedSpaceEnvs,
    disableSave,
    selectSpace,
    setArchived,
    setSelectedSpace,
    updateName,
    del: { onDeleteEnv, onDeletePlan },
    sort: { sortSpaces, handleSort },
  }
};
