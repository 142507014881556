import { DocumentData, DocumentReference, getDoc, updateDoc } from "firebase/firestore";
import _ from "lodash";
import { ProviderNames, ProviderSettings, ProvidersMap, CommonFieldNames, CommonFields } from "./types";


type DocRef = DocumentReference<DocumentData>;
type InitEditReturn = Promise<{
  fields: ProviderSettings[],
  commFields: CommonFields,
  creds: any
}>;

/**
 * Cloud providers Auth configuration class
 */
class CloudProvider implements ProvidersMap {
  readonly providers: { [provider: string]: ProviderSettings[] } = {};

  /**
   * List of currently supported providers
   */
  private readonly providerKeys: ProviderNames[] = [
    'AWS', 'GCP', 'DO', 'AZR',
  ];

  /**
   * Stores all the credentials using provider name as Key
   */
  credentials: any = {};

  /**
   * Credential field errors for the active vendor
   */
  errors: any = {};
  lockvendor: boolean = false;

  /**
   * Field names common to all providers
   */
  commonFields: CommonFields = {
    name: ""
  }

  /**
   * Saves the already read creadentials incase a user selects a different
   * cloud provider while editing
   */
  private tempCreds: any = {}

  /**
   * Create a map for the currently supported providers. Linking them
   * to their various configs
   */
  constructor() {
    this.providerKeys.forEach(key => this.providers[key] = this[key])
  }

  /**
   * Retrieve auth config fields for various providers
   * @param key Cloud provider name e.g AWS, GCP, HRK, DO, AZR
   * @returns Array<Fields> to collect data
   */
  getProvider(key: ProviderNames, mode?: 'edit' | 'create'): ProviderSettings[] {
    const data = this.providers[key];
    if (data) {
      this.setupCredentials(key);
      /** Do not remember prev creds if in create mode */

      if (mode === "edit" && this.tempCreds[key]) {
        this.credentials[key] = this.tempCreds[key];
      }
      return data;
    } else {
      return this.comingSoon
    }
  }

  /**
   * Handle saving credentials
   * @param event Input event
   * @param key the selected provider
   */
  onChangeCreds(event: any, key: ProviderNames) {
    const { name, value } = event.target;
    this.credentials[key][name] = value;
    if (value.trim() !== "") {
      this.errors[name] = {
        error: false,
        message: ""
      }
    } else {
      this.errors[name] = {
        error: true,
        message: "This field is required"
      }
    }
  }

  /**
   * Setup credential input fields for the selected provider
   * @param key Provider name key
   * @returns Void;
   */
  setupCredentials(key: ProviderNames) {
    this.credentials[key] = {};
    this.errors = {};
    this.providers[key].forEach(({ field }) => {
      this.credentials[key][field] = "";
      this.errors[field] = {
        error: false,
        message: ""
      };
    });
  }

  /**
   * Retrieves credentials property from this
   * @param key Provider key
   * @returns credentials object
   */
  readonly getCredentials = (key: string) => {
    const iskey = key as ProviderNames;
    const [field0] = this.providers[iskey];
    if (field0.type === 'textarea') {
      const [creds] = Object.values(this.credentials[iskey]);
      return creds as string;
    }
    return this.credentials[iskey];
  }

  /**
   * Check whether the provider selected is currently supported
   * @param name Cloud provider name 
   * @returns boolean true=provider supported
   */
  readonly isReady = (name: string): boolean => {
    const _name = name as ProviderNames
    return this.providerKeys.includes(_name);
  }

  /**
   * Configurations for Google cloud platform provider
   */
  private readonly GCP: ProviderSettings[] = [
    { field: 'project', secret: false, type: 'text' },
    { field: 'jsonFile', secret: true, type: 'textarea' },
  ]

  /**
   * Configurations for Digital Ocean cloud service provider
   */
  private readonly DO: ProviderSettings[] = [
    { field: 'personalAccessToken', secret: true, type: 'password' },
    { field: 'spacesAccessKey', secret: true, type: 'text' },
    { field: 'spacesSecretKey', secret: true, type: 'password' }
  ]

  /**
   * Configurations for Heroku cloud service provider
   */
  private readonly HRK: ProviderSettings[] = [
    { field: 'authToken', secret: true, type: 'password' }
  ]

  /**
   * Configurations for Amazon web services provider
   */
  private readonly AWS: ProviderSettings[] = [
    { field: 'accountId', secret: false, type: 'text' },
    { field: 'accessKeyId', secret: false, type: 'text' },
    { field: 'secretAccessKey', secret: true, type: 'password' },
  ]

  /**
   * Configurations for azure cloud provider
   */
  private readonly AZR: ProviderSettings[] = [
    { field: 'clientId', secret: false, type: 'text' },
    { field: 'clientSecret', secret: true, type: 'password' },
    { field: 'subscriptionId', secret: false, type: 'text' },
    { field: 'tenantId', secret: false, type: 'text' },
  ]

  /**
   * onChange Handler function for the enviroment input fields
   * @param event InputEvent
   */
  onChange(event: any) {
    const { name, value } = event.target;
    const _key: CommonFieldNames = name
    this.commonFields[_key] = value;
  }

  /**
   * Initializes the editing process by reading the credentials from firestore
   * and returning the appropriate input fields for the values read to allow
   * for editing
   * @param ref Document reference to the enviroment being edited
   * @returns {InitEditReturn}
   * fields - provider specific input fields
   * creds - credentials specific to the provider
   * commFields - common field names to all providers
   */
  public initCreds = async (ref: DocRef): InitEditReturn => {
    const credsDoc = await getDoc(ref);
    const env = credsDoc.data();
    if (env && env.vendor) {
      const vendor = env.vendor.toUpperCase();
      if (env.name) this.commonFields.name = env.name
      if (env.vendor) this.commonFields.vendor = vendor
      if (env.region) this.commonFields.region = env.region
      const fields = this.initEdit(vendor, env);
      return {
        fields, commFields: this.commonFields,
        creds: this.credentials[vendor]
      }
    } else {
      this.credentials = {};
      this.errors = {};
      this.lockvendor = false;
      const fields: ProviderSettings[] = [];
      this.commonFields = { name: env.name }
      return {
        fields, commFields: this.commonFields,
        creds: {}
      }
    }
  }

  /**
   * A method to call setup field names and update credetials
   * @param name Provider name (vendor)
   * @param data Data read
   * @returns {ProviderSettings[]} provider specific field names
   */
  private readonly initEdit = (name: ProviderNames, data: any = null): ProviderSettings[] => {
    const fields = this.getProvider(name);
    this.updateCredentials(data, name);
    if (this.isReady(name)) {
      this.lockvendor = true
    } else {
      this.lockvendor = false
    }
    return fields
  }

  /**
   * Updates the local instance with the credentials read from firestore
   * @param data Data read
   * @param vendor Provider name
   */
  private readonly updateCredentials = (data: any, vendor: ProviderNames) => {
    if (data && data.credentials) {
      const { credentials } = data;
      this.credentials[vendor] = credentials;
      this.tempCreds[vendor] = credentials;
    }
  }

  /**
   * Prperty getter for a credential fields data
   * @param provider Cloud provider name
   * @param field Field key name
   * @returns {string} field data
   */
  public readonly getFieldCred = (provider: string, field: string): string => {
    if (!this.isReady(provider)) return ""
    return this.credentials[provider][field]
  }

  /**
   * Resets the credential fields and the common fields upon data update
   */
  public readonly cleanup = () => {
    this.credentials = {};
    this.commonFields = {};
    // this.tempCreds = {};
    this.lockvendor = false;
  }

  /**
   * Writes the updated data to firestore
   * @param ref Document reference
   * @returns void
   */
  public readonly updateEnv = async (ref: DocRef, Data: any) => {
    await updateDoc(ref, Data).then(() => this.cleanup())
  };

  /**
   * Common fields data accessible publicly
   * @returns {CommonFields} Common fields data
   */
  public readonly fields = (): CommonFields => this.commonFields;

  /**
   * Provider specific credentials accessible publicly
   * @returns {any} provider specific fields data
   */
  public readonly creds = (): any => this.credentials;

  /**
   * Checks if the credentials are valid | (only checks for empty fields)
   * @returns {boolean} returns true if all checks pass
   */
  public readonly validate = (provider: ProviderNames): boolean => {
    if (!this.isReady(provider)) return false;
    if (_.isEmpty(this.credentials[provider])) return false;
    return Object.values(this.credentials[provider])
      .some((field) => {
        const val = field as string;
        return val.trim() === ''
      })
  }

  public readonly getFieldError = (provider: ProviderNames, field: string) => {
    if (this.credentials[provider][field].trim() === '') {
      return {
        status: true,
        message: `${field} is required`
      }
    }
    return {
      status: false,
      message: ''
    }
  }

  /**
   * Configurations for cloud providers that aren't currently supported
   */
  private readonly comingSoon: ProviderSettings[] = [
    { field: 'coming soon', secret: false, type: 'coming soon' }
  ]

  public readonly hasRegions = (vendor: ProviderNames): boolean =>
    vendor === "AWS" || vendor === "AZR" || vendor === "GCP" || vendor === "DO"
}

export const Providers = new CloudProvider();

export default Providers;
