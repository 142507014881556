export class Session {
  /**
   * An instance to help intaract with the local session storage
   * @param SessionStorage windows session storage
   */
  constructor(
    private readonly SessionStorage = window.sessionStorage,
  ) { }

  /**
   * Sets a key value pair in the sessionStorage to help with
   * maintaining state (value setter)
   * @param key Key for identifying the value store
   * @param val value to be stored
   */
  public readonly setItem = (key: string, val: string) => {
    this.SessionStorage.setItem(key, val);
  }

  /**
   * Retrieve a value from the session storage (value getter)
   * @param key key identifying the value to be retrieved
   */
  public readonly getItem = (key: string) => this.SessionStorage
    .getItem(key);

  /**
   * Removes an Item from the session storage
   * @param key key identifying the value to be removed
   */
  public readonly deleteItem = (key: string) => {
    this.SessionStorage.removeItem(key);
  }

  /**
   * Clear the session storage
   */
  public readonly clear = () => {
    this.SessionStorage.clear()
  }
}

export namespace Session {

  /**
   * Predefined keys
   */
  export enum keys {
    'dash-board-view' = 'fabr-dashboard-view-selector-value',
    'deploy-prefer-env' = 'fabr-cloud-design-deploy-prefer-env'
  }
}
