import React, { useState, useEffect, Fragment } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue,
  Input,
  FormLabel,
  useDisclosure,
} from "@chakra-ui/react";

import Card from "components/card/Card";
import { HSeparator } from "components/separator/Separator";
import { BasicModal } from "common/Modal";
import { useAuth } from "context/AuthContext";
import { profileTypes } from "../../../types/userTypes";
import { toast } from "react-toastify";
import { MsgContainerTopCenter } from "utils/authError";
import * as EmailValidator from "email-validator";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "firebase-local/config";
import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from "firebase/auth";
import Loader from "common/Loader";
import { PASSWORD_CONFIG } from "variables/config";

// const countryData = [
//   { id: 1, name: "Country", value: "" },
//   { id: 2, name: "Australia", value: "Australia" },
//   { id: 3, name: "United States", value: "United States" },
//   { id: 4, name: "United Kingdom", value: "United Kingdom" },
//   { id: 5, name: "Sweden", value: "Sweden" },
//   { id: 6, name: "Spain", value: "Spain" },
//   { id: 7, name: "Italy", value: "Italy" },
//   { id: 8, name: " United Arab Emirates", value: "United Arab Emirates" },
//   { id: 9, name: "Japan", value: "Japan" },
//   { id: 10, name: " Netherlands", value: "Netherlands" },
// ];

export default function ProfileTeam() {
  const {
    userData,
    currentUser,
    userEmail,
    docId,
    getUserData,
    logout,
  } = useAuth();
  const [btnId, setBtnId] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [saveClicked, setSaveClicked] = useState<boolean>(false);
  // const [userId, setUserId] = useState<string>("");
  // const [userEmail, setUserEmail] = useState<string>("");
  const [passwordSize, setPasswordSize] = useState<boolean>(true);

  const [updateFields, setUpdateFields] = useState<profileTypes>({
    name: "",
    email: "",
    // country: "",
    currentPassword: "",
    newPassword: "",
    matchPassword: "",
  });

  useEffect(() => {
    if (userEmail !== "") {
      setUpdateFields({
        ...updateFields,
        name: userData.name,
        email: userData.email,
      });
    }
    // eslint-disable-next-line
  }, [currentUser, userEmail, userData]);

  const onChangeHandler = (e: any) => {
    const value = e.target.value;
    setUpdateFields({
      ...updateFields,
      [e.target.name]: value,
    });
    if (e.target.name === "newPassword") {
      setPasswordSize(true);
    }
  };

  const handleMatchPassword = () => {
    const { newPassword, matchPassword } = updateFields;
    if (newPassword !== matchPassword) {
      toast.error("Passwords do not match");
    }
  };

  const handlePasswordSize = () => {
    const { newPassword } = updateFields;
    if (!PASSWORD_CONFIG.regex.test(newPassword)) {
      toast.error(PASSWORD_CONFIG.message);
      setPasswordSize(false);
    }
  };

  const handleValidateEmail = () => {
    const { email } = updateFields;
    if (!EmailValidator.validate(email)) {
      toast.error("Please enter a valid email address");
    }
  };

  const onSaveProfile = async () => {
    if (btnId === 1) {
      const { name, email, newPassword, currentPassword } =
        updateFields;
      const cred = EmailAuthProvider.credential(userEmail, currentPassword);
      const user: any = currentUser;

      if (docId !== "") {
        const usersRef = doc(db, "users", docId);
        if (
          userData.name !== name ||
          userData.email !== email
        ) {
          setLoading(true);
          updateDoc(usersRef, {
            name,
            email,
          })
            .then(() => {
              toast.success("Profile updated successfully!");
              getUserData(userEmail);
              setLoading(false);
            })
            .catch((err) => {
              toast.error(err.message);
              setLoading(false);
            });
        }
        if (newPassword !== "") {
          setLoading(true);

          try {
            await reauthenticateWithCredential(user, cred)
              .then((res) => {
                updatePassword(user, newPassword)
                  .then(() => {
                    setLoading(false);
                    toast.success(
                      "Password updated successfully - use with your new password from now on"
                    );
                    setUpdateFields({
                      ...updateFields,
                      newPassword: "",
                      currentPassword: "",
                      matchPassword: "",
                    });
                    setLoading(false);
                    setTimeout(() => {
                      logout();
                    }, 5000);
                  })
                  .catch((err) => {
                    setLoading(false);
                    toast.error(err.message);
                    setLoading(false);
                  });
              })
              .catch((err) => {
                setLoading(false);
                toast.error(
                  "Please provide correct current password"
                );
              });
          } catch (err: any) {
            setLoading(false);
            toast.error(err.message);
          }
        }
      }
    } else {
      setSaveClicked(true);
      setTimeout(() => {
        setSaveClicked(false);
      }, 1000);
    }
  };

  const { newPassword, matchPassword } = updateFields;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleBtnClick = (id: number) => {
    setBtnId(id);
  };

  const { name, email } = updateFields;

  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      {MsgContainerTopCenter}
      <Flex flexDirection="column">
        <Card p="0px">
          <Flex
            align={{ sm: "flex-start", lg: "center" }}
            justify="space-between"
            w="100%"
            px="22px"
            py="18px"
          >
            <Text color={textColor} fontSize="xl" fontWeight="600">
              Profile Settings
            </Text>

            <Button
              colorScheme="blue"
              onClick={onSaveProfile}
              disabled={
                (newPassword !== "" && newPassword !== matchPassword) ||
                !passwordSize
              }
            >
              Save
            </Button>
          </Flex>
          <Flex direction="row" justifyContent="center" alignItems="center">
            <Loader loading={loading} />
          </Flex>
          <Fragment>
            <Flex
              direction="row"
              justifyContent="center"
              alignItems="center"
              background="transparent"
              borderRadius="15px"
            >
              <Box
                w={{ base: "100%", md: "420px" }}
                maxW="100%"
                ml="16px"
                mr="16px"
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Email
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  type="email"
                  placeholder="hi@fabr.ltd"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  name="email"
                  value={email}
                  onChange={onChangeHandler}
                  onBlur={handleValidateEmail}
                  disabled
                  _disabled={{ color: "gray.400", cursor: "not-allowed" }}
                />
              </Box>
            </Flex>
            <Flex
              direction="row"
              justifyContent="center"
              alignItems="center"
              background="transparent"
              borderRadius="15px"
            >
              <Box
                w={{ base: "100%", md: "420px" }}
                maxW="100%"
                ml="16px"
                mr="16px"
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Name
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  type="text"
                  placeholder="hi@fabr.ltd"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  name="name"
                  value={name}
                  onChange={onChangeHandler}
                />
              </Box>
            </Flex>

            {/* <Flex
              direction="row"
              justifyContent="center"
              alignItems="center"
              background="transparent"
              borderRadius="15px"
            >
              <Box
                w={{ base: "100%", md: "420px" }}
                maxW="100%"
                ml="16px"
                mr="16px"
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Country
                </FormLabel>
                <Select
                  placeholder="Select option"
                  size={"lg"}
                  mb="8"
                  fontSize="sm"
                  sx={{ borderRadius: "16px" }}
                  name="country"
                  value={country}
                  onChange={onChangeHandler}
                >
                  {countryData.map(({ id, name, value }) => (
                    <option key={id} value={value}>
                      {name}
                    </option>
                  ))}
                </Select>
              </Box>
            </Flex> */}

            <HSeparator mb="36px" mt="30px" />
            <Flex
              direction="row"
              justifyContent="center"
              alignItems="center"
              background="transparent"
              borderRadius="15px"
            >
              <Box
                w={{ base: "100%", md: "420px" }}
                maxW="100%"
                ml="16px"
                mr="16px"
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Current Password
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  type="password"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  name="currentPassword"
                  onChange={onChangeHandler}
                />
              </Box>
            </Flex>
            <Flex
              direction="row"
              justifyContent="center"
              alignItems="center"
              background="transparent"
              borderRadius="15px"
            >
              <Box
                w={{ base: "100%", md: "420px" }}
                maxW="100%"
                ml="16px"
                mr="16px"
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  New Password
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  type="password"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  name="newPassword"
                  onChange={onChangeHandler}
                  onBlur={handlePasswordSize}
                />
              </Box>
            </Flex>
            <Flex
              direction="row"
              justifyContent="center"
              alignItems="center"
              background="transparent"
              borderRadius="15px"
            >
              <Box
                w={{ base: "100%", md: "420px" }}
                maxW="100%"
                ml="16px"
                mr="16px"
              >
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Confirm Password
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  type="password"
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  name="matchPassword"
                  onChange={onChangeHandler}
                  onBlur={handleMatchPassword}
                />
              </Box>
            </Flex>
          </Fragment>
        </Card>
      </Flex>
      <BasicModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      {/* Delete Product */}
    </Box>
  );
}
